import { FETCH_ERROR, FETCH_START } from "../../constants/ActionTypes";
import qs from "qs";
import axios from "util/Api";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const role = cookies.get("role");
const currentEmail = cookies.get("email");

/**
 *  Hàm lay thông tin list App
 * @param {} page
 * @param {} page_size
 * @param {*} callback
 */
export const getAppList = (
  { page, page_size, keyword, email },
  callback = (code, res) => {}
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .get(
        `app/search?email=${email}&keyword=${keyword}&page=${page}&page_size=${page_size}`
      )
      .then(({ data }) => {
        if (data.code === 200) {
          if (typeof callback === "function") {
            callback(200, data.data);
          }
        } else callback(data.code);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

/**
 *  Hàm them domain
 * @param {} domainInfo
 */
export const createApp = (
  { app_name, domain_name, play_url, email, platform },
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post(
        `app`,
        qs.stringify({
          app_name: app_name,
          domain_name: domain_name,
          play_url: play_url,
          email: role === "user" ? currentEmail : email,
          platform: platform,
        })
      )
      .then(({ data }) => {
        if (data.code === 200) {
          if (typeof callback === "function") {
            callback(200, data.data);
          }
        } else callback(data.code);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

/**
 *  Hàm delete app
 * @param {} domain_id
 */

export const deleteApp = (app_id, callback = (code, response) => {}) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .delete(`app/${app_id}`)
      .then(({ data }) => {
        if (data.code === 200) {
          if (typeof callback === "function") {
            callback(200, data.data);
          }
        } else callback(data.code);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};
