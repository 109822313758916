import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import AsyncSelect from "react-select/async";
import axios from "util/Api";
import { Table, Button, Row, Col, Modal, message } from "antd";
import { connect } from "react-redux";
import { searchEmail } from "../../appRedux/actions/Dashboard";
import {
  getListUserDataPack,
  updateDataPackStatus,
} from "../../appRedux/actions/UserDataPack";
import { Pagination } from "antd";
import moment from "moment";

const mapStateToProps = () => ({});

const TransactionManager = (props) => {
  //#region state
  const [isFetch, setIsFetch] = useState(false);

  const [total, setTotal] = useState(50);
  const [page, setPage] = useState(1);
  // const [userDataPackID, setUserDataPackID] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [listData, setListData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [defaultUserList, setDefaultUserList] = useState([]);
  const cookies = new Cookies();
  const roleAccount = cookies.get("role");

  const columns = [
    {
      title: "ID",
      width: "5%",
      render: (text, record) => listData.indexOf(record) + 1,
    },
    {
      title: "Pack Name",
      dataIndex: "pack_name",
      key: "pack_name",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Expiration",
      dataIndex: "expired",
      key: "expired",
      render: (time) => moment(time).utc().format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: "Activation",
      dataIndex: "created",
      key: "created",
      render: (time) => moment(time).utc().format("DD-MM-YYYY HH:mm:ss"),
    },
  ];

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
      minWidth: "250px",
    }),
    menu: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
  };

  // const stateSwitch = (record) => {
  //   if (roleAccount !== "user") {
  //     return record.active;
  //   }
  // };
  // const handleSwitch = (record) => {
  //   //setTimeout(setIsLoading(true), 200);
  //   if (roleAccount === "admin" || roleAccount === "super") {
  //     props.updateDataPackStatus(
  //       { id: record.userdata_id, active: !record.active },
  //       (code, res) => {
  //         if (code === 200) {
  //           message.success("Set status user package successfully");
  //           setIsFetch(true);
  //         } else {
  //           message.error("Error when verifing domain");
  //         }
  //       }
  //     );
  //   }
  //   // setIsLoading(false);
  // };

  const onChange = (current, pageSize) => {
    setPage(current);
    setPageSize(pageSize);
  };

  // Configure react-select
  const loadDefaultUserList = () => {
    if (roleAccount !== "user") {
      props.searchEmail(
        { page: 1, page_size: 20, keyword: "" },
        (code, res) => {
          if (code === 200) {
            setDefaultUserList(res);
          } else message.error("Error when fetching user list !");
        }
      );
    }
  };

  const loadUserOptions = (inputValue) => {
    if (roleAccount !== "user") {
      return axios
        .get(`admin/email/search?page=1&page_size=5&keyword=${inputValue}`)
        .then((res) => res.data.data);
    }
  };

  useEffect(() => {
    if (roleAccount === "admin" || roleAccount === "super") {
      loadDefaultUserList();
    }
  }, []);

  useEffect(() => {
    var email = "";
    if (roleAccount === "user") {
      email = cookies.get("email");
    } else {
      if (selectedUser) {
        email = selectedUser.value;
      } else {
        message.warn("Please choose one user !");
      }
    }
    props.getListUserDataPack(
      { page: page, page_size: pageSize, email: email },
      (code, res) => handleResult(code, res)
    );
    const handleResult = (code, res) => {
      if (code === 200) {
        setListData(res.data);
        setTotal(res.total);
      } else if (code === 1007) {
        setListData([]);
        setTotal(0);
      } else {
        message.error("Error when getting domain information!");
      }
    };
  }, [page, pageSize, selectedUser]);

  useEffect(() => {
    var email = "";
    if (isFetch === true) {
      if (roleAccount === "user") {
        email = cookies.get("email");
      } else {
        if (selectedUser) email = selectedUser.value;
      }
      props.getListUserDataPack(
        { page: page, page_size: pageSize, email: email },
        (code, res) => handleResult(code, res)
      );
      const handleResult = (code, res) => {
        if (code === 200) {
          setListData(res.data);
          setTotal(res.total);
          setIsFetch(false);
        } else if (code === 1007) {
          setListData([]);
          setTotal(0);
          setIsFetch(false);
        } else {
          message.error("Error when getting domain information!");
        }
      };
    }
  }, [page, pageSize, isFetch, selectedUser]);

  return (
    <div>
      <Row
        justify="space-between"
        style={{ marginLeft: "5px", marginRight: "0px" }}
      >
        <Col>
          <h4
            className="gx-font-weight-semi-bold gx-mb-3"
            style={{ color: "#274679" }}
          >
            USER PACKAGE
          </h4>
        </Col>
        <Col>
          {roleAccount === "super" || roleAccount === "admin" ? (
            <AsyncSelect
              className="gx-mb-3"
              placeholder="All user..."
              isClearable={true}
              styles={colourStyles}
              defaultOptions={defaultUserList}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              loadOptions={loadUserOptions}
              onChange={(value) => setSelectedUser(value)}
              maxMenuHeight={175}
            />
          ) : null}
        </Col>
      </Row>
      <div
        className="gx-card"
        key="card"
      >
        <Table
          className="gx-table-responsive"
          key="table"
          columns={columns}
          dataSource={listData}
          pagination={false}
          rowKey="id"
          style={{
            border: "none",
            borderRadius: "10px",
            minHeight: "360px",
          }}
        />
        <br />
        <Pagination
          showTotal={(total) => `Total ${total} items`}
          total={total}
          defaultCurrent={page}
          defaultPageSize={pageSize}
          onChange={onChange}
          showQuickJumper
          showSizeChanger
          style={{ marginLeft: "20px" }}
        />

        {/* {isAddPackage && (
          <Modal
            maskClosable={false}
            title={
              <div className="gx-font-weight-semi-bold gx-text-eco-1">
                ADD NEW PACKAGE
              </div>
            }
            width={720}
            wrapClassName="vertical-center-modal"
            visible={isAddPackage}
            onOk={() => setSubmitAdd(true)}
            onCancel={() => setIsAddPackage(false)}
            centered={true}
          >
            <CreateUserPackage
              submitAdd={submitAdd}
              onClose={onClose}
              onIsFetch={onIsFetch}
            />
          </Modal>
        )}
        {isEdit && (
          <Modal
            maskClosable={false}
            title={
              <div className="gx-font-weight-semi-bold gx-text-eco-1">
                EDIT USER PACKAGE
              </div>
            }
            width={450}
            wrapClassName="vertical-center-modal"
            visible={isEdit}
            onOk={() => setSubmitAdd(true)}
            onCancel={() => setIsEdit(false)}
            centered={true}
          >
            <EditUserPackage
              userDataPackID={userDataPackID}
              submitAdd={submitAdd}
              onClose={onClose}
              onIsFetch={onIsFetch}
            />
          </Modal>
        )} */}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, {
  getListUserDataPack,
  updateDataPackStatus,
  searchEmail,
})(TransactionManager);
