import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select, Tag, message, Button } from "antd";
import { updateUser } from "../../../appRedux/actions/User";
import { connect } from "react-redux";

const Option = Select.Option;

const mapStateToProps = () => ({});
const EditUser = (props) => {
  const [userInfo, setUserInfo] = useState([]);
  const [userEmail, setUserEmail] = useState(null);
  const [userID, setUserID] = useState(null);

  const onChange = (newFields) => {
    setUserInfo(newFields);
  };

  const handleUpdate = (res) => {
    if (res === 200) {
      message.success("Update user information successfully!");
      props.onIsFetch(true);
      props.onClose(false);
    } else message.error("Error when updating user information !");
  };

  useEffect(() => {
    setUserEmail(props.userEmail);
    setUserID(props.userID);
    setUserInfo([
      {
        name: ["status"],
        value: props.userStatus,
      },
      {
        name: ["type"],
        value: props.userType,
      },
    ]);
  }, [props.userID, props.userStatus, props.userType, props.userEmail]);

  useEffect(() => {
    if (props.submitUpdate === true) {
      props.updateUser(userInfo, userID, (res) => handleUpdate(res));
    }
  }, [props.submitUpdate]);

  return (
    <Form
      name="basic"
      className="gx-signin-form gx-form-row0"
      fields={userInfo}
      onFieldsChange={(changedFields, allFields) => {
        onChange(allFields);
      }}
    >
      <Row
        gutter={[16, 10]}
        justify="space-between"
        style={{ marginLeft: "0px", marginRight: "0px" }}
      >
        <Col>
          <span style={{ alignItems: "center" }}>Account : </span>
          <Tag style={{ alignItems: "center" }}>{userEmail}</Tag>
        </Col>
        <Col>
          <Button type="primary" onClick={() => props.onIsChangePass(true)}>
            Change Password
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 10]}>
        <Col span={12}>
          <p>Status</p>
          <Form.Item name="status">
            <Select className="gx-mr-3 gx-mb-3">
              <Option key="1" value={1}>
                Active
              </Option>
              <Option key="2" value={0}>
                InActive
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <p>Type</p>
          <Form.Item name="type">
            <Select className="gx-mr-3 gx-mb-3">
              <Option key="1" value={0}>
                User
              </Option>
              <Option key="2" value={1}>
                Admin
              </Option>
              <Option key="3" value={2}>
                Super
              </Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default connect(mapStateToProps, { updateUser })(EditUser);
