import React, { useState, useEffect } from "react";
import { Form, message, Input } from "antd";
import { useDispatch } from "react-redux";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
} from "@ant-design/icons";
import { changeUserPassword } from "../../../appRedux/actions/User";

const ChangePass = (props) => {
  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = useState([]);
  const [userEmail, setUserEmail] = useState(null);

  const onChange = (newFields) => {
    setUserInfo(newFields);
  };

  useEffect(() => {
    setUserEmail(props.userEmail);
    setUserInfo([
      {
        name: ["admin_passwd"],
        value: null,
      },
      {
        name: ["new_passwd"],
        value: null,
      },
      {
        name: ["confirm_new_passwd"],
        value: null,
      },
    ]);
  }, [props.userEmail]);

  useEffect(() => {
    if (props.submitChangePass === true) {
      dispatch(
        changeUserPassword(userEmail, userInfo, (res) => {
          if (res === 200) {
            message.success("Update user password successfully!");
            props.onIsFetch(true);
            props.onCloseChangePass(false);
          } else if (res === 401) {
            message.error("Access Denied ! Please check your account !");
          } else message.error("Error when changing user password !");
        })
      );
    }
  }, [props.submitChangePass]);

  return (
    <Form
      name="basic"
      className="gx-signin-form gx-form-row0"
      fields={userInfo}
      onFieldsChange={(changedFields, allFields) => {
        onChange(allFields);
      }}
    >
      <p>Admin Account Password</p>
      <Form.Item
        name="admin_passwd"
        rules={[{ required: true, message: "Please input your old password!" }]}
      >
        <Input.Password
          prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="Enter your own password"
          type="password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
      <p>New Account Password</p>
      <Form.Item
        name="new_passwd"
        rules={[
          { required: true, message: "Please input new account password!" },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="Enter new account password"
          type="password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
      <p>Confirm Password</p>
      <Form.Item
        name="confirm_new_passwd"
        dependencies={["new_passwd"]}
        hasFeedback
        rules={[
          { required: true, message: "Please confirm new Password!" },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("new_passwd") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                "The two passwords that you entered do not match!"
              );
            },
          }),
        ]}
      >
        <Input.Password
          prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="Confirm your new pasword"
          type="password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
    </Form>
  );
};

export default ChangePass;
