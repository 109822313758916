import React, { useRef, useEffect, useState } from "react";
import { message } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { createTransaction } from "../../../../appRedux/actions/Transaction";
import { connect } from "react-redux";
import Cookies from "universal-cookie";

const mapStateToProps = () => ({});

const Paypal = (props) => {
  const cookies = new Cookies();
  const email = cookies.get("email");

  const paypal = useRef();
  const [paid, setPaid] = useState(false);

  const handeCreateTransaction = (res) => {
    if (res === 200) {
      message.success("Successfully Purchased !");
      setPaid(true);
    } else message.error("Purchase Fail !");
  };

  useEffect(() => {
    window.paypal
      .Buttons({
        style: {
          color: "silver",
          height: 50,
          label: "pay",
        },
        createOrder: (data, actions, err) => {
          return actions.order.create({
            // intent: "CAPTURE",
            purchase_units: [
              {
                description: props.description,
                amount: {
                  currency_code: props.currency_code
                    ? props.currency_code
                    : "USD",
                  value: props.real_price != null ? props.real_price : 0,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          message.loading("Please wait a minute ...");
          const order = await actions.order.capture();
          if (order != null && order.purchase_units[0] != null) {
            props.createTransaction(
              [
                {
                  name: "email",
                  value: email,
                },
                {
                  name: "pack_name",
                  value: props.name,
                },
                {
                  name: "trans_id",
                  value: order.id != null ? order.id : "",
                },
                {
                  name: "origin_price",
                  value: null,
                },
                {
                  name: "real_price",
                  value: null,
                },
                {
                  name: "paid",
                  value:
                    order.purchase_units[0].payments.captures[0].amount.value,
                },
                {
                  name: "currency",
                  value: props.currency,
                },
                {
                  name: "pay_id",
                  value: 2,
                },
                {
                  name: "status",
                  value: "success",
                },
                {
                  name: "description",
                  value:
                    email +
                    " pay " +
                    order.purchase_units[0].payments.captures[0].amount.value +
                    order.purchase_units[0].payments.captures[0].amount.currency_code,
                },
              ],
              (res) => handeCreateTransaction(res)
            );
          }
        },
        onError: (err) => {
          message.error("Error, transaction is not completed!");
        },
      })
      .render(paypal.current);
  }, []);

  return (
    <div style={{ width: "80%" }}>
      {paid ? (
        <strong
          className="gx-text-eco-1"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
          }}
        >
          <CheckCircleTwoTone
            twoToneColor="#52c41a"
            style={{ fontSize: 25, marginRight: 12 }}
          />
          Successfully Purchased !
        </strong>
      ) : (
        // <div style={{ display: "flex", justifyContent: "center" }}>
        //   <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 25 }} />
        //   <strong className="gx-ml-3">Successfully Purchased !</strong>
        // </div>
        <div ref={paypal}></div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, {
  createTransaction,
})(Paypal);
