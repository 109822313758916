import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Avatar, Popover, message } from "antd";
import { userSignOut } from "../../appRedux/actions/Auth";
import { getUserInfo } from "../../appRedux/actions/Auth";

const UserProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    const handleResult = (code, res) => {
      if (code === 200) {
        setUserInfo(res);
      } else message.error("Error when getting user information!");
    };
    dispatch(getUserInfo((code, res) => handleResult(code, res)));
  }, []);
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => history.push("/user-profile")}>My Account</li>
      <li>Connections</li>
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  );

  return (
    <div
      className="gx-flex-row gx-align-items-center gx-mb-2 gx-ml-3 gx-avatar-row"
      style={{ justifyContent: "center" }}
    >
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
        style={{ alignItems: "center" }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Avatar
            src={require("../../assets/images/placeholder.jpg")}
            className="gx-size-50 gx-pointer gx-mr-3"
            alt=""
          />
        </div>

        <br />
        <div>
          <span className="gx-avatar-name">
            {userInfo.user_name}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
          </span>
        </div>
      </Popover>
    </div>
  );
};

export default UserProfile;
