import React, { useState, useEffect } from "react";
import { Button, message, Result } from "antd";
import { CheckCircleTwoTone, StarFilled } from "@ant-design/icons";
import { connect } from "react-redux";
import { createTransaction } from "../../../../appRedux/actions/Transaction";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
const cookies = new Cookies();
const userEmail = cookies.get("email");

const mapStateToProps = () => ({});
const DirectPayment = (props) => {
  const history = useHistory();

  const handleSubmit = () => {
    const transactionInfo = [
      {
        name: "email",
        value: userEmail,
      },
      {
        name: "pack_name",
        value: props.pack_name,
      },
      {
        name: "trans_id",
        value: "",
      },
      {
        name: "origin_price",
        value: props.origin_price,
      },
      {
        name: "real_price",
        value: props.real_price,
      },
      {
        name: "paid",
        value: props.real_price,
      },
      {
        name: "currency",
        value: props.currency,
      },
      {
        name: "pay_id",
        value: 1,
      },
      {
        name: "status",
        value: "pending",
      },
      {
        name: "description",
        value: props.description,
      },
    ];
    props.createTransaction(transactionInfo, (res) => {
      if (res === 200) {
        message.success("Create transaction successfully!");
        props.onClose(false);
        history.push("/pricing/transactions");
      } else message.error("Error when adding transaction !");
    });
  };
  return (
    <div className="price-info" style={{ marginTop: "-25px" }}>
      <div className="gx-product-body">
        <Result
          status="success"
          title="Thanks for your order !"
          extra={
            <div>
              <p>We'll contact you soon through your email !</p>
              <p>Support email : support@ecocdn.net</p>
            </div>
          }
        />
        {/* <div style={{ display: "flex" }}>
          <StarFilled style={{ color: "#ee517e", fontSize: 20 }} />
          <div
            style={{ marginLeft: 20, fontWeight: 600, color: "#635757" }}
          ></div>
        </div> */}
        {/* <div className="ant-row-flex  ant-row-space-between gx-mt-4">
          <div className="gx-font-weight-semi-bold">
            <CheckCircleTwoTone twoToneColor="#52c41a" className="gx-mr-2" />
            Owner
          </div>
          <div
            className="gx-font-weight-semi-bold gx-text-eco-3"
            style={{ fontSize: 16, flex: 1, textAlign: "end" }}
          >
            Công ty EcoCDN
          </div>
        </div>
        <hr></hr>
        <div className="ant-row-flex  ant-row-space-between gx-mt-4">
          <div className="gx-font-weight-semi-bold">
            <CheckCircleTwoTone twoToneColor="#52c41a" className="gx-mr-2" />
            Card Number
          </div>
          <div
            className="gx-font-weight-semi-bold gx-text-eco-3"
            style={{ fontSize: 16, flex: 1, textAlign: "end" }}
          >
            0491000025541
          </div>
        </div>
        <hr></hr>
        <div className="ant-row-flex  ant-row-space-between gx-mt-3">
          <div className="gx-font-weight-semi-bold">
            <CheckCircleTwoTone twoToneColor="#52c41a" className="gx-mr-2" />
            Bank
          </div>
          <div
            className="gx-font-weight-semi-bold gx-text-eco-3"
            style={{ fontSize: 16, flex: 1, textAlign: "end" }}
          >
            Ngân hàng Vietcombank - Chi nhánh Thăng Long
          </div>
        </div>
        <hr></hr>
        <div className="ant-row-flex  ant-row-space-between gx-mt-3">
          <div className="gx-font-weight-semi-bold">
            <CheckCircleTwoTone twoToneColor="#52c41a" className="gx-mr-2" />
            Amount
          </div>
          <div
            className="gx-font-weight-semi-bold gx-text-eco-3"
            style={{ fontSize: 16 }}
          >
            {props.real_price} {props.currency}
          </div>
        </div>
        <hr></hr>
        <div className="ant-row-flex  ant-row-space-between gx-mt-3">
          <div className="gx-font-weight-semi-bold">
            <CheckCircleTwoTone twoToneColor="#52c41a" className="gx-mr-2" />
            Content
          </div>
          <div
            className="gx-font-weight-semi-bold gx-text-eco-3"
            style={{ fontSize: 16, flex: 1, textAlign: "end" }}
          >
            Tai khoan jackwiliams thanh toan don hang EcoCDN_4544_1607779807
          </div>
        </div>
       */}
      </div>
      <div
        className="gx-product-footer"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          style={{
            backgroundColor: "#f5487f", //#006eb8
            color: "white",
            borderRadius: 8,
            height: 50,
            width: "100%",
            boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.06)",
            border: 0,
          }}
          onClick={handleSubmit}
        >
          <strong style={{ fontSize: 15 }}>Finish</strong>
          <i
            className="icon  i icon-arrow-right gx-ml-3"
            style={{ fontSize: 15, fontWeight: "bold" }}
          ></i>
        </Button>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, { createTransaction })(DirectPayment);
