import { FETCH_ERROR } from "../../constants/ActionTypes";
import axios from "util/Api";
import qs from "qs";

/**
 *  Hàm update thông tin user account (admin  mới có quyền)
 * @param {} accountId
 * @param {} type
 * @param {} status
 * @param {*} callback
 */
export const updateUser = (userInfo, userID, callback = (response) => {}) => {
  return (dispatch) => {
    axios
      .put(
        "admin/account",
        qs.stringify({
          account_id: userID,
          status: userInfo[0].value,
          type: userInfo[1].value,
        })
      )
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(res.data.code);
          }
        } else {
          callback(res.data.code);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        callback(404);
      });
  };
};

/**
 * Ham lay thong tin user by ID (admin)
 * @param {string} userID id cua user can lay thong tin (admin)
 * @param {*} callback
 */
export const getUserInfoByID = (userID, callback = (code, data) => {}) => {
  return (dispatch) => {
    axios
      .get(`admin/account/info?userid=${userID}`)
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(res.data.code, res.data.data);
          }
        } else {
          callback(res.data.code);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        callback(404, null);
      });
  };
};

/**
 * Ham lay danh sach user (by admin)
 * @param {} callback
 */
export const getListUser = (
  { page, pageSize, keyWord },
  callback = (code, res) => {}
) => {
  return (dispatch) => {
    var url = `admin/account/search?page=${page}&page_size=${pageSize}&keyword=${keyWord}`;
    axios
      .get(url)
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(res.data.code, res.data.data);
          }
        } else {
          callback(res.data.code);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        callback(404, null);
      });
  };
};

/**
 * Ham  thay doi user password (by admin)
 * @param {} callback
 */
export const changeUserPassword = (
  userEmail,
  userInfo,
  callback = (res) => {}
) => {
  return (dispatch) => {
    axios
      .post(
        "admin/account/pwd",
        qs.stringify({
          email: userEmail,
          admin_passwd: userInfo[0] != null ? userInfo[0].value : null,
          new_passwd: userInfo[1] != null ? userInfo[1].value : null,
        })
      )
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(res.data.code);
          }
        } else callback(res.data.code);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        callback(404);
      });
  };
};

/**
 * Ham  tao user (by admin)
 * @param {} callback
 */

export const createUser = (userInfo, callback = (response) => {}) => {
  return (dispatch) => {
    axios
      .post(
        `admin/account`,
        qs.stringify({
          username: userInfo[0].value,
          email: userInfo[1].value,
          passwd: userInfo[2].value,
          user_status: userInfo[4].value,
          user_type: userInfo[5].value,
        })
      )
      .then(({ data }) => {
        if (typeof callback === "function") {
          callback(200);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

/**
 * Ham xoa user (by admin)
 * @param {} callback
 */

export const deleteUser = (user_id, callback = (response) => {}) => {
  return (dispatch) => {
    axios
      .delete(`admin/account/${user_id}`)
      .then(({ data }) => {
        if (typeof callback === "function") {
          callback(200);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};
