import React, { useState, useEffect } from "react";
import { Card } from "antd";

const P2POffload = (props) => {
  const [offload, setOffload] = useState([]);

  var series = [
    parseInt(props.offloadTrafficMonth * 100),
    100 - parseInt(props.offloadTrafficMonth * 100),
  ];
  var options = {
    chart: {
      width: "100%",
      type: "pie",
    },
    labels: ["P2P", "HTTP"],
    theme: {
      monochrome: {
        enabled: true,
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5,
        },
      },
    },
    dataLabels: {
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return [name, val.toFixed(2) + "%"];
      },
    },
    legend: {
      show: false,
    },
  };

  useEffect(() => {
    var res = [
      {
        name: "P2P Traffic",
        value: parseInt(props.offloadTrafficMonth * 100),
      },
      {
        name: "Other",
        value: 100 - parseInt(props.offloadTrafficMonth * 100),
      },
    ];
    setOffload(res);
  }, [props.offloadTrafficMonth]);

  return (
    <Card
      style={{
        backgroundColor: "#4158D0",
        backgroundImage: `linear-gradient(43deg, #4158D0 0%, #C850C0 86%)`,
        borderRadius: "10px",
        boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.05)",
      }}
    >
      <div
        className="gx-text-capitalize gx-text-white gx-mb-2 gx-font-weight-heavy "
        style={{ fontSize: 13 }}
      >
        P2P OFFLOAD THIS MONTH
      </div>
      <div className="h1 gx-text-capitalize gx-text-white gx-mb-0 gx-font-weight-bold">
        {(props.offloadTrafficMonth * 100).toFixed(2)} %
      </div>
      <hr />
    </Card>
  );
};
export default P2POffload;
