import React, { useState, useEffect } from "react";
import { message, Table, Tag, Tooltip, Card } from "antd";
import { getTopDomain } from "../../../../appRedux/actions/Dashboard";
import { connect } from "react-redux";
import { isEmpty } from "../../../../util/formatData";
import { formatDataSize } from "../../../../util/formatData";

const mapStateToProps = () => ({});
const DomainList = (props) => {
  const [domainList, setDomainList] = useState([]);
  const columns = [
    {
      title: "Top",
      render: (text, record) => {
        return <b className="gx-text-blue">{domainList.indexOf(record) + 1}</b>;
      },
    },
    {
      title: "Domain Name",
      key: "domain_name",
      render: (text, record) => {
        return <span className="gx-text-blue">{record.domain_name}</span>;
      },
    },
    {
      title: "Concurent User",
      key: "num_rt",
      width: "20%",
      render: (text, record) => {
        return (
          <Tooltip title="Concurrent User" color="blue">
            <Tag
              className="gx-bg-blue gx-text-white"
              style={{ width: "105px", textAlign: "center" }}
            >
              {record.num_rt != null ? record.num_rt : 0}
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      title: "P2P Traffic Today",
      key: "traffic_p2p_day",
      width: "20%",
      render: (text, record) => {
        return (
          <Tooltip title="P2P Traffic Today" color="orange">
            <Tag
              className="gx-bg-orange gx-text-white"
              style={{ width: "110px", textAlign: "center" }}
            >
              {record.traffic_p2p_day != null
                ? formatDataSize(record.traffic_p2p_day)
                : 0}
            </Tag>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    var email;
    if (props.isUser === true) {
      email = props.userEmail;
    } else {
      if (!isEmpty(props.selectedUser)) {
        email = props.selectedUser.value;
      } else email = "";
    };
      props.getTopDomain({ number: 5, email: email }, (code, data) => {
        if (code === 200) {
          setDomainList(data);
        } else if (code === 500) {
          message.error("Server Busy !");
        } else message.error("Get top domain fail");
      });
  }, [props.selectedUser, props.isReload]);

  // const onChange = (data) => {
  //   setdomainList(
  //     domainList.map((domain) => {
  //       if (domain.id === data.id) {
  //         domain.completed = !data.completed;
  //       }
  //       return domain;
  //     })
  //   );
  // };

  return (
    <Card
      style={{
        height: "435px",
        borderRadius: "10px",
        boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
        border: "none",
      }}
      title={
        <div className="h4 gx-text-capitalize gx-font-weight-heavy gx-mb-0 gx-text-blue">
          TOP TRAFFIC TODAY
        </div>
      }
    >
      <div className="gx-table-responsive">
        <Table
          style={{ maxWidth: "100%" }}
          className="gx-table-no-bordered"
          columns={columns}
          dataSource={domainList}
          pagination={false}
          size="small"
          rowKey="id"
        />
      </div>
    </Card>
  );
};

export default connect(mapStateToProps, {
  getTopDomain,
})(DomainList);
