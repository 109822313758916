import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import AsyncSelect from "react-select/async";
import axios from "util/Api";
import {
  Table,
  Row,
  Col,
  Tag,
  message,
  Spin,
  Select,
  Typography,
  DatePicker,
} from "antd";
import { connect } from "react-redux";
import { searchEmail } from "../../../appRedux/actions/Dashboard";
import { getMonthReport } from "../../../appRedux/actions/Report";
import {
  formatDataSize,
  formatMonthnthDataSize,
  isEmpty,
} from "../../../util/formatData";
import { Pagination } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Text } = Typography;

const mapStateToProps = () => ({});

const MonthReport = (props) => {
  //#region state
  const [time, setTime] = useState({
    start: "",
    end: "",
  });
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [listData, setListData] = useState([]);
  const [totalData, setTotalData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);
  const [defaultUserList, setDefaultUserList] = useState([]);
  const cookies = new Cookies();
  const roleAccount = cookies.get("role");
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "5%",
      render: (text, record) => listData.indexOf(record) + 1,
    },
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
    },
    {
      title: "Type",
      dataIndex: "isAppDomain",
      key: "isAppDomain",
      render: (isAppDomain) =>
        isAppDomain ? <span>Application</span> : <span>Web</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (disable_p2p, admin_disable_p2p) =>
        admin_disable_p2p && disable_p2p ? (
          <Tag
            style={{ width: 80, textAlign: "center", margin: "auto" }}
            color="#ff4d4f"
          >
            Disable
          </Tag>
        ) : (
          <Tag
            style={{ width: 80, textAlign: "center", margin: "auto" }}
            color="#0388fc"
          >
            Enable
          </Tag>
        ),
    },
    {
      title: "HTTP Traffic (GB) ",
      dataIndex: "traffic_http_month",
      key: "traffic_http_month",
      render: (traffic_http_month) => (
        <span>{formatMonthnthDataSize(traffic_http_month)}</span>
      ),
    },
    {
      title: "P2P Traffic (GB)",
      dataIndex: "traffic_p2p_month",
      key: "traffic_p2p_month",
      render: (traffic_p2p_month) => (
        <span>{formatMonthnthDataSize(traffic_p2p_month)}</span>
      ),
    },
    // {
    //   title: "Peak Concurrent Viewers",
    //   dataIndex: "num_max",
    //   key: "num_max",
    // },
    // {
    //   title: "Playback Sessions",
    //   dataIndex: "api_frequency_month",
    //   key: "api_frequency_month",
    // },
  ];

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
    menu: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
  };

  const onChange = (current, pageSize) => {
    setPage(current);
    setPageSize(pageSize);
  };

  const onChangeDate = (dates, dateStrings) => {
    setTime({
      start: dateStrings[0],
      end: dateStrings[1],
    });
  };

  // Configure react-select
  const loadDefaultUserList = () => {
    if (roleAccount !== "user") {
      props.searchEmail(
        { page: 1, page_size: 20, keyword: "" },
        (code, res) => {
          if (code === 200) {
            setDefaultUserList(res);
          } else message.error("Error when fetching user list !");
        }
      );
    }
  };

  const loadUserOptions = (inputValue) => {
    if (roleAccount !== "user") {
      return axios
        .get(`admin/email/search?page=1&page_size=5&keyword=${inputValue}`)
        .then((res) => res.data.data);
    }
  };

  useEffect(() => {
    var email = "";
    if (roleAccount === "user") {
      email = cookies.get("email");
    } else {
      if (selectedUser) email = selectedUser.value;
      else {
        message.warning("Please choose one user !");
      }
    }
    setIsLoading(true);
    props.getMonthReport(
      {
        page: page,
        page_size: pageSize,
        start: time.start,
        end: time.end,
        email: email,
      },
      (code, res) => handleResult(code, res)
    );
    const handleResult = (code, res) => {
      if (code === 200) {
        setListData(res.list_data);
        setTotalData(res.total_data);
        if (!defaultUserList.length) loadDefaultUserList();
        setTotal(res.total);
      } else if (code === 1007) {
        if (!defaultUserList.length) loadDefaultUserList();
      } else {
        console.warn("Please select time range !");
      }
      setIsLoading(false);
    };
  }, [page, pageSize, selectedUser, time]);

  return (
    <Spin spinning={isLoading} size="large">
      <Row
        // justify="space-between"
        style={{ marginLeft: "-15px", marginRight: "-15px" }}
      >
        <Col
          xs={24}
          sm={24}
          md={6}
          lg={6}
          xl={4}
          xxl={3}
          className="gx-order-lg-1 gx-order-md-1"
          style={{ marginRight: "0px" }}
        >
          <h4
            className="gx-font-weight-semi-bold gx-mb-3"
            style={{ color: "#274679" }}
          >
            MONTH REPORT
          </h4>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={6}
          xxl={6}
          className="gx-order-lg-3  gx-order-md-3"
        >
          {roleAccount === "super" || roleAccount === "admin" ? (
            <AsyncSelect
              className="gx-mb-3"
              placeholder="All user..."
              isClearable={true}
              styles={colourStyles}
              defaultOptions={defaultUserList}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              //value={selectedUser}
              loadOptions={loadUserOptions}
              onChange={(value) => setSelectedUser(value)}
              maxMenuHeight={175}
            />
          ) : null}
        </Col>
        <Col
          xs={0}
          sm={0}
          md={2}
          lg={2}
          xl={8}
          xxl={9}
          className="gx-order-lg-2 gx-order-md-2"
        ></Col>
        <Col
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={6}
          xxl={6}
          className="gx-order-lg-4 gx-order-md-4"
        >
          <RangePicker
            ranges={{
              "Today": [moment(), moment()],
              "Yesterday": [
                moment().subtract(1, "days"), 
                moment().subtract(1, "days")
              ],
              "This Week": [moment().startOf("week"), moment().endOf("week")],
              "This Month": [
                moment().startOf("month"),
                moment().endOf("month"),
              ],
              "Last Months": [
                moment().subtract(1, "months").startOf("month"),
                moment().subtract(1, "months").endOf("month"),
              ],
            }}
            onChange={onChangeDate}
            format="YYYY-MM-DD"
            style={{
              borderRadius: "8px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
              height: "37px",
              width: "100%",
              // minWidth: 140,
              border: "none",
            }}
            className=" gx-mb-4"
          />
        </Col>
      </Row>
      <div
        className="gx-card"
        key="card"
      >
        <Table
          className="gx-table-responsive"
          key="table"
          columns={columns}
          dataSource={listData}
          pagination={false}
          rowKey="id"
          style={{
            border: "none",
            borderRadius: "10px",
            minHeight: "360px",
          }}
          summary={() => {
            return !isEmpty(listData) ? (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={4}>
                    <Text
                      style={{
                        fontWeight: "bolder",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Total
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <span>
                      {!isEmpty(totalData)
                        ? formatDataSize(totalData.traffic_http)
                        : "None"}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <span type="danger">
                      {!isEmpty(totalData)
                        ? formatDataSize(totalData.traffic_p2p)
                        : "None"}
                    </span>
                  </Table.Summary.Cell>
                  {/* <Table.Summary.Cell>
                    <span type="danger">
                      {!isEmpty(totalData) ? totalData.num_max : 0}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <span type="danger">
                      {!isEmpty(totalData) ? totalData.api_frequency : 0}
                    </span>
                  </Table.Summary.Cell> */}
                </Table.Summary.Row>
              </>
            ) : null;
          }}
        />
        <br />
        <Pagination
          showTotal={(total) => `Total ${total} items`}
          total={total}
          defaultCurrent={page}
          defaultPageSize={pageSize}
          onChange={onChange}
          showQuickJumper
          showSizeChanger
          style={{ marginLeft: "20px" }}
        />
      </div>
    </Spin>
  );
};

export default connect(mapStateToProps, {
  getMonthReport,
  searchEmail,
})(MonthReport);
