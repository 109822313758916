import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import AsyncSelect from "react-select/async";
import axios from "util/Api";
import {
  Table,
  Button,
  Row,
  Col,
  message,
  Switch,
  Input,
  Slider,
  Space,
  Spin
} from "antd";
import { FileSearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { searchEmail } from "../../appRedux/actions/Dashboard";
import {
  getDomainList,
  setP2PConfig,
} from "../../appRedux/actions/DomainConfig";
import { Pagination } from "antd";

const { Search } = Input;

const mapStateToProps = () => ({});
const P2PSetting = (props) => {
  //#region state
  const [isLoading, setIsLoading] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const [keyWord, setKeyWord] = useState("");
  const [isRatioChange, setIsRatioChange] = useState(false);
  const [isWifiChange, setIsWifiChange] = useState(false);
  const [ratio, setRatio] = useState(0);
  const [wifiOnly, setWifiOnly] = useState(false);

  const [domainID, setDomainID] = useState(null);
  const [total, setTotal] = useState(50);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [listData, setListData] = useState([]);

  const [selectedUser, setSelectedUser] = useState(null);
  const [defaultUserList, setDefaultUserList] = useState([]);
  const cookies = new Cookies();
  const roleAccount = cookies.get("role");
  const columns = [
    {
      title: "ID",
      width: "10%",
      render: (text, record) => listData.indexOf(record) + 1,
    },
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
    },
    {
      title: "Ratio",
      dataIndex: "ratio",
      key: "ratio",
      width: "30%",
      render: (text, record) => (
        <Slider
          defaultValue={record.ratio}
          onAfterChange={(value) => handleChangeRatio(record.id, value)}
        ></Slider>
      ),
    },
    {
      title: "Ethernet/Wifi Only",
      key: "wifi_only",
      width: "10%",
      render: (text, record) => (
        <Switch
          //  checked={record.wifi_only}
          checkedChildren="enable"
          unCheckedChildren="disable"
          defaultChecked={record.wifi_only}
          onChange={(value) => handleSwitch(record, value)}
        />
      ),
    },
    {
      title: "",
      key: "action",
      width: "15%",
      render: (text, record) =>
        (isWifiChange === true || isRatioChange === true) &&
        domainID === record.id ? (
          <Space size="large">
            <Button
              style={{
                height: "35px",
                margin: "auto",
                backgroundColor: "#0388fc",
                color: "white",
                borderRadius: "8px",
              }}
              onClick={() => handleConfirm(record.id)}
              disabled={
                record.isValid === 0 &&
                (record.disable_p2p === true ||
                  record.admin_disable_p2p === true)
                  ? true
                  : false
              }
            >
              Confirm
            </Button>
            <Button
              style={{
                height: "35px",
                margin: "auto",
                borderRadius: "8px",
              }}
              type="default"
              onClick={cancleConfirm}
            >
              Cancle
            </Button>
          </Space>
        ) : null,
    },
  ];

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
    menu: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
  };

  const handleChangeRatio = (id, ratio) => {
    setRatio(ratio);
    setDomainID(id);
    setIsRatioChange(true);
  };

  const handleConfirm = (id) => {
    if (isRatioChange && isWifiChange) {
      props.setP2PConfig(
        { domain_id: id, ratio: ratio, wifi_only: wifiOnly },
        (code) => {
          if (code === 200) {
            setIsWifiChange(false);
            setIsRatioChange(false);
            message.success("Set P2P Config successfully !");

            setIsFetch(true);
            setIsFetch(false);
          } else {
            message.error("Error when setting P2P Config");
          }
        }
      );
    } else if (isRatioChange && !isWifiChange) {
      props.setP2PConfig({ domain_id: id, ratio: ratio }, (code) => {
        if (code === 200) {
          setIsWifiChange(false);
          setIsRatioChange(false);
          message.success("Set P2P Config successfully !");

          setIsFetch(true);
          setIsFetch(false);
        } else {
          message.error("Error when setting P2P Config");
        }
      });
    } else {
      props.setP2PConfig({ domain_id: id, wifi_only: wifiOnly }, (code) => {
        if (code === 200) {
          setIsWifiChange(false);
          setIsRatioChange(false);
          message.success("Set P2P Config successfully !");

          setIsFetch(true);
          setIsFetch(false);
        } else {
          message.error("Error when setting P2P Config");
        }
      });
    }
  };
  const cancleConfirm = () => {
    setIsRatioChange(false);
    setIsWifiChange(false);
    setIsFetch(true);
  };

  const onChange = (current, pageSize) => {
    setPage(current);
    setPageSize(pageSize);
  };

  const handleSwitch = (record, value) => {
    setIsWifiChange(true);
    setDomainID(record.id);
    setWifiOnly(value);
    //setTimeout(setIsLoading(true), 200);
    // setIsLoading(false);
  };

  // Configure react-select
  const loadDefaultUserList = () => {
    if (roleAccount !== "user") {
      props.searchEmail(
        { page: 1, page_size: 20, keyword: "" },
        (code, res) => {
          if (code === 200) {
            setDefaultUserList(res);
          } else message.error("Error when fetching user list !");
        }
      );
    }
  };

  const loadUserOptions = (inputValue) => {
    if (roleAccount !== "user") {
      return axios
        .get(`admin/email/search?page=1&page_size=5&keyword=${inputValue}`)
        .then((res) => res.data.data);
    }
  };

  useEffect(() => {
    var email = "";
    var searchtype = "all";
    if (roleAccount === "user") {
      email = cookies.get("email");
    } else {
      if (selectedUser) email = selectedUser.value;
    }
    setIsLoading(true);
    props.getDomainList(
      { keyword: keyWord, page: page, page_size: pageSize, email: email, type: searchtype },
      (code, res) => handleResult(code, res)
    );
    const handleResult = (code, res) => {
      if (code === 200) {
        var domainList = [];
        if (res.total > 0) {
          domainList = res.data.filter(function (domain) {
            return domain.isValid === 1;
          });
        }
        setListData(domainList);

        if (!defaultUserList.length) loadDefaultUserList();
        setTotal(res.total);
      } else {
        message.error("Error when getting domain information!");
      }
      setIsLoading(false);
    };
  }, [page, pageSize, keyWord, selectedUser]);

  useEffect(() => {
    if (isFetch === true) {
      var email = "";
      var searchtype = "all";
      if (selectedUser) email = selectedUser.value;
      setIsLoading(true);
      props.getDomainList(
        { keyword: keyWord, page: page, page_size: pageSize, email: email, type: searchtype },
        (code, res) => handleResult(code, res)
      );
      const handleResult = (code, res) => {
        if (code === 200) {
          var domainList = [];
          if(res.total > 0) {
            domainList = res.data.filter(function (domain) {
            return domain.isValid === 1;
            });
          }
          setListData(domainList);

          setTotal(res.total);
        } else {
          message.error("Error when getting domain information!");
        }
        setIsLoading(false);
      };
    }
  }, [page, pageSize, isFetch, keyWord, selectedUser]);

  return (
    <Spin spinning={isLoading} size="large">
      <Row
        // justify="space-between"
        style={{ marginLeft: "-15px", marginRight: "-15px" }}
      >
        <Col
          xs={24}
          sm={24}
          md={4}
          lg={4}
          xl={4}
          xxl={3}
          className="gx-order-lg-1 gx-order-md-1"
          style={{ marginRight: "0px" }}
        >
          <h4
            className="gx-font-weight-semi-bold gx-mb-3"
            style={{ color: "#274679" }}
          >
            ADVANCE SETTING
          </h4>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={7}
          lg={7}
          xl={6}
          xxl={6}
          className="gx-order-lg-3  gx-order-md-3"
        >
          {roleAccount === "super" || roleAccount === "admin" ? (
            <AsyncSelect
              className="gx-mb-3"
              placeholder="All user..."
              isClearable={true}
              styles={colourStyles}
              defaultOptions={defaultUserList}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              //value={selectedUser}
              loadOptions={loadUserOptions}
              onChange={(value) => setSelectedUser(value)}
              maxMenuHeight={175}
            />
          ) : null}
        </Col>
        <Col
          xs={0}
          sm={0}
          md={1}
          lg={4}
          xl={6}
          xxl={7}
          className="gx-order-lg-2 gx-order-md-2"
        ></Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={8}
          xl={8}
          xxl={8}
          className="gx-order-lg-4 gx-order-md-4"
        >
          <Search
            placeholder="Filter by domain ..."
            onSearch={(value) => setKeyWord(value)}
            allowClear
            bordered={false}
            enterButton={<FileSearchOutlined />}
            style={{
              height: "37px",
              backgroundColor: "white",
              borderRadius: "6px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
            }}
          />
        </Col>
      </Row>
      <div
        className="gx-card"
        key="card"
      >
        <Table
          className="gx-table-responsive"
          key="table"
          columns={columns}
          dataSource={listData}
          pagination={false}
          rowKey="id"
          style={{
            border: "none",
            borderRadius: "10px",
            minHeight: "360px",
          }}
        />
        <br />
        <Pagination
          showTotal={(total) => `Total ${total} items`}
          total={total}
          defaultCurrent={page}
          defaultPageSize={pageSize}
          onChange={onChange}
          showQuickJumper
          showSizeChanger
          style={{ marginLeft: "20px" }}
        />
      </div>
    </Spin>
  );
};

export default connect(mapStateToProps, {
  getDomainList,
  searchEmail,
  setP2PConfig,
})(P2PSetting);
