import React from "react";
import { Button, Form, Input, message, Space } from "antd";
import IntlMessages from "util/IntlMessages";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  CheckCircleOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
} from "@ant-design/icons";
import { resetPassword, verifyEmail } from "../appRedux/actions/Auth";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const FormItem = Form.Item;

const ResetPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onFinishFailed = (errorInfo) => {
  };

  const onFinish = (values) => {
    dispatch(
      resetPassword(values, (res) => {
        if (res === 200) {
          message.success("Reset your password successfully !");
          cookies.remove("email_verify");
          cookies.remove("email_token");
          history.push("/auth/signin");
        } else if (res === 1007) {
          message.error("Incorrect verify code !");
        } else if (res === 500) {
          message.error("Server busy ! Please try again !");
        } else message.error("Error when reseting your password !");
      })
    );
  };

  const resendEmail = () => {
    const email =
      cookies.get("email_verify") != null ? cookies.get("email_verify") : null;
    setTimeout(
      message.loading(
        "Please wait a minute ! We will send you an email with a verify code !"
      ),
      2000
    );
    dispatch(
      verifyEmail({ email }, (res) => {
        if (res === 200) {
          message.success(
            "Success! Please check your email to get verify code !"
          );
        } else message.error("Error verifying your email !");
      })
    );
  };

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-login-header">
          <img
            src={require("assets/images/logo-white.png")}
            alt="wieldy"
            title="wieldy"
          />
        </div>
        <div className="gx-mb-4">
          <h2>Reset Your Password ?</h2>
          <p>
            <IntlMessages id="app.userAuth.resetPassword" />
          </p>
        </div>

        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >
          <FormItem
            name="verifyCode"
            rules={[
              { required: true, message: "Please input your confirm code!" },
            ]}
          >
            <Input
              type="number"
              placeholder="Verify Code"
              prefix={
                <CheckCircleOutlined style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />
          </FormItem>

          <FormItem
            name="new_passwd"
            rules={[
              { required: true, message: "Please input your new password!" },
            ]}
          >
            <Input.Password
              type="password"
              placeholder="New Password"
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </FormItem>

          <FormItem
            name="confirm_password"
            dependencies={["new_passwd"]}
            hasFeedback
            rules={[
              { required: true, message: "Please confirm your Password!" },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("new_passwd") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The two passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password
              type="password"
              placeholder="Confirm New Password"
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </FormItem>

          <FormItem>
            <Space size="middle">
              <span>Don't receive any email?</span>
              <span className="gx-link" onClick={() => resendEmail()}>
                Resend
              </span>
            </Space>
          </FormItem>

          <FormItem>
            <Button type="primary" size="large" htmlType="submit">
              Send
            </Button>
            <Link className="gx-login-form-forgot" to="/auth/forgot-password">
              <Button type="default" size="large">
                Cancle
              </Button>
            </Link>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
