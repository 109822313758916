import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import AsyncSelect from "react-select/async";
import axios from "util/Api";
import { connect } from "react-redux";
import {
  Table,
  Row,
  Col,
  Space,
  Tag,
  Button,
  Modal,
  Switch,
  message,
  Pagination,
  Input,
} from "antd";
import {
  AppstoreAddOutlined,
  FileSearchOutlined
} from "@ant-design/icons";
import CreateApp from "./components/CreateApp";
import moment from "moment";
import { searchEmail } from "../../appRedux/actions/Dashboard";
import { setStatusDomain } from "../../appRedux/actions/DomainConfig";
import { getAppList, deleteApp } from "../../appRedux/actions/AppConfig";

const mapStateToProps = () => ({});
const { Search } = Input;
const confirm = Modal.confirm;

const AppConfig = (props) => {
  //#region state
  const [isAddApp, setIsAddApp] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const [submitAddApp, setSubmitAddApp] = useState(false);

  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(50);
  const [keyWord, setKeyWord] = useState("");

  const [selectedUser, setSelectedUser] = useState(null);
  const [defaultUserList, setDefaultUserList] = useState([]);
  const cookies = new Cookies();

  const roleAccount = cookies.get("role");

  const columns = [
    {
      title: "No.",
      width: "5%",
      render: (text, record) => listData.indexOf(record) + 1,
    },
    {
      title: "Name",
      dataIndex: "app_name",
      key: "app_name",
    },
    {
      title: "Domain",
      dataIndex: "app_id",
      key: "app_id",
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      align: "center",
      render: (platform) =>
        platform === "android" ? (
          <span>
            Android
          </span>
        ) : platform === "ios" ? (
          <span>
            IOS
          </span>
        ) : (
          <span>
            PC
          </span>
        ),
    },
    {
      title: "Created Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (time) => moment(time).utc().format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: "Status",
      dataIndex: "isValid",
      key: "isValid",
      width: "10%",
      render: (isValid) =>
        isValid === 1 ? (
          <Tag
            style={{ width: "80px", textAlign: "center", margin: "auto" }}
            color="#0388fc"
            key={isValid}
          >
            Active
          </Tag>
        ) : (
          <Tag
            style={{ width: "80px", textAlign: "center", margin: "auto" }}
            color="#ff4d4f"
            key={isValid}
          >
            InActive
          </Tag>
        ),
    },
    {
      title: "Actions",
      key: "action",
      width: "20%",
      render: (text, record) => (
        <Space size="large" style={{ alignItems: "center" }}>
          <Switch
            checked={stateSwitch(record)}
            checkedChildren="enable"
            unCheckedChildren="disable"
            onChange={() => handleSwitch(record)}
          />
          {/* <Button type="danger" align="middle" size="small" icon={<DeleteOutlined />} onClick={() => setIsDelete(true)} >Delete</Button> */}
        </Space>
      ),
    },
  ];

  const adminColumns = [
    {
      title: "No.",
      width: "5%",
      render: (text, record) => listData.indexOf(record) + 1,
    },
    {
      title: "Name",
      dataIndex: "app_name",
      key: "app_name",
    },
    {
      title: "Domain",
      dataIndex: "app_id",
      key: "app_id",
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      align: "center",
      render: (platform) =>
        platform === "android" ? (
          <span>
            Android
          </span>
        ) : platform === "ios" ? (
          <span>
            IOS
          </span>
        ) : (
          <span>
            PC
          </span>
        ),
    },
    {
      title: "Created Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (time) => moment(time).utc().format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: "Status",
      dataIndex: "isValid",
      key: "isValid",
      width: "10%",
      render: (isValid) =>
        isValid === 1 ? (
          <Tag color="#0388fc" key={isValid} style={{width: "80px", textAlign: "center", margin: "auto"}}>
            Active
          </Tag>
        ) : (
          <Tag color="#ff4d4f" key={isValid} style={{width: "80px", textAlign: "center", margin: "auto"}}>
            InActive
          </Tag>
        ),
    },
    {
      title: "Setting",
      key: "setting",
      width: "10%",
      render: (text, record) => (
        <Space size="large" style={{ alignItems: "center" }}>
          <Switch
            checked={stateSwitch(record)}
            checkedChildren="enable"
            unCheckedChildren="disable"
            onChange={() => handleSwitch(record)}
          />
        </Space>
      ),
    },
    {
      title: "",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <Space style={{ alignItems: "center" }}>
          <span className="gx-link" onClick={() => confimDelete(record)}>
            <Button
              style={{
                    width: "80px",
                    height: "35px",
                    margin: "auto",
                    color: "white",
                    background: "#ff4d4f"
                  }}
            >
              Delete
            </Button>
          </span>
        </Space>
      ),
    },
  ];

  const confimDelete = (record) => {
    confirm({
      title: "Do you want to delete this app ?",
      content: "If you agree, please click OK ",
      onOk() {
        props.deleteApp(record.id, (code) => {
          if (code === 200) {
            setIsFetch(true);
            onClose();
            message.success("Delete App Successfully !");
          } else message.error("Error when deleting app !");
        });
      },
      onCancel() {
      },
    });
  };

  const handleSwitch = (record) => {
    //setTimeout(setIsLoading(true), 200);
    const handleResult = (code, res) => {
      if (code === 200) {
        message.success("Set status domain successfully");
        setIsFetch(true);
        setIsFetch(false);
      } else {
        message.error("Error when set status app information!");
      }
    };

    if (roleAccount === "user" && !record.admin_disable_p2p) {
      props.setStatusDomain(
        { domain_id: record.domain_id, disable: !record.disable_p2p },
        (code, res) => handleResult(code, res)
      );
    } else if (roleAccount === "admin" || roleAccount === "super") {
      props.setStatusDomain(
        { domain_id: record.domain_id, disable: !record.admin_disable_p2p },
        (code, res) => handleResult(code, res)
      );
    }
  };

  const stateSwitch = (record) => {
    if (roleAccount === "user") {
      if (!record.admin_disable_p2p) return !record.disable_p2p;
      return !record.admin_disable_p2p;
    } else {
      return !record.admin_disable_p2p;
    }
  };

  const onClose = () => {
    setSubmitAddApp(false);
    setIsAddApp(false);
    setIsFetch(false);
  };
  const onIsFetch = () => {
    setIsFetch(true);
  };

  const onChange = (currentPage, pageSize) => {
    setPage(currentPage);
    setPageSize(pageSize);
  };

  // Configure react-select
  const loadDefaultUserList = () => {
    if (roleAccount !== "user") {
      props.searchEmail(
        { page: 1, page_size: 20, keyword: "" },
        (code, res) => {
          if (code === 200) {
            setDefaultUserList(res);
          } else message.error("Error when fetching user list !");
        }
      );
    }
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
    menu: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
  };

  const loadUserOptions = (inputValue) => {
    if (roleAccount !== "user") {
      return axios
        .get(`admin/email/search?page=1&page_size=5&keyword=${inputValue}`)
        .then((res) => res.data.data);
    }
  };

  useEffect(() => {
    var email = "";
    if (selectedUser) email = selectedUser.value;
    props.getAppList(
      { page: page, page_size: pageSize, keyword: keyWord, email: email },
      (code, res) => handleResult(code, res)
    );
    const handleResult = (code, res) => {
      if (code === 200) {
        setListData(res.data);

        if (!defaultUserList.length) loadDefaultUserList();
        setTotal(res.total);
      } else {
        message.error("Error when getting app information!");
      }
    };
  }, [page, pageSize, keyWord, selectedUser]);

  useEffect(() => {
    if (isFetch) {
      var email = "";
      if (selectedUser) email = selectedUser.value;
      props.getAppList(
        { page: page, page_size: pageSize, keyword: keyWord, email: email },
        (code, res) => handleResult(code, res)
      );
      const handleResult = (code, res) => {
        if (code === 200) {
          setListData(res.data);
          setTotal(res.total);
        } else {
          message.error("Error when getting app information!");
        }
      };
    }
  }, [page, pageSize, keyWord, isFetch, selectedUser]);

  return (
    <div>
      <Row
        justify="space-between"
        style={{ marginLeft: "-15px", marginRight: "-15px" }}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <h4
            className="gx-font-weight-semi-bold gx-mb-3"
            style={{ color: "#274679" }}
          >
            APP CONFIG
          </h4>
        </Col>
        <Col
          xs={8}
          sm={8}
          md={4}
          lg={4}
          xl={4}
          xxl={3}
          className="gx-order-lg-1 gx-order-md-1"
          style={{ marginRight: "0px" }}
        >
          <Button
            type="default"
            style={{
              fontWeight: "bold",
              color: "#274679",
              border: "none",
              borderRadius: "8px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
            }}
            icon={<AppstoreAddOutlined />}
            onClick={() => setIsAddApp(true)}
          >
            Add App
          </Button>
        </Col>
        <Col
          xs={16}
          sm={16}
          md={7}
          lg={7}
          xl={6}
          xxl={6}
          className="gx-order-lg-3  gx-order-md-3"
        >
          {roleAccount === "super" || roleAccount === "admin" ? (
            <AsyncSelect
              className="gx-mb-3"
              placeholder="All user..."
              isClearable={true}
              styles={colourStyles}
              defaultOptions={defaultUserList}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              loadOptions={loadUserOptions}
              onChange={(value) => setSelectedUser(value)}
              maxMenuHeight={175}
            />
          ) : null}
        </Col>
        <Col
          xs={0}
          sm={0}
          md={1}
          lg={4}
          xl={6}
          xxl={7}
          className="gx-order-lg-2 gx-order-md-2"
        ></Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={8}
          xl={8}
          xxl={8}
          className="gx-order-lg-4 gx-order-md-4"
        >
          <Search
            placeholder="Filter app ..."
            onSearch={(value) => setKeyWord(value)}
            allowClear
            enterButton={<FileSearchOutlined />}
            bordered={false}
            style={{
              backgroundColor: "white",
              borderRadius: "6px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
            }}
          />
        </Col>
      </Row>
      <div key="card" className="gx-card">
        <Table
          key="table"
          className="gx-table-responsive"
          columns={roleAccount === "user" ? columns : adminColumns}
          dataSource={listData}
          pagination={false}
          style={{
            border: "none",
            borderRadius: "10px",
            minHeight: "360px",
          }}
        />
        <br />
        <Pagination
          showTotal={(total) => `Total ${total} items`}
          total={total}
          defaultCurrent={page}
          defaultPageSize={pageSize}
          onChange={onChange}
          showQuickJumper
          showSizeChanger
          style={{ marginLeft: "20px" }}
        />
      </div>

      {isAddApp && (
        <Modal
          title="Input App Information"
          width={720}
          wrapClassName="vertical-center-modal"
          visible={isAddApp}
          // onOk={() => setSubmitAddApp(true)}
          onCancel={() => setIsAddApp(false)}
          footer={
            <span>
              <Button onClick={() => setIsAddApp(false)}>Cancel</Button>
              <Button
                type="primary"
                form="createApp"
                key="submit"
                htmlType="submit"
              >
                Submit
              </Button>
            </span>
          }
        >
          <CreateApp
            submitAddApp={submitAddApp}
            onClose={onClose}
            onIsFetch={onIsFetch}
          />
        </Modal>
      )}
    </div>
  );
};

export default connect(mapStateToProps, {
  getAppList,
  setStatusDomain,
  searchEmail,
  deleteApp,
})(AppConfig);
