import { FETCH_ERROR } from "../../constants/ActionTypes";
import reportApi from "../../util/ReportApi";
import axios from "util/Api";
import qs from "qs";

/**
 * Get top 5 domain
 *
 */
export const getReportData = (
  { domain, email, selectedtime },
  callback = () => {}
) => {
  return (dispatch) => {
    reportApi
      .get(
        `report/chart/month?domain=${domain}&email=${email}&selectedtime=${selectedtime}`
      )
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(res.data.code, res.data.data);
          }
        } else callback(res.data.code, null);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404, null);
        }
      });
  };
};

export const getAdvanceReportData = (
  { type, domain_id },
  callback = () => {}
) => {
  return (dispatch) => {
    reportApi
      .get(`report/advance?domain_id=${domain_id}&type=${type}`)
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(res.data.code, res.data.data);
          }
        } else callback(res.data.code, null);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404, null);
        }
      });
  };
};

export const getMonthReport = (
  { page, page_size, start, end, email },
  callback = () => {}
) => {
  return (dispatch) => {
    axios
      .get(
        `report/month?email=${email}&start=${start}&end=${end}&page_size=${page_size}&page=${page}`
      )
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(res.data.code, res.data.data);
          }
        } else callback(res.data.code, null);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404, null);
        }
      });
  };
};

export const getUserTrafficReport = (
  email,
  remain_under,
  page,
  pageSize,
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    reportApi
      .get(
        `usertraffic/traffic?email=${email}&remain_under=${remain_under}&page=${page}&page_size=${pageSize}`
      )
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(200, res.data.data);
          }
        } else callback(404);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};
