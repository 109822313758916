import React, { useState, useEffect } from "react";
import { Button, Col, Row, Progress, Tag, message } from "antd";
import { getUserTrafficByEmail } from "../../../../appRedux/actions/UserTraffic";
import { connect } from "react-redux";
import moment from "moment";
import { formatDataSize } from "../../../../util/formatData";

const mapStateToProps = () => ({});
const PacketData = (props) => {
  const [packetInfo, setPacketInfo] = useState(null);

  useEffect(() => {
    if (props.userEmail) {
      props.getUserTrafficByEmail(props.userEmail, (code, res) => {
        if (code === 200) {
          setPacketInfo(res);
        } else message.error("Error when getting newest packet info !");
      });
    }
  }, [props.userEmail]);
  return (
    <div>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
          <div className="h4 gx-mb-3 gx-text-eco-2 gx-font-weight-semi-bold">
            LAST TRANSACTION
          </div>
          <div className="ant-row-flex gx-mb-4">
            <div className=" h3 gx-mr-2 gx-mb-0 gx-text-capitalize gx-fs-xxxl gx-text-eco-3 gx-font-weight-bold">
              {packetInfo && packetInfo.newest_userpack
                ? packetInfo.newest_userpack.pack_name
                : ""}
            </div>
            {/* <h4 className="gx-pt-2 gx-chart-up gx-text-capitalize">
              $1000 <i className="icon icon-menu-up gx-fs-sm" />
            </h4> */}
          </div>
          <div className="gx-mb-4">
            <div className="h5 gx-mb-0 gx-text-eco-2 gx-font-weight-semi-bold">
              Traffic Activation:{" "}
              <a>
                {" "}
                {packetInfo && packetInfo.usertraffic
                  ? moment(packetInfo.usertraffic.created)
                      .utc()
                      .format("MM-DD-YYYY hh:mm:ss")
                  : ""}
              </a>
            </div>
            <br></br>
            <div className="h5 gx-mb-0 gx-text-eco-2 gx-font-weight-semi-bold">
              Traffic Expiration:{" "}
              <a>
                {" "}
                {packetInfo && packetInfo.usertraffic
                  ? moment(packetInfo.usertraffic.expired)
                      .utc()
                      .format("MM-DD-YYYY hh:mm:ss")
                  : ""}
              </a>
            </div>
          </div>
          <div className="ant-row-flex gx-mb-3 gx-mb-md-2">
            <Button className="gx-mr-2" type="primary">
              Buy
            </Button>
            <Button className="gx-btn-orange">Detail</Button>
          </div>
        </Col>
        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
          <div className="h4 gx-mb-3 gx-text-eco-2 gx-font-weight-semi-bold">
            TOTAL PACKET TRAFFIC
          </div>
          <div className="gx-site-dash">
            <ul className="gx-line-indicator gx-fs-sm gx-pb-1 gx-pb-sm-0">
              <li>
                <span>
                  <strong>Total | </strong>
                </span>
                <Tag color="orange">
                  {packetInfo && packetInfo.usertraffic
                    ? formatDataSize(packetInfo.usertraffic.buyedtraffic)
                    : "0 KB"}
                </Tag>
                <Progress
                  strokeColor="orange"
                  size="small"
                  percent={100}
                  status="active"
                />
              </li>
              <li>
                <span>
                  <strong>Used |</strong>{" "}
                </span>
                <Tag color="red">
                  {packetInfo && packetInfo.usertraffic
                    ? formatDataSize(packetInfo.usertraffic.usedtraffic)
                    : "0 KB"}
                </Tag>
                <Progress
                  size="small"
                  strokeColor="red"
                  percent={
                    packetInfo && packetInfo.usertraffic
                      ? Math.floor(
                          (packetInfo.usertraffic.usedtraffic /
                            packetInfo.usertraffic.buyedtraffic) *
                            100
                        )
                      : 0
                  }
                />
              </li>
              <li>
                <span>
                  <strong>Remain | </strong>
                </span>
                <Tag color="green">
                  {packetInfo && packetInfo.usertraffic
                    ? formatDataSize(
                        packetInfo.usertraffic.buyedtraffic -
                          packetInfo.usertraffic.usedtraffic
                      )
                    : "0 KB"}
                </Tag>
                <Progress
                  strokeColor={{
                    from: "#108ee9",
                    to: "#87d068",
                  }}
                  size="small"
                  percent={
                    packetInfo && packetInfo.usertraffic
                      ? Math.floor(
                          ((packetInfo.usertraffic.buyedtraffic -
                            packetInfo.usertraffic.usedtraffic) /
                            packetInfo.usertraffic.buyedtraffic) *
                            100
                        )
                      : 0
                  }
                  status="active"
                />
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, {
  getUserTrafficByEmail,
})(PacketData);
