import React, { useState, useEffect } from "react";
import { Form, Input, message, Col, Row, Select } from "antd";
import { GlobalOutlined } from "@ant-design/icons";
import { updateDataPack } from "../../../appRedux/actions/DataPack";
import { connect } from "react-redux";

const mapStateToProps = () => ({});

function EditPackage(props) {
  const Option = Select.Option;
  const [packageInfo, setPackageInfo] = useState([]);

  const onChange = (newFields) => {
    setPackageInfo(newFields);
  };

  const handleUpdate = (res) => {
    if (res === 200) {
      message.success("Update package information successfully!");
      props.onIsFetch(true);
    } else message.error("Error when updating package information !");
    props.onClose();
  };

  useEffect(() => {
    if (props.packageInfo) {
      setPackageInfo([
        {
          name: ["active"],
          value: props.packageInfo.active,
        },
        {
          name: ["public"],
          value: props.packageInfo.public,
        },
        // {
        //   name: ["expired"],
        //   value: props.packageInfo.expired,
        // },
        {
          name: ["default"],
          value: props.packageInfo.default,
        },
        {
          name: ["description"],
          value: props.packageInfo.description,
        },
      ]);
    }
  }, [props.packageInfo]);

  useEffect(() => {
    if (props.submitUpdate === true) {
      props.updateDataPack(packageInfo, props.packageInfo.id, (res) =>
        handleUpdate(res)
      );
    }
  }, [props.submitUpdate]);

  return (
    <Form
      name="basic"
      className="gx-signin-form gx-form-row0"
      fields={packageInfo}
      onFieldsChange={(changedFields, allFields) => {
        onChange(allFields);
      }}
    >
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Active</div>
          <Form.Item
            name="active"
            rules={[{ required: true, message: "Please choose active or not" }]}
          >
            <Select>
              <Option value={true}>Active</Option>
              <Option value={false}>InActive</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Public</div>
          <Form.Item
            name="public"
            rules={[
              { required: true, message: "Please choose public or not!" },
            ]}
          >
            <Select>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        {/* <Col span={12}>
          <div className="gx-font-weight-semi-bold" style={{ marginBottom: 6 }}>
            Expired
          </div>
          <Form.Item
            name="expired"
            rules={[{ required: true, message: "Please input expired date!" }]}
          >
            <Input
              suffix={<strong className="gx-text-eco-2">Months</strong>}
              placeholder="Expired (Months)"
              type="number"
            />
          </Form.Item>
        </Col> */}
        <Col span={12}>
          <div className="gx-font-weight-semi-bold" style={{ marginBottom: 6 }}>
            Default Package
          </div>
          <Form.Item
            name="default"
            rules={[
              {
                required: true,
                message: "Please choose is default package or not!",
              },
            ]}
          >
            <Select>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Description</div>
          <Form.Item
            name="description"
            rules={[{ required: true, message: "Please input description !" }]}
          >
            <Input.TextArea
              style={{ minHeight: 34 }}
              prefix={<GlobalOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Description ..."
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default connect(mapStateToProps, { updateDataPack })(EditPackage);
