import { FETCH_ERROR, FETCH_START } from "../../constants/ActionTypes";
import qs from "qs";
import axios from "util/Api";

/**
 *  Hàm lay thông tin list transaction
 * @param {} page
 * @param {} page_size
 * @param {*} callback
 */
export const getListTransaction = (
  { page, page_size, keyword, email, trans_status },
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .get(
        `transaction/search?email=${email}&keyword=${keyword}&page=${page}&page_size=${page_size}&trans_status=${trans_status}`
      )
      .then(({ data }) => {
        if (data.code === 200) {
          if (typeof callback === "function") {
            callback(200, data.data);
          }
        } else callback(404);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

/**
 *  Hàm them transaction
 * @param {} transactionInfo
 */
export const createTransaction = (
  transactionInfo,
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post(
        `transaction`,
        qs.stringify({
          email:
            transactionInfo && transactionInfo[0]
              ? transactionInfo[0].value
              : "",
          pack_name:
            transactionInfo && transactionInfo[1]
              ? transactionInfo[1].value
              : "",
          trans_id:
            transactionInfo && transactionInfo[2]
              ? transactionInfo[2].value
              : "",
          origin_price:
            transactionInfo && transactionInfo[3]
              ? transactionInfo[3].value
              : 0,
          real_price:
            transactionInfo && transactionInfo[4]
              ? transactionInfo[4].value
              : 0,
          paid:
            transactionInfo && transactionInfo[5]
              ? transactionInfo[5].value
              : 0,
          currency:
            transactionInfo && transactionInfo[6]
              ? transactionInfo[6].value
              : "",

          pay_id:
            transactionInfo && transactionInfo[7]
              ? transactionInfo[7].value
              : null,
          status:
            transactionInfo && transactionInfo[8]
              ? transactionInfo[8].value
              : "",
          description:
            transactionInfo && transactionInfo[9]
              ? transactionInfo[9].value
              : "",
        })
      )
      .then(({ data }) => {
        if (data.code === 200) {
          if (typeof callback === "function") {
            callback(200, data);
          }
        } else callback(404);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

export const updateTransaction = (
  id,
  transInfo,
  callback = (response) => {}
) => {
  return (dispatch) => {
    axios
      .put(
        "transaction",
        qs.stringify({
          id: id,
          paid: transInfo && transInfo[0] ? transInfo[0].value : 0,

          status: "cancel",
          paydate: transInfo && transInfo[2] ? transInfo[2].value : "",
        })
      )
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(res.data.code);
          }
        } else {
          callback(res.data.code);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        callback(404);
      });
  };
};

export const acceptTransaction = (
  id,
  paid,
  description,
  callback = (response) => {}
) => {
  return (dispatch) => {
    axios
      .put(
        "transaction/acceptance",
        qs.stringify({
          id: id,
          paid: paid,
          description: description,
        })
      )
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(res.data.code);
          }
        } else {
          callback(res.data.code);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        callback(404);
      });
  };
};
