import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import AsyncSelect from "react-select/async";
import axios from "util/Api";
import {
  Table,
  Button,
  Row,
  Col,
  Tag,
  Modal,
  message,
  Input,
  Select,
} from "antd";
import { PlusCircleOutlined, FileSearchOutlined } from "@ant-design/icons";
import CreateOrder from "./components/CreateOrder";
import EditTransaction from "./components/EditTransaction";
import Detail from "./components/Detail";
import { connect } from "react-redux";
import { searchEmail } from "../../appRedux/actions/Dashboard";
import {
  getListTransaction,
  updateTransaction,
} from "../../appRedux/actions/Transaction";
import { Pagination } from "antd";
import moment from "moment";

const { Search } = Input;
const { Option, OptGroup } = Select;
const confirm = Modal.confirm;

const mapStateToProps = () => ({});
const TransactionManager = (props) => {
  //#region state
  const [selectedTrans, setSelectedTrans] = useState({});
  const [isFetch, setIsFetch] = useState(false);
  const [keyWord, setKeyWord] = useState("");
  const [isCreateOrder, setIsCreateOrder] = useState(false);
  const [submitAdd, setSubmitAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [submitUpdate, setSubmitUpdate] = useState(false);
  const [total, setTotal] = useState(50);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [listData, setListData] = useState([]);
  const [status, setStatus] = useState("pending");

  const [selectedUser, setSelectedUser] = useState(null);
  const [defaultUserList, setDefaultUserList] = useState([]);
  const cookies = new Cookies();
  const roleAccount = cookies.get("role");

  const pendingColumns = [
    {
      title: "ID",
      dataIndex: "trans_id",
      key: "trans_id",
    },
    {
      title: "Email ",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Pack Name",
      dataIndex: "pack_name",
      key: "pack_name",
    },
    {
      title: "Order Code",
      dataIndex: "ordercode",
      key: "ordercode",
      render: (text, record) => <a onClick={() => onDetail(record)}>{text}</a>,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) =>
        status === "success" ? (
          <Tag
            color="#0388fc"
            style={{ width: 80, textAlign: "center", margin: "auto" }}
          >
            Success
          </Tag>
        ) : status === "pending" ? (
          <Tag
            color="#87d068"
            style={{ width: 80, textAlign: "center", margin: "auto" }}
          >
            Pending
          </Tag>
        ) : (
          <Tag
            color="#ff4d4f"
            style={{ width: 80, textAlign: "center", margin: "auto" }}
          >
            Cancel
          </Tag>
        ),
    },
    {
      title: "Pay date",
      dataIndex: "paydate",
      key: "paydate",
      render: (time) => moment(time).utc().format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: "",
      key: "action",
      width: "220px",
      render: (text, record) =>
        record.status === "pending" ? (
          <span className="gx-link">
            <Button
              style={{ width: "80px", height: "35px", margin: "auto" }}
              type="primary"
              onClick={() => onEdit(record)}
            >
              Confirm
            </Button>
            <Button
              className="gx-ml-3"
              style={{
                width: "80px",
                height: "35px",
                margin: "auto",
                color: "white",
                background: "#ff4d4f"
              }}
              onClick={() => confirmCancel(record)}
            >
              Cancel
            </Button>
          </span>
        ) : null,
    },
  ];

  const columns = [
    {
      title: "ID",
      dataIndex: "trans_id",
      key: "trans_id",
    },
    {
      title: "Email ",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Pack Name",
      dataIndex: "pack_name",
      key: "pack_name",
    },
    {
      title: "Order Code",
      dataIndex: "ordercode",
      key: "ordercode",
      render: (text, record) => <a onClick={() => onDetail(record)}>{text}</a>,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) =>
        status === "success" ? (
          <Tag
            color="#0388fc"
            style={{ width: 80, textAlign: "center", margin: "auto" }}
          >
            Success
          </Tag>
        ) : status === "pending" ? (
          <Tag
            color="#87d068"
            style={{ width: 80, textAlign: "center", margin: "auto" }}
          >
            Pending
          </Tag>
        ) : (
          <Tag
            color="#ff4d4f"
            style={{ width: 80, textAlign: "center", margin: "auto" }}
          >
            Cancel
          </Tag>
        ),
    },
    {
      title: "Pay date",
      dataIndex: "paydate",
      key: "paydate",
      render: (time) => moment(time).utc().format("DD-MM-YYYY HH:mm:ss"),
    },
  ];

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
    menu: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
  };

  const onIsFetch = (isFetch) => {
    setIsFetch(isFetch);
  };

  const onClose = () => {
    setSubmitAdd(false);
    setSubmitUpdate(false);
    setIsCreateOrder(false);
    setIsEdit(false);
    setIsFetch(false);
    setIsDetail(false);
  };

  const onChange = (current, pageSize) => {
    setPage(current);
    setPageSize(pageSize);
  };

  const onEdit = (record) => {
    setIsEdit(true);
    setSelectedTrans(record);
  };

  const handleChangeStatus = (status) => {
    setStatus(status);
  };

  const onDetail = (record) => {
    setIsDetail(true);
    setSelectedTrans(record);
  };
  // Configure react-select
  const loadDefaultUserList = () => {
    if (roleAccount !== "user") {
      props.searchEmail(
        { page: 1, page_size: 20, keyword: "" },
        (code, res) => {
          if (code === 200) {
            setDefaultUserList(res);
          } else message.error("Error when fetching user list !");
        }
      );
    }
  };

  const confirmCancel = (record) => {
    confirm({
      title: "Do you want to cancel this transaction ?",
      content: "If you agree, please click OK ",
      onOk() {
        props.updateTransaction(
          record.trans_id,
          [
            {
              name: "paid",
              value: record.paid,
            },
            {
              name: "status",
              value: record.status,
            },
            {
              name: "paydate",
              value: record.pay_date,
            },
          ],
          (code) => {
            if (code === 200) {
              setIsFetch(true);
              message.success("Cancel Transaction Successfully !");
              setIsFetch(false);
            } else message.error("Error when cancel this transaction !");
          }
        );
      },
      onCancel() {},
    });
  };

  const loadUserOptions = (inputValue) => {
    if (roleAccount !== "user") {
      return axios
        .get(`admin/email/search?page=1&page_size=5&keyword=${inputValue}`)
        .then((res) => res.data.data);
    }
  };

  useEffect(() => {
    var email = "";
    if (selectedUser) email = selectedUser.value;
    props.getListTransaction(
      {
        keyword: keyWord,
        page: page,
        page_size: pageSize,
        email: email,
        trans_status: status,
      },
      (code, res) => handleResult(code, res)
    );
    const handleResult = (code, res) => {
      if (code === 200) {
        setListData(res.data);

        if (!defaultUserList.length) loadDefaultUserList();
        setTotal(res.total);
      } else {
        message.error("Error when getting transaction information!");
      }
    };
  }, [page, pageSize, keyWord, selectedUser, status]);

  useEffect(() => {
    if (isFetch === true) {
      var email = "";
      if (selectedUser) email = selectedUser.value;
      props.getListTransaction(
        {
          keyword: keyWord,
          page: page,
          page_size: pageSize,
          email: email,
          trans_status: status,
        },
        (code, res) => handleResult(code, res)
      );
      const handleResult = (code, res) => {
        if (code === 200) {
          setListData(res.data);
          setTotal(res.total);
        } else {
          message.error("Error when getting transaction information!");
        }
      };
    }
  }, [page, pageSize, isFetch, keyWord, selectedUser, status]);

  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <h4
            className="gx-font-weight-semi-bold gx-mb-3"
            style={{ color: "#274679" }}
          >
            TRANSACTION MANAGER
          </h4>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={8}
          lg={4}
          xl={4}
          xxl={4}
          className="gx-order-lg-1 gx-order-md-1"
          style={{ marginRight: "0px" }}
        >
          <Button
            type="default"
            style={{
              // backgroundColor: "#274679",
              fontWeight: "bold",
              height: "37px",
              color: "#274679",
              border: "none",
              borderRadius: "8px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
            }}
            icon={<PlusCircleOutlined />}
            onClick={() => setIsCreateOrder(true)}
          >
            Create Order
          </Button>
        </Col>
        <Col
          xs={0}
          sm={0}
          md={0}
          lg={1}
          xl={2}
          xxl={4}
          className="gx-order-lg-1 gx-order-md-1"
        ></Col>
        <Col
          xs={12}
          sm={12}
          md={8}
          lg={7}
          xl={6}
          xxl={4}
          className="gx-order-lg-3  gx-order-md-3"
        >
          {roleAccount === "super" || roleAccount === "admin" ? (
            <AsyncSelect
              className="gx-mb-3"
              placeholder="All user..."
              isClearable={true}
              styles={colourStyles}
              defaultOptions={defaultUserList}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              loadOptions={loadUserOptions}
              onChange={(value) => setSelectedUser(value)}
              maxMenuHeight={175}
            />
          ) : null}
        </Col>
        <Col
          xs={12}
          sm={12}
          md={8}
          lg={4}
          xl={4}
          xxl={4}
          className="gx-order-lg-2 gx-order-md-2"
        >
          <Select
            defaultValue={status}
            bordered={false}
            style={{
              borderRadius: "8px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
              height: "37px",
              width: "100%",
              // minWidth: 140,
              backgroundColor: "white",
            }}
            onChange={(status) => handleChangeStatus(status)}
            className=" gx-mb-4"
          >
            <OptGroup label="Status">
              <Option value="success">Success</Option>
              <Option value="pending">Pending</Option>
              <Option value="cancel">Cancel</Option>
            </OptGroup>
          </Select>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={24}
          lg={8}
          xl={8}
          xxl={8}
          className="gx-order-lg-4 gx-order-md-4"
        >
          <Search
            placeholder="Filter by order code ..."
            onSearch={(value) => setKeyWord(value)}
            allowClear
            bordered={false}
            enterButton={<FileSearchOutlined />}
            style={{
              height: "37px",
              backgroundColor: "white",
              borderRadius: "6px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
            }}
          />
        </Col>
      </Row>
      <div
        className="gx-card"
        key="card"
      >
        <Table
          className="gx-table-responsive"
          key="table"
          columns={status === "pending" ? pendingColumns : columns}
          dataSource={listData}
          pagination={false}
          rowKey="id"
          style={{
            border: "none",
            borderRadius: "10px",
            minHeight: "360px",
          }}
        />
        <br />
        <Pagination
          showTotal={(total) => `Total ${total} items`}
          total={total}
          defaultCurrent={page}
          defaultPageSize={pageSize}
          onChange={onChange}
          showQuickJumper
          showSizeChanger
          style={{ marginLeft: "20px" }}
        />

        {isCreateOrder && (
          <Modal
            maskClosable={false}
            title={
              <div className="gx-font-weight-semi-bold gx-text-eco-1">
                CREATE ORDER
              </div>
            }
            width={720}
            wrapClassName="vertical-center-modal"
            visible={isCreateOrder}
            onOk={() => setSubmitAdd(true)}
            onCancel={() => setIsCreateOrder(false)}
            centered={true}
          >
            <CreateOrder
              submitAdd={submitAdd}
              onClose={onClose}
              onIsFetch={onIsFetch}
            />
          </Modal>
        )}
        {isEdit && (
          <Modal
            maskClosable={false}
            title={
              <div className="gx-font-weight-semi-bold gx-text-eco-1">
                CONFIRM TRANSACTION
              </div>
            }
            width={720}
            wrapClassName="vertical-center-modal"
            visible={isEdit}
            onOk={() => setSubmitUpdate(true)}
            onCancel={() => setIsEdit(false)}
            centered={true}
          >
            <EditTransaction
              selectedTrans={selectedTrans}
              submitUpdate={submitUpdate}
              onClose={onClose}
              onIsFetch={onIsFetch}
            />
          </Modal>
        )}
        {isDetail && (
          <Modal
            maskClosable={false}
            title={
              <div className="gx-font-weight-semi-bold gx-text-eco-1">
                TRANSACTION DETAIL
              </div>
            }
            width={720}
            wrapClassName="vertical-center-modal"
            visible={isDetail}
            onOk={() => setIsDetail(false)}
            onCancel={() => setIsDetail(false)}
            centered={true}
          >
            <Detail selectedTrans={selectedTrans} onClose={onClose} />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, {
  getListTransaction,
  searchEmail,
  updateTransaction,
})(TransactionManager);
