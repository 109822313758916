import React, { useState, useEffect } from "react";
import { Col, message, Row, Card, Button } from "antd";
import AsyncSelect from "react-select/async";

import Auxiliary from "util/Auxiliary";

import DomainList from "./components/TopDomain/DomainList";
import P2POffload from "./components/P2POffloadMonth/P2POffload";
import P2PTrafficMonth from "./components/P2PTrafficMonth/P2PTrafficMonth";
import PacketData from "./components/PacketData/PacketData";
import TopBuyUse from "./components/TopBuyUse/TopBuyUse";
import axios from "util/Api";
import {
  getDomainList,
  getDomainInfo,
  searchEmail,
  getTotalTrafficMonth,
} from "../../appRedux/actions/Dashboard";
import { connect } from "react-redux";
import { formatDataSize, isEmpty, formatPercent } from "../../util/formatData";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";

const cookies = new Cookies();
const roleAccount = cookies.get("role");
const decoded_token =
  cookies.get("token") != null ? jwt_decode(cookies.get("token")) : null;
const userEmail = decoded_token != null ? decoded_token.email : null;

const mapStateToProps = () => ({});
const Dashboard = (props) => {
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedDomain, setSelectedDomain] = useState({});
  const [domainInfo, setDomainInfo] = useState([]);
  const [p2pTrafficMonth, setP2pTrafficMonth] = useState(0);
  const [offloadTrafficMonth, setOffloadTrafficMonth] = useState(0);
  const [defaultUserList, setDefaultUserList] = useState([]);
  const [defaultDomainList, setDefaultDomainList] = useState([]);
  const [isReload, setIsReload] = useState(true);

  //#region  Xu ly select & search user

  // handle selection
  const handleChange = (value) => {
    setSelectedUser(value);
  };

  const handleDomainChange = (value) => {
    setSelectedDomain(value);
  };

  const loadUserOptions = (inputValue) => {
    return axios
      .get(`admin/email/search?page=1&page_size=5&keyword=${inputValue}`)
      .then((res) => res.data.data);
  };

  const loadDomainOptions = (inputValue) => {
    var url = ``;
    var email;
    if (roleAccount === "super" || roleAccount === "admin") {
      email = isEmpty(selectedUser) ? "" : selectedUser.value;
    } else email = userEmail;

    if (isEmpty(selectedUser)) {
      url = `domain/search?page=1&page_size=10&keyword=${inputValue}`;
    } else
      url = `domain/search?email=${email}&page=1&page_size=10&keyword=${inputValue}`;
    return axios.get(url).then((res) => res.data.data.data);
  };

  useEffect(() => {
    if (roleAccount === "admin" || roleAccount === "super") {
      props.searchEmail(
        { page: 1, page_size: 20, keyword: "" },
        (code, res) => {
          if (code === 200) {
            setDefaultUserList(res);
          } else message.error("Error when fetching user list !");
        }
      );
    }
  }, []);

  useEffect(() => {
    var email;
    if (roleAccount === "super" || roleAccount === "admin") {
      email = isEmpty(selectedUser) ? "" : selectedUser.value;
    } else email = userEmail;
    props.getDomainList(
      {
        page: 1,
        page_size: 20,
        keyword: "",
        email: email,
      },
      (code, res) => {
        if (code === 200) {
          setDefaultDomainList(res.data);
        } else message.error("Error when fetching domain list !");
      }
    );
  }, [selectedUser]);

  useEffect(() => {
    var email;
    if (roleAccount === "super" || roleAccount === "admin") {
      if (isEmpty(selectedUser)) {
        email = "";
      } else email = selectedUser.value;
    } else email = userEmail;

    props.getDomainInfo(
      {
        email: email,
        domain: isEmpty(selectedDomain) ? "" : selectedDomain.domain,
      },
      (code, res) => {
        if (code === 200) {
          setDomainInfo(res);
        } else message.error("Error when fetching domain info !");
      }
    );

    props.getTotalTrafficMonth(
      {
        email: email,
        domain: isEmpty(selectedDomain) ? "" : selectedDomain.domain,
        month: new Date().getMonth() + 1,
      },
      (code, res) => {
        if (code === 200) {
          setP2pTrafficMonth(res.p2p_traffic_month);
          setOffloadTrafficMonth(res.p2p_offload_month);
        } else message.error("Error when fetching total traffic info !");
      }
    );
  }, [selectedUser, selectedDomain, isReload]);

  //#endregion

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
    menu: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
  };

  const check_admin = roleAccount === "admin" || roleAccount === "super"
  return (
    <Auxiliary>
      <Row>
        {check_admin ? (
          <Col xxl={6} xl={6} lg={12} md={12} sm={12} xs={24}>
            <AsyncSelect
              className="gx-mb-4"
              placeholder="All user..."
              styles={colourStyles}
              isClearable={true}
              defaultOptions={defaultUserList}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              loadOptions={loadUserOptions}
              onChange={handleChange}
              maxMenuHeight={175}
            />
          </Col>
        ) : null}
        <Col xxl={6} xl={6} lg={12} md={12} sm={12} xs={24}>
          <AsyncSelect
            placeholder="All domain..."
            className="gx-mb-4"
            styles={colourStyles}
            isClearable={true}
            defaultOptions={defaultDomainList}
            getOptionLabel={(e) => e.domain}
            getOptionValue={(e) => e.id}
            loadOptions={loadDomainOptions}
            onChange={handleDomainChange}
            maxMenuHeight={175}
          />
        </Col>
        <Col xxl={check_admin ? 12 : 18} xl={check_admin ? 12 : 18} lg={check_admin ? 24 : 12} md={check_admin ? 24 : 12} sm={check_admin ? 24 : 12} xs={check_admin ? 24 : 24}>
          <Button
            type="primary"
            className="gx-mb-0"
            style={{ height: "38px", float: "right", width: "100px" }}
            onClick={() => {
              let state = isReload;
              setIsReload(!state);
            }}
          >Refresh
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xl={6} lg={12} md={12} sm={12} xs={24}>
          <Card
            style={{
              backgroundColor: "#0beef9",
              backgroundImage: `linear-gradient(315deg, #0beef9 0%, #48a9fe 95%)`,
              borderRadius: "10px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.05)",
            }}
          >
            <div className="gx-text-center gx-text-capitalize gx-text-uppercase gx-mb-2 gx-font-weight-heavy gx-text-white">
              CURRENT PEERS
            </div>
            <div className="h1 gx-text-center gx-text-capitalize gx-mb-0 gx-font-weight-semi-bold gx-text-white">
              {isEmpty(domainInfo) ? 0 : domainInfo.num_rt}
            </div>
          </Card>
        </Col>
        <Col xl={6} lg={12} md={12} sm={12} xs={24}>
          <Card
            style={{
              backgroundColor: "#0beef9",
              backgroundImage: `linear-gradient(315deg, #0beef9 0%, #48a9fe 75%)`,
              borderRadius: "10px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.05)",
            }}
          >
            <div className="gx-text-center gx-text-capitalize gx-text-uppercase gx-mb-2 gx-font-weight-heavy gx-text-white">
              P2P TRAFFIC TODAY
            </div>
            <div className="h1 gx-text-center gx-text-capitalize gx-mb-0 gx-font-weight-semi-bold gx-text-white">
              {isEmpty(domainInfo)
                ? "O KB"
                : formatDataSize(domainInfo.traffic_p2p_day)}
            </div>
          </Card>
        </Col>
        <Col xl={6} lg={12} md={12} sm={12} xs={24}>
          <Card
            style={{
              backgroundColor: "#0beef9",
              backgroundImage: `linear-gradient(315deg, #0beef9 0%, #48a9fe 55%)`,
              borderRadius: "10px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.05)",
            }}
          >
            <div className="gx-text-center gx-text-capitalize gx-text-uppercase gx-mb-2 gx-font-weight-heavy gx-text-white">
              HTTP TRAFFIC TODAY
            </div>
            <div className="h1 gx-text-center gx-text-capitalize gx-mb-0 gx-font-weight-semi-bold gx-text-white">
              {isEmpty(domainInfo)
                ? "0 KB"
                : formatDataSize(domainInfo.traffic_http_day)}
            </div>
          </Card>
        </Col>
        <Col xl={6} lg={12} md={12} sm={12} xs={24}>
          <Card
            style={{
              backgroundColor: "#0beef9",
              backgroundImage: `linear-gradient(315deg, #0beef9 0%, #48a9fe 35%)`,
              borderRadius: "10px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.05)",
            }}
          >
            <div className="gx-text-center gx-text-capitalize gx-text-uppercase gx-mb-2 gx-font-weight-heavy gx-text-white">
              P2P OFFLOAD TODAY
            </div>
            <div className="h1 gx-text-center gx-text-capitalize gx-mb-0 gx-font-weight-semi-bold gx-text-white">
              {isEmpty(domainInfo)
                ? "0%"
                : formatPercent(
                  domainInfo.traffic_p2p_day,
                  domainInfo.traffic_http_day
                ) + " %"}
            </div>
          </Card>
        </Col>

        <Col
          xl={12}
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className="gx-order-sm-4 gx-order-xl-2"
        >
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <P2PTrafficMonth p2pTrafficMonth={p2pTrafficMonth} />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <P2POffload offloadTrafficMonth={offloadTrafficMonth} />
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card
                style={{
                  height: "270px",
                  borderRadius: "10px",
                  boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
                  border: "none",
                }}
              >
                {roleAccount === "super" || roleAccount === "admin" ? (
                  <TopBuyUse />
                ) : (
                  <PacketData userEmail={userEmail} />
                )}
              </Card>
            </Col>
          </Row>
        </Col>
        <Col
          xl={12}
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className="gx-order-sm-1 gx-order-xl-1"
        >
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <DomainList
                isUser={roleAccount === "user"}
                userEmail={userEmail}
                selectedUser={selectedUser}
                isReload={isReload}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Auxiliary>
  );
};

export default connect(mapStateToProps, {
  getDomainList,
  searchEmail,
  getDomainInfo,
  getTotalTrafficMonth,
})(Dashboard);
