import { FETCH_ERROR, FETCH_START } from "../../constants/ActionTypes";
import qs from "qs";
import axios from "util/Api";

/**
 *  Hàm lay thông tin DataPack
 * @param {} id
 */
export const getDataPackInfo = ({ id }, callback = (code, response) => {}) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .get(`pack/information?id=${id}`)
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(200, res.data.data);
          }
        } else callback(404);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

/**
 *  Hàm lay thông tin list Datapack
 * @param {} page
 * @param {} page_size
 * @param {*} callback
 */
export const searchDataPack = (
  { page, page_size, keyword },
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .get(`pack/search?keyword=${keyword}&page=${page}&page_size=${page_size}`)
      .then(({ data }) => {
        if (data.code === 200) {
          if (typeof callback === "function") {
            callback(200, data.data);
          }
        } else callback(404);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

/**
 *  Hàm them datapack
 * @param {} dataPackInfo
 */
export const createDataPack = (
  dataPackInfo,
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post(
        `pack`,
        qs.stringify({
          name:
            dataPackInfo[0] && dataPackInfo[0].value != null
              ? dataPackInfo[0].value
              : null,
          value:
            dataPackInfo[1] && dataPackInfo[1].value != null
              ? dataPackInfo[1].value
              : null,
          real_price:
            dataPackInfo[2] && dataPackInfo[2].value != null
              ? dataPackInfo[2].value
              : null,
          origin_price:
            dataPackInfo[3] && dataPackInfo[3].value != null
              ? dataPackInfo[3].value
              : null,
          currency:
            dataPackInfo[4] && dataPackInfo[4].value != null
              ? dataPackInfo[4].value
              : null,
          expired:
            dataPackInfo[5] && dataPackInfo[5].value != null
              ? dataPackInfo[5].value
              : null,
          active:
            dataPackInfo[6] && dataPackInfo[6].value != null
              ? dataPackInfo[6].value
              : null,
          public:
            dataPackInfo[7] && dataPackInfo[7].value != null
              ? dataPackInfo[7].value
              : null,
          isdefault:
            dataPackInfo[8] && dataPackInfo[8].value != null
              ? dataPackInfo[8].value
              : null,
          description:
            dataPackInfo[9] && dataPackInfo[9].value != null
              ? dataPackInfo[9].value
              : null,
        })
      )
      .then(({ data }) => {
        if (data.code === 200) {
          if (typeof callback === "function") {
            callback(200, data);
          }
        } else callback(404);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

/**
 *  Hàm delete datapack
 * @param {} dataPackID
 */

export const deleteDatapack = (
  dataPackID,
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .delete(`pack/${dataPackID}`)
      .then(({ data }) => {
        if (data.code === 200) {
          if (typeof callback === "function") {
            callback(200, data.data);
          }
        } else callback(404);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

/**
 *  Hàm update thông tin data Pack (admin  mới có quyền)
 * @param {} dataPackInfo
 * @param {} dataPackID
 * @param {} status
 * @param {*} callback
 */
export const updateDataPack = (
  dataPackInfo,
  dataPackID,
  callback = (response) => {}
) => {
  return (dispatch) => {
    axios
      .put(
        "pack",
        qs.stringify({
          id: dataPackID,
          active:
            (dataPackInfo != null) & (dataPackInfo[0] != null)
              ? dataPackInfo[0].value
              : null,
          public:
            (dataPackInfo != null) & (dataPackInfo[1] != null)
              ? dataPackInfo[1].value
              : null,
          // expired:
          //   (dataPackInfo != null) & (dataPackInfo[2] != null)
          //     ? dataPackInfo[2].value
          //     : null,
          default:
            (dataPackInfo != null) & (dataPackInfo[2] != null)
              ? dataPackInfo[2].value
              : null,
          description:
            (dataPackInfo != null) & (dataPackInfo[3] != null)
              ? dataPackInfo[3].value
              : null,
        })
      )
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(res.data.code);
          }
        } else {
          callback(res.data.code);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        callback(404);
      });
  };
};
