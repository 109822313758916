import { FETCH_ERROR, FETCH_START } from "../../constants/ActionTypes";
import qs from "qs";
import axios from "util/Api";

/**
 *  Hàm lay thông tin DataPack
 * @param {} id
 */
export const getUserTrafficByEmail = (
  email,
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .get(`usertraffic/information?email=${email}`)
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(200, res.data.data);
          }
        } else callback(404);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

export const getTopUserTraffic = (size, callback = (code, response) => {}) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .get(`usertraffic/toptraffic?top=${size}`)
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(200, res.data.data);
          }
        } else callback(404);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};
