import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const token = cookies.get("token");

// const REPORT_URL = (process.env.NODE_ENV == 'production') ? process.env.REACT_APP_REPORT_URL_PRODUCT : process.env.REACT_APP_REPORT_URL_LOCAL;

const REPORT_URL = process.env.REACT_APP_REPORT_URL;

var authorization = "";

if (token != null) {
  authorization = "Bearer " + token;
}

export default axios.create({
  baseURL: REPORT_URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    Authorization: authorization,
  },
});
