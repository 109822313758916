import React, { useState, useEffect } from "react";
import {
  Dropdown,
  Menu,
  Table,
  Row,
  Col,
  Tag,
  Modal,
  Input,
  Button,
  message,
} from "antd";
import {
  EditOutlined,
  ExportOutlined,
  UserAddOutlined,
  DeleteOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { getListUser, deleteUser } from "../../appRedux/actions/User";
import Detail from "./components/Detail";
import EditUser from "./components/EditUser";
import CreateUser from "./components/CreateUser";
import ChangePass from "./components/ChangePassword";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Pagination } from "antd";

const { Search } = Input;
const confirm = Modal.confirm;

const UserManager = (props) => {
  const dispatch = useDispatch();
  //#region state
  const [isAddUser, setIsAddUser] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isChangePass, setIsChangePass] = useState(false);

  // 4 props chuyen tu component user manager sang component edit user
  const [submitAdd, setSubmitAdd] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [submitUpdate, setSubmitUpdate] = useState(false);
  const [submitChangePass, setSubmitChangePass] = useState(false);
  const [isFetch, setIsFetch] = useState(false);

  const [keyWord, setKeyWord] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(50);
  const [listData, setListData] = useState([]);

  const columns = [
    {
      title: "ID",
      dataIndex: "user_id",
      key: "user_id",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) =>
        status === 1 ? (
          <Tag color="#0388fc" style={{width: 80, textAlign: "center", margin: "auto"}}>Active</Tag>
        ) : (
          <Tag color="#ff4d4f" style={{width: 80, textAlign: "center", margin: "auto"}}>InActive</Tag>
        ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) =>
        type === 0 ? (
          <Tag style={{width: 80, textAlign: "center", margin: "auto"}} color="cyan">
            User
          </Tag>
        ) : type === 1 ? (
          <Tag style={{width: 80, textAlign: "center", margin: "auto"}} color="#108ee9">
            Admin
          </Tag>
        ) : (
          <Tag style={{width: 80, textAlign: "center", margin: "auto"}} color="#ff4d4f">
            Super
          </Tag>
        ),
    },
    {
      title: "Created Time",
      dataIndex: "created",
      key: "created",
      width: "15%",
      render: (created) => moment(created).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: "",
      key: "action",
      render: (text, record) => (
        <Dropdown overlay={menu} trigger={["click"]}>
          <a>
            <i
              onClick={() => setSelectedUser(record)}
              className="icon icon-ellipse-h"
            ></i>
          </a>
        </Dropdown>
        // <Popover placement="bottomLeft" content={content} trigger="click">
        //   <a>
        //     <i
        //       onClick={() => setSelectedUser(record)}
        //       className="icon icon-ellipse-h"
        //     ></i>
        //   </a>
        // </Popover>
      ),
    },
  ];

  const menu = (
    <Menu style={{ width: 150 }}>
      <Menu.Item>
        <a
          target="_blank"
          style={{ fontSize: 17 }}
          className="gx-text-blue"
          rel="noopener noreferrer"
          onClick={() => handleDetail()}
        >
          <ExportOutlined style={{ fontSize: 17, paddingLeft: 20 }} /> Detail
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          style={{ fontSize: 17 }}
          className="gx-text-blue"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleEdit()}
        >
          <EditOutlined style={{ fontSize: 17, paddingLeft: 20 }} /> Edit
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          className="gx-text-red"
          style={{ fontSize: 17 }}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => confimDelete()}
        >
          <DeleteOutlined style={{ fontSize: 17, paddingLeft: 20 }} /> Delete
        </a>
      </Menu.Item>
    </Menu>
  );
  // const content = (
  //   <div>
  //     <div
  //       className="gx-link"
  //       onClick={() => handleDetail()}
  //       // style={{ color: "red", alignItems: "center" }}
  //     >
  //       <ExportOutlined /> Detail
  //     </div>
  //     <div
  //       style={{ marginTop: "5px" }}
  //       className="gx-link gx-text-hover"
  //       onClick={() => handleEdit()}
  //       // style={{ color: "red", alignItems: "center" }}
  //     >
  //       <EditOutlined /> Edit
  //     </div>
  //     <div
  //       style={{ marginTop: "5px", color: "red" }}
  //       className="gx-link gx-text-hover"
  //       onClick={() => confimDelete()}
  //       // style={{ color: "red", alignItems: "center" }}
  //     >
  //       <DeleteOutlined /> Delete
  //     </div>
  //   </div>
  // );

  const confimDelete = () => {
    confirm({
      title: "Do you want to delete this user: " + selectedUser.email + " ?",
      content: "If you agree, please click OK ",
      onOk() {
        dispatch(
          deleteUser(selectedUser.user_id, (code) => {
            if (code === 200) {
              setIsFetch(true);
              onClose();
              message.success("User is deleted!");
            } else message.error("Error when deleting user!");
          })
        );
      },
      onCancel() {
      },
    });
  };

  const handleDetail = () => {
    setIsDetail(true);
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  const onClose = () => {
    setSubmitUpdate(false);
    setIsEdit(false);
    setIsFetch(false);
    setIsAddUser(false);
  };

  const onCloseChangePass = (isChangePass) => {
    setSubmitChangePass(false);
    setIsChangePass(isChangePass);
    setIsFetch(false);
  };

  const onIsFetch = (isFetch) => {
    setIsFetch(isFetch);
  };

  const onIsChangePass = (isChangePass) => {
    setIsChangePass(isChangePass);
  };

  const onCancleChangePass = () => {
    setIsChangePass(false);
    setSubmitChangePass(false);
  };

  const onChange = (current, pageSize) => {
    setPage(current);
    setPageSize(pageSize);
  };

  useEffect(() => {
    dispatch(
      getListUser({ page, pageSize, keyWord }, (code, res) => {
        if (code === 200) {
          setListData(res.data);
          setTotal(res.total);
        } else console.log("error");
      })
    );
  }, [page, pageSize, keyWord]);

  useEffect(() => {
    if (isFetch) {
      dispatch(
        getListUser({ page, pageSize, keyWord }, (code, res) => {
          if (code === 200) {
            setListData(res.data);
            setTotal(res.total);
          } else console.log("error");
        })
      );
    }
  }, [page, pageSize, isFetch, keyWord]);

  return (
    <div>
      <Row
        justify="space-between"
        style={{ marginLeft: "-15px", marginRight: "-15px" }}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <h4
            className="gx-font-weight-semi-bold gx-mb-3"
            style={{ color: "#274679" }}
          >
            USER MANAGER
          </h4>
        </Col>
        <Col xs={8} sm={8} md={4} lg={4} xl={4} xxl={4}>
          <Button
            type="default"
            style={{
              fontWeight: "bold",
              color: "#274679",
              border: "none",
              borderRadius: "8px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
            }}
            icon={<UserAddOutlined />}
            onClick={() => setIsAddUser(true)}
          >
            Add User
          </Button>
        </Col>
        <Col xs={16} sm={16} md={12} lg={7} xl={8} xxl={8}>
          <Search
            placeholder="Filter by email ..."
            onSearch={(value) => setKeyWord(value)}
            allowClear
            enterButton={<UserOutlined />}
            bordered={false}
            style={{
              backgroundColor: "white",
              borderRadius: "6px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
            }}
          />
        </Col>
      </Row>
      <div className="gx-card">
        <Table
          className="gx-table-responsive"
          key="table"
          columns={columns}
          dataSource={listData}
          pagination={false}
          rowKey="user_id"
          style={{
            border: "none",
            borderRadius: "10px",
            minHeight: "360px",
          }}
        />
        <br />
        <Pagination
          showTotal={(total) => `Total ${total} items`}
          total={total}
          defaultCurrent={page}
          defaultPageSize={pageSize}
          onChange={onChange}
          showQuickJumper
          showSizeChanger
          style={{ marginLeft: "20px" }}
        />
      </div>

      {isAddUser && (
        <Modal
          title="Add new user"
          width={720}
          wrapClassName="vertical-center-modal"
          visible={isAddUser}
          onOk={() => setSubmitAdd(true)}
          onCancel={() => setIsAddUser(false)}
        >
          <CreateUser
            submitAdd={submitAdd}
            onClose={onClose}
            onIsFetch={onIsFetch}
          />
        </Modal>
      )}

      {isDetail && (
        <Modal
          title="User Information Detail"
          width={720}
          wrapClassName="vertical-center-modal"
          visible={isDetail}
          onOk={() => setIsDetail(false)}
          onCancel={() => setIsDetail(false)}
        >
          <Detail userID={selectedUser.user_id} />
        </Modal>
      )}

      {isEdit && (
        <Modal
          title="Edit User Information"
          width={720}
          wrapClassName="vertical-center-modal"
          visible={isEdit}
          onOk={() => setSubmitUpdate(true)}
          onCancel={() => setIsEdit(false)}
        >
          <EditUser
            userID={selectedUser.user_id}
            userStatus={selectedUser.status}
            userType={selectedUser.type}
            userEmail={selectedUser.email}
            submitUpdate={submitUpdate}
            onIsChangePass={onIsChangePass}
            onClose={onClose}
            onIsFetch={onIsFetch}
          />
        </Modal>
      )}

      {isChangePass && (
        <Modal
          title="Change User Password"
          width={720}
          wrapClassName="vertical-center-modal"
          visible={isChangePass}
          onOk={() => setSubmitChangePass(true)}
          onCancel={() => onCancleChangePass()}
        >
          <ChangePass
            userEmail={selectedUser.email}
            submitChangePass={submitChangePass}
            onCloseChangePass={onCloseChangePass}
            onIsFetch={onIsFetch}
          />
        </Modal>
      )}
    </div>
  );
};

export default UserManager;
