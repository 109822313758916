import React, { useState, useEffect } from "react";
import { Tooltip, Table, Tag, message, Tabs } from "antd";
import { getTopUserTraffic } from "../../../../appRedux/actions/UserTraffic";
import { connect } from "react-redux";
import { formatDataSize } from "../../../../util/formatData";
import moment from "moment";

const mapStateToProps = () => ({});
const { TabPane } = Tabs;
const TopBuyUse = (props) => {
  const [listBuy, setListBuy] = useState([]);
  const [listUsed, setListUsed] = useState([]);
  const [tab, setTab] = useState("buy"); // 1- buy ; 2 - use

  const buyColumns = [
    {
      title: "Top",
      width: "40px",
      render: (text, record) => {
        return <b className="gx-text-blue">{listBuy.indexOf(record) + 1}</b>;
      },
    },
    {
      title: "Email",
      key: "email",
      render: (text, record) => {
        return <span className="gx-text-blue">{record.email}</span>;
      },
    },

    {
      title: "Paid Traffic",
      key: "buyedtraffic",
      width: "120px",
      render: (text, record) => {
        return (
          <Tooltip title="Paid Traffic" color="blue">
            <Tag
              className="gx-bg-blue gx-text-white"
              style={{ width: "105px", textAlign: "center" }}
            >
              {record.buyedtraffic != null
                ? formatDataSize(record.buyedtraffic)
                : 0}
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      title: "Activation Date",
      dataIndex: "created",
      key: "created",
      //  width: "25%",
      render: (time) => moment(time).utc().format("DD-MM-YYYY HH:mm:ss"),
    },
  ];

  const useColumns = [
    {
      title: "Top",
      width: "10%",
      render: (text, record) => {
        return <b className="gx-text-blue">{listUsed.indexOf(record) + 1}</b>;
      },
    },
    {
      title: "Email",
      key: "email",
      render: (text, record) => {
        return <span className="gx-text-blue">{record.email}</span>;
      },
    },

    {
      title: "Used Traffic",
      key: "usedtraffic",
      width: "120px",
      render: (text, record) => {
        return (
          <Tooltip title="Used Traffic" color="orange">
            <Tag
              className="gx-bg-orange gx-text-white"
              style={{ width: "105px", textAlign: "center" }}
            >
              {record.usedtraffic != null
                ? formatDataSize(record.usedtraffic)
                : 0}
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      title: "Activation Date",
      dataIndex: "created",
      key: "created",
      // width: "25%",
      render: (time) => moment(time).utc().format("DD-MM-YYYY HH:mm:ss"),
    },
  ];

  useEffect(() => {
    if (tab === "buy") {
      props.getTopUserTraffic(5, (code, data) => {
        if (code === 200) {
          setListBuy(data.top_buyed);
        } else if (code === 500) {
          message.error("Server Busy !");
        } else message.error("Get top buy traffic fail !");
      });
    } else {
      props.getTopUserTraffic(5, (code, data) => {
        if (code === 200) {
          setListUsed(data.top_used);
        } else if (code === 500) {
          message.error("Server Busy !");
        } else message.error("Get top uses traffic fail !");
      });
    }
  }, [tab]);
  return (
    <Tabs
      defaultActiveKey={tab}
      style={{ marginTop: "-15px" }}
      onChange={(key) => setTab(key)}
    >
      <TabPane tab={<b>TOP BUY TRAFFIC</b>} key="buy">
        <Table
          className="gx-table-no-bordered"
          columns={buyColumns}
          scroll={{ y: 150 }}
          dataSource={listBuy}
          pagination={false}
          size="small"
          rowKey="id"
        />
      </TabPane>
      <TabPane tab={<b>TOP USE TRAFFIC</b>} key="use">
        <Table
          className="gx-table-no-bordered"
          columns={useColumns}
          scroll={{ y: 150 }}
          dataSource={listUsed}
          pagination={false}
          size="small"
          rowKey="id"
        />
      </TabPane>
    </Tabs>
  );
};

export default connect(mapStateToProps, {
  getTopUserTraffic,
})(TopBuyUse);
