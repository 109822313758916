import React from "react";
import { Button, Form, Input, message } from "antd";
import IntlMessages from "util/IntlMessages";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { MailOutlined } from "@ant-design/icons";
import { verifyEmail } from "../appRedux/actions/Auth";

const FormItem = Form.Item;

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onFinishFailed = (errorInfo) => {
  };

  const onFinish = (values) => {
    setTimeout(
      message.loading(
        "Please wait a minute ! We will send you an email with a verify code !"
      ),
      2000
    );
    dispatch(
      verifyEmail(values, (res) => {
        if (res === 200) {
          message.success(
            "Success! Please check your email to get verify code !"
          );
          history.push("/auth/forgot-password/reset");
        } else if (res === 1007) {
          message.error("Incorrect email !");
        } else if (res === 500) {
          message.error("Server busy ! Please try again !");
        } else message.error("Error verifying your email !");
      })
    );
  };

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-login-header">
          <img
            src={require("assets/images/logo-white.png")}
            alt="wieldy"
            title="wieldy"
          />
        </div>
        <div className="gx-mb-4">
          <h2>Forgot Your Password ?</h2>
          <p>
            <IntlMessages id="app.userAuth.forgot" />
          </p>
        </div>

        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >
          <FormItem
            name="email"
            rules={[{ required: true, message: "Please input your E-mail!" }]}
          >
            <Input
              className="gx-input-lineheight"
              type="email"
              placeholder="E-mail Address"
              prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            />
          </FormItem>

          <FormItem>
            <Button type="primary" htmlType="submit">
              Send
            </Button>
            <Link className="gx-login-form-forgot" to="/auth/signin">
              <Button type="default">Cancle</Button>
            </Link>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
