import React, { useState, useEffect } from "react";
import Widget from "../../../components/Widget";
import { Avatar, Row, message, Col } from "antd";
import { getUserInfo } from "../../../appRedux/actions/Auth";
import { connect } from "react-redux";
import moment from "moment";

const mapStateToProps = () => ({});
const Contact = (props) => {
  const [userInfo, setUserInfo] = useState({});
  const [isFetch, setIsFetch] = useState(false);

  useEffect(() => {
    if (props.isFetch === true) {
      setIsFetch(true);
    }
  }, [props.isFetch]);

  useEffect(() => {
    props.getUserInfo((code, res) => handleResult(code, res));
    const handleResult = (code, res) => {
      if (code === 200) {
        setUserInfo(res);
        setIsFetch(false);
      } else message.error("Error when getting user information!");
    };
  }, []);

  useEffect(() => {
    if (isFetch === true) {
      props.getUserInfo((code, res) => handleResult(code, res));
    }
    const handleResult = (code, res) => {
      if (code === 200) {
        setUserInfo(res);
        setIsFetch(false);
      } else message.error("Error when getting user information!");
    };
  }, [isFetch]);

  const contactList = [
    {
      id: 1,
      title: "User Name",
      icon: "user-o",
      desc: [
        <span className="gx-link" key={1}>
          {userInfo.user_name}
        </span>,
      ],
    },
    {
      id: 2,
      title: "Full Name",
      icon: "keyboard",
      desc: [
        <span className="gx-link" key={2}>
          {userInfo.firstname} {userInfo.lastname}
        </span>,
      ],
    },
    {
      id: 3,
      title: "Email",
      icon: "email",
      desc: [
        <span className="gx-link" key={3}>
          {userInfo.email}
        </span>,
      ],
    },
  ];

  const contactList2 = [
    {
      id: 4,
      title: "Date of Birth",
      icon: "birthday",
      desc: [
        <span className="gx-link" key={4}>
          {moment(userInfo.dateofbirth).format("DD-MM-YYYY")}
        </span>,
      ],
    },
    {
      id: 5,
      title: "Phone",
      icon: "phone",
      desc: [
        <span className="gx-link" key={5}>
          {userInfo.phone}
        </span>,
      ],
    },
    {
      id: 6,
      title: "Sex",
      icon: "user-o",
      desc: [
        <span className="gx-link" key={6}>
          {userInfo.sex}
        </span>,
      ],
    },
  ];

  return (
    <Widget styleName="gx-card-profile-sm">
      <br />
      <Row justify="center">
        <Avatar
          className="gx-size-90"
          alt="..."
          src={"https://placebeard.it/150x150"}
        />
      </Row>
      <br />
      <Row justify="center">
        <h4>{userInfo.user_name}</h4>
      </Row>
      <br />
      <Row justify="space-between">
        <Col span="13">
          {contactList.map((data, index) => (
            <div
              key={index}
              className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list"
            >
              <div className="gx-mr-3">
                <i
                  className={`icon icon-${data.icon} gx-fs-xxl gx-text-grey`}
                />
              </div>
              <div className="gx-media-body">
                <span className="gx-mb-0 gx-text-grey gx-fs-sm">
                  {data.title}
                </span>
                <p className="gx-mb-0" style={{ wordBreak: "break-word" }}>
                  {data.desc}
                </p>
              </div>
            </div>
          ))}
        </Col>
        <Col span="11">
          {contactList2.map((data, index) => (
            <div
              key={index}
              className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list"
            >
              <div className="gx-mr-3">
                <i
                  className={`icon icon-${data.icon} gx-fs-xxl gx-text-grey`}
                />
              </div>
              <div className="gx-media-body">
                <span className="gx-mb-0 gx-text-grey gx-fs-sm">
                  {data.title}
                </span>
                <p className="gx-mb-0">{data.desc}</p>
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </Widget>
  );
};

export default connect(mapStateToProps, { getUserInfo })(Contact);
