import { FETCH_ERROR } from "../../constants/ActionTypes";
import axios from "util/Api";

/**
 * Get top 5 domain
 *
 */
export const getTopDomain = (
  { number, email },
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    var url = `domain/day?top=${number}&email=${email}`;
    axios
      .get(url)
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(res.data.code, res.data.data);
          }
        } else callback(res.data.code, null);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404, null);
        }
      });
  };
};

export const searchEmail = (
  { page, page_size, keyword },
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    axios
      .get(
        `/admin/email/search?keyword=${keyword}&page=${page}&page_size=${page_size}`
      )
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(res.data.code, res.data.data);
          }
        } else callback(res.data.code);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

export const getP2PTrafficMonth = (
  { domain_id },
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    axios
      .get(`domain/history/p2p/month?domain_id=${domain_id}`)
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(res.data.code, res.data.data);
          }
        } else callback(res.data.code, null);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404, null);
        }
      });
  };
};

export const getHTTPTrafficMonth = (
  { domain_id },
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    axios
      .get(`domain/history/http/month?domain_id=${domain_id}`)
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(res.data.code, res.data.data);
          }
        } else callback(res.data.code, null);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404, null);
        }
      });
  };
};

export const getDomainList = (
  { page, page_size, email, keyword },
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    var url = `domain/search?email=${email}&keyword=${keyword}&page=${page}&page_size=${page_size}&type=all`;
    axios
      .get(url)
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(200, res.data.data);
          }
        } else callback(res.data.code);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

/**
 *  Hàm lay thông tin Domain Info theo user_id va domain_id
 * @param {} user_id
 * @param {} domain_id
 * @param {*} callback
 */
export const getDomainInfo = (
  { email, domain },
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    axios
      .get(`domain/information?email=${email}&domain=${domain}`)
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(200, res.data.data);
          }
        } else callback(res.data.code);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

export const getTotalTrafficMonth = (
  { email, domain, month },
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    axios
      .get(
        `domain/history/month?domain=${domain}&email=${email}&month=${month}`
      )
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(res.data.code, res.data.data);
          }
        } else callback(res.data.code, null);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404, null);
        }
      });
  };
};
