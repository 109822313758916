import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import AsyncSelect from "react-select/async";
import axios from "util/Api";
import {
  Table,
  Row,
  Col,
  message,
  Spin,
  Select,
  Input,
} from "antd";
import { connect } from "react-redux";
import { searchEmail } from "../../../appRedux/actions/Dashboard";
import { getUserTrafficReport } from "../../../appRedux/actions/Report";
import {
  formatMonthnthDataSize,
} from "../../../util/formatData";
import { Pagination } from "antd";
import moment from "moment";

const { Option } = Select;

const mapStateToProps = () => ({});

const UserTraffic = (props) => {
  //#region state

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);
  const [defaultUserList, setDefaultUserList] = useState([]);

  const [typeData, setTypeData] = useState("KB");
  const [remainValue, setRemainValue] = useState(0);
  const [remain, setRemain] = useState(-1);
  const cookies = new Cookies();
  const roleAccount = cookies.get("role");
  const columns = [
    {
      title: "User ID",
      dataIndex: "user_id",
      key: "user_id",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Buyed Traffic (GB)",
      dataIndex: "buyedtraffic",
      key: "buyedtraffic",
      render: (buyedtraffic) => (
        <span>{formatMonthnthDataSize(buyedtraffic)}</span>
      ),
    },
    {
      title: "Remain Traffic (GB)",
      dataIndex: "remaintraffic",
      key: "remaintraffic",
      render: (remaintraffic) => (
        <span>{formatMonthnthDataSize(remaintraffic)}</span>
      ),
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: (time) => moment(time).utc().format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: "Expired",
      dataIndex: "expired",
      key: "expired",
      render: (time) => moment(time).utc().format("DD-MM-YYYY HH:mm:ss"),
    },
  ];

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
    menu: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
  };

  const onChange = (current, pageSize) => {
    setPage(current);
    setPageSize(pageSize);
  };

  // Configure react-select
  const loadDefaultUserList = () => {
    if (roleAccount != "user") {
      props.searchEmail(
        { page: 1, page_size: 20, keyword: "" },
        (code, res) => {
          if (code == 200) {
            setDefaultUserList(res);
          } else message.error("Error when fetching user list !");
        }
      );
    }
  };

  const loadUserOptions = (inputValue) => {
    if (roleAccount != "user") {
      return axios
        .get(`admin/email/search?page=1&page_size=5&keyword=${inputValue}`)
        .then((res) => res.data.data);
    }
  };
  const handleSelectTypeData = (typeData) => {
    if (typeData == "KB") {
      setTypeData("KB");
      setRemain(remainValue);
    } else if (typeData == "GB") {
      setTypeData("GB");
      setRemain(remainValue * 1024 * 1024);
    } else if (typeData == "TB") {
      setTypeData("TB");
      setRemain(remainValue * 1024 * 1024 * 1024);
    }
  };

  const onChangeRemain = (e) => {
    var value = e.target.value;
    setRemainValue(value);
    if (typeData == "KB") {
      setRemain(value);
    } else if (typeData == "GB") {
      setRemain(value * 1024 * 1024);
    } else if (typeData == "TB") {
      setRemain(value * 1024 * 1024 * 1024);
    };
  };

  const selectAfter = (
    <Select
      defaultValue={"KB"}
      onChange={handleSelectTypeData}
      style={{ border: "none" }}
    >
      <Option value={"KB"}>
        <strong className="gx-text-eco-2">KB</strong>
      </Option>
      <Option value={"GB"}>
        <strong className="gx-text-eco-2">GB</strong>
      </Option>
      <Option value={"TB"}>
        <strong className="gx-text-eco-2">TB</strong>
      </Option>
    </Select>
  );

  useEffect(() => {
    var email = "";
    if (roleAccount == "user") {
      email = cookies.get("email");
    } else {
      if (selectedUser) {
        email = selectedUser.value;
      } else {
        // message.warning("Please choose one user !");
      }
    }
    setIsLoading(true);
    props.getUserTrafficReport(email, remain, page, pageSize, (code, res) =>
      handleResult(code, res)
    );
    const handleResult = (code, res) => {
      if (code == 200) {
        console.warn(res);
        setListData(res.usertraffic);
        if (!defaultUserList.length) loadDefaultUserList();
        setTotal(res.total);
      } else if (code == 1007) {
        if (!defaultUserList.length) loadDefaultUserList();
      } else {
        message.error("Error when getting month report data!");
      }
      setIsLoading(false);
    };
  }, [page, pageSize, selectedUser, remain]);

  return (
    <Spin spinning={isLoading} size="large">
      <Row
        // justify="space-between"
        style={{ marginLeft: "-15px", marginRight: "-15px" }}
      >
        <Col
          xs={24}
          sm={24}
          md={6}
          lg={6}
          xl={4}
          xxl={3}
          className="gx-order-lg-1 gx-order-md-1"
          style={{ marginRight: "0px" }}
        >
          <h4
            className="gx-font-weight-semi-bold gx-mb-3"
            style={{ color: "#274679" }}
          >
            USER TRAFFIC
          </h4>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={6}
          xxl={6}
          className="gx-order-lg-3  gx-order-md-3"
        >
          {roleAccount == "super" || roleAccount == "admin" ? (
            <AsyncSelect
              className="gx-mb-3"
              placeholder="All user..."
              isClearable={true}
              styles={colourStyles}
              defaultOptions={defaultUserList}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              //value={selectedUser}
              loadOptions={loadUserOptions}
              onChange={(value) => setSelectedUser(value)}
              maxMenuHeight={175}
            />
          ) : null}
        </Col>
        <Col
          xs={0}
          sm={0}
          md={2}
          lg={2}
          xl={8}
          xxl={9}
          className="gx-order-lg-2 gx-order-md-2"
        ></Col>
        <Col
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={6}
          xxl={6}
          className="gx-order-lg-4 gx-order-md-4"
        >
          <Input
            style={{
              borderRadius: "8px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
              height: "37px",
              width: "100%",
              // minWidth: 140,
              backgroundColor: "white",
            }}
            className="input-user-traffic"
            type="number"
            bordered={false}
            placeholder="Remain Under"
            addonAfter={selectAfter}
            onBlur={onChangeRemain}
          />
        </Col>
      </Row>
      <div
        className="gx-card"
        key="card"
      >
        <Table
          className="gx-table-responsive"
          key="table"
          columns={columns}
          dataSource={listData}
          pagination={false}
          rowKey="id"
          style={{
            border: "none",
            borderRadius: "10px",
            minHeight: "360px",
          }}
        />
        <br />
        <Pagination
          showTotal={(total) => `Total ${total} items`}
          total={total}
          defaultCurrent={page}
          defaultPageSize={pageSize}
          onChange={onChange}
          showQuickJumper
          showSizeChanger
          style={{ marginLeft: "20px" }}
        />
      </div>
    </Spin>
  );
};

export default connect(mapStateToProps, {
  getUserTrafficReport,
  searchEmail,
})(UserTraffic);
