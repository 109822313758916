import React from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import {
  StockOutlined,
  ScheduleOutlined,
  FundViewOutlined,
} from "@ant-design/icons";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const roleAccount = cookies.get("role");
const SubMenu = Menu.SubMenu;

const SidebarContent = () => {
  let { navStyle, themeType } = useSelector(({ settings }) => settings);
  let { pathname } = useSelector(({ common }) => common);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          {/* <AppsNavigation /> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.Item key="dashboard">
              <Link to="/dashboard">
                <i className="icon icon-dasbhoard" />
                <span>
                  <IntlMessages id="sidebar.dashboard" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="domain-config">
              <Link to="/domain-config">
                <i className="icon icon-social" />
                <span>
                  <IntlMessages id="sidebar.domainConfig" />
                </span>
              </Link>
            </Menu.Item>
            {roleAccount === "admin" || roleAccount === "super" ? (
              <Menu.Item key="user-manager">
                <Link to="/user-manager">
                  <i className="icon icon-avatar" />
                  <span>
                    <IntlMessages id="sidebar.userManager" />
                  </span>
                </Link>
              </Menu.Item>
            ) : null}
            <Menu.Item key="app-config">
              <Link to="/app-config">
                <i className="icon icon-widgets" />
                <span>
                  <IntlMessages id="sidebar.appConfig" />
                </span>
              </Link>
            </Menu.Item>
            {/* {roleAccount === "admin" || roleAccount === "super" ? (
              <Menu.Item key="app-config">
                <Link to="/app-config">
                  <i className="icon icon-widgets" />
                  <span>
                    <IntlMessages id="sidebar.appConfig" />
                  </span>
                </Link>
              </Menu.Item>
            ) : null} */}
            <Menu.Item key="p2p-setting">
              <Link to="/p2p-setting">
                <i className="icon i icon-crm" />
                <span>
                  <IntlMessages id="sidebar.p2pSetting" />
                </span>
              </Link>
            </Menu.Item>
            {roleAccount === "admin" || roleAccount === "super" ? (
              <Menu.Item key="package-manager">
                <Link to="/package-manager">
                  <i className="icon i icon-pricing-table" />
                  <span>
                    <IntlMessages id="sidebar.packageManager" />
                  </span>
                </Link>
              </Menu.Item>
            ) : null}
            {roleAccount === "admin" || roleAccount === "super" ? (
              <Menu.Item key="transaction-manager">
                <Link to="/transaction-manager">
                  <i className="icon i icon-refer" />
                  <span>
                    <IntlMessages id="sidebar.transactionManager" />
                  </span>
                </Link>
              </Menu.Item>
            ) : null}
            {roleAccount === "admin" || roleAccount === "super" ? (
              <Menu.Item key="user-package">
                <Link to="/user-package">
                  <i className=" icon i icon-revenue-new"></i>
                  <span>
                    <IntlMessages id="sidebar.userPackage" />
                  </span>
                </Link>
              </Menu.Item>
            ) : null}
            {roleAccount === "user" ? (
              <SubMenu
                key="pricing"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon i icon-orders" />
                    <span>
                      <IntlMessages id="sidebar.pricing" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="pricing/resource">
                  <Link to="/pricing/resource">
                    <i className="icon i icon-shopping-cart" />
                    <span>
                      <IntlMessages id="sidebar.resource" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="pricing/transactions">
                  <Link to="/pricing/transactions">
                    <i className="icon i icon-important" />
                    <span>
                      <IntlMessages id="sidebar.transactions" />
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>
            ) : null}

            <SubMenu
              key="report"
              popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  <i className="icon i icon-wysiwyg" />
                  <span>
                    <IntlMessages id="sidebar.report" />
                  </span>
                </span>
              }
            >
              {/* <Menu.Item key="report/statistic">
                <Link to="/report/statistic">
                  <AuditOutlined />
                  <span>
                    <IntlMessages id="sidebar.statistic" />
                  </span>
                </Link>
              </Menu.Item> */}
              <Menu.Item key="report/visualization">
                <Link to="/report/visualization">
                  <StockOutlined />
                  <span>
                    <IntlMessages id="sidebar.visualization" />
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="report/month">
                <Link to="/report/month">
                  <ScheduleOutlined />
                  <span>
                    <IntlMessages id="sidebar.monthReport" />
                  </span>
                </Link>
              </Menu.Item>
              {roleAccount === "admin" || roleAccount === "super" ? (
                <Menu.Item key="report/user-traffic">
                  <Link to="/report/user-traffic">
                    <FundViewOutlined />
                    <span>
                      <IntlMessages id="sidebar.userTraffic" />
                    </span>
                  </Link>
                </Menu.Item>
              ) : null}
            </SubMenu>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;
