import React, { useState, useEffect } from "react";
import { Form, Input, Col, Row, Select } from "antd";
import { DollarCircleOutlined, GlobalOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";

const mapStateToProps = () => ({});

function Detail(props) {
  const Option = Select.Option;
  const [transactionInfo, setTransactionInfo] = useState([]);

  useEffect(() => {
    if (props.selectedItem) {
      setTransactionInfo([
        {
          name: "pack_name",
          value: props.selectedItem.pack_name,
        },
        {
          name: "trans_id",
          value: props.selectedItem.trans_id,
        },
        {
          name: "real_price",
          value: props.selectedItem.real_price,
        },
        {
          name: "origin_price",
          value: props.selectedItem.origin_price,
        },
        {
          name: "currency",
          value: props.selectedItem.currency,
        },
        {
          name: "ordercode",
          value: props.selectedItem.ordercode,
        },
        {
          name: "paid",
          value: props.selectedItem.paid,
        },

        {
          name: "pay_date",
          value: moment(props.selectedItem.pay_date)
            .utc()
            .format("DD-MM-YYYY HH:mm:ss"),
        },

        {
          name: "status",
          value: props.selectedItem.status,
        },
        {
          name: "created",
          value: moment(props.selectedItem.created)
            .utc()
            .format("DD-MM-YYYY HH:mm:ss"),
        },
        {
          name: "description",
          value: props.selectedItem.description,
        },
      ]);
    }
  }, [props.selectedItem]);

  return (
    <Form
      name="basic"
      className="gx-signin-form gx-form-row0"
      fields={transactionInfo}
    >
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Package Name</div>
          <Form.Item
            name="pack_name"
            rules={[{ required: true, message: "Please input  package name!" }]}
          >
            <Input placeholder="The display name of package" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold" style={{ marginBottom: 6 }}>
            Transaction ID
          </div>
          <Form.Item
            name="trans_id"
            rules={[
              { required: true, message: "Please input transaction id!" },
            ]}
          >
            <Input placeholder="Transaction ID" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Real Price</div>
          <Form.Item
            name="real_price"
            rules={[{ required: true, message: "Please input real price!" }]}
          >
            <Input
              prefix={
                <DollarCircleOutlined style={{ color: "rgba(0,0,0,.25)" }} />
              }
              placeholder="Real Price"
              type="number"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Origin Price</div>
          <Form.Item
            name="origin_price"
            rules={[{ required: true, message: "Please input origin price!" }]}
          >
            <Input
              prefix={
                <DollarCircleOutlined style={{ color: "rgba(0,0,0,.25)" }} />
              }
              placeholder="Origin Price"
              type="number"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Currency</div>
          <Form.Item
            initialValue="USD"
            name="currency"
            rules={[{ required: true, message: "Please input your currency!" }]}
          >
            <Select>
              <Option value="USD">USD</Option>
              <Option value="VND">VND</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Status</div>
          <Form.Item
            initialValue="success"
            name="public"
            rules={[{ required: true, message: "Please choose status!" }]}
          >
            <Select>
              <Option value="success">Success</Option>
              <Option value="pending">Pending</Option>
              <Option value="cancle">Cancle</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold" style={{ marginBottom: 6 }}>
            Paid
          </div>
          <Form.Item name="paid">
            <Input placeholder="Paid Amount" type="number" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold" style={{ marginBottom: 6 }}>
            Pay Date
          </div>
          <Form.Item name="pay_date">
            <Input placeholder="Pay date" type="text" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <div className="gx-font-weight-semi-bold" style={{ marginBottom: 6 }}>
            Order Code
          </div>
          <Form.Item name="ordercode">
            <Input placeholder="Order Code" type="text" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <div className="gx-font-weight-semi-bold" style={{ marginBottom: 6 }}>
            Created Date
          </div>
          <Form.Item name="created">
            <Input placeholder="Created date" type="text" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Description</div>
          <Form.Item
            name="description"
            rules={[{ required: true, message: "Please input description !" }]}
          >
            <Input.TextArea
              prefix={<GlobalOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Description ..."
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default connect(mapStateToProps, {})(Detail);
