import React, { useState, useEffect } from "react";
import { Form, Input, message, Select } from "antd";
import { HomeOutlined, LinkOutlined } from "@ant-design/icons";
import { createDomain } from "../../../appRedux/actions/DomainConfig";
import { connect } from "react-redux";
import Cookies from "universal-cookie";

const { Option } = Select;
const mapStateToProps = () => ({});

function CreateDomain(props) {
  var renderEmail;
  const cookies = new Cookies();
  const roleAccount = cookies.get("role");
  const [domainInfo, setDomainInfo] = useState([
    {
      name: "type",
      value: "advanced",
    },
  ]);

  function normalizeDomain(domain) {
    if (!Boolean(domain)) {
      return null;
    }

    if (!/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/.test(domain)) {
      return null;
    }

    if (domain.startsWith("http")) {
      return domain
    }

    return `https://${domain}`;
  }

  const onChange = (changedFields, newFields) => {
    const changedFieldName = changedFields[0].name[0];
    const newDomain = newFields.find((field) => field.name[0] == "domain");
    const newHomepage = newFields.find((field) => field.name[0] == "play_url");
    // if (Boolean(newDomain.value) & !Boolean(newHomepage.value)) {
    if (Boolean(newDomain.value) & changedFieldName == "domain") {
      newHomepage.value = normalizeDomain(newDomain.value);
    } 
    setDomainInfo(newFields);
  };

  const handleAdd = (res) => {
    if (res === 200) {
      message.success("Add domain information successfully!");
      props.onIsFetch(true);
    } else message.error("Error when adding domain information !");
    props.onClose();
  };

  useEffect(() => {
    if (props.submitAdd === true) {
      props.createDomain(roleAccount, domainInfo, (res) => handleAdd(res));
    }
  }, [props.submitAdd]);

  if (roleAccount === "user") {
    renderEmail = "";
  } else {
    renderEmail = (
      <span>
        <p>Email</p>
        <Form.Item
          name="email"
          rules={[{ required: true, message: "Please input email of user !" }]}
        >
          <Input prefix={<LinkOutlined />} placeholder="Email" />
        </Form.Item>
      </span>
    );
  }
  return (
    <Form
      name="basic"
      className="gx-signin-form gx-form-row0"
      fields={domainInfo}
      onFieldsChange={(changedFields, allFields) => {
        onChange(changedFields, allFields);
      }}
    >
      <p>Domain</p>
      <Form.Item
        name="domain"
        rules={[{ required: true, message: "Please input your domain!" }]}
      >
        <Input prefix={<LinkOutlined />} placeholder="Domain" />
      </Form.Item>
      <p>HomePage</p>
      <Form.Item
        name="play_url"
        rules={[
          {
            required: true,
            message: "Please input your Link Homepage",
          },
        ]}
      >
        <Input.TextArea
          prefix={<HomeOutlined />}
          placeholder="Enter the link of your website homepage or the guide page link to the play page https://example.com/index.html"
        />
      </Form.Item>
      {renderEmail}
      <p>Type</p>
      <Form.Item name="type">
        <Select className="gx-mr-3 gx-mb-3">
          <Option value="default">Default</Option>
          <Option value="advanced">Advanced</Option>
        </Select>
      </Form.Item>
    </Form>
  );
}

export default connect(mapStateToProps, { createDomain })(CreateDomain);
