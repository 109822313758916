import React from "react";
import { Route, Switch } from "react-router-dom";
import Resource from "./Resource";
import Transactions from "./Transactions";

const Pricing = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/resource`} component={Resource} />
    <Route path={`${match.url}/transactions`} component={Transactions} />
  </Switch>
);

export default Pricing;
