import React, { useState, useEffect } from "react";
import { Col, message, Row } from "antd";
import AsyncSelect from "react-select/async";

import Auxiliary from "util/Auxiliary";
import Traffic from "./components/Traffic";

import axios from "util/Api";
import {
  getDomainList,
  searchEmail,
} from "../../../appRedux/actions/Dashboard";
import { connect } from "react-redux";
import { isEmpty } from "util/formatData";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const roleAccount = cookies.get("role");
const userEmail = cookies.get("email");

const mapStateToProps = () => ({});
const Visualization = (props) => {
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedDomain, setSelectedDomain] = useState({});

  const [defaultUserList, setDefaultUserList] = useState([]);
  const [defaultDomainList, setDefaultDomainList] = useState([]);

  //#region  Xu ly select & search user

  // handle selection
  const handleChange = (value) => {
    setSelectedUser(value);
  };

  const handleDomainChange = (value) => {
    setSelectedDomain(value);
  };

  const loadUserOptions = (inputValue) => {
    return axios
      .get(`admin/email/search?page=1&page_size=5&keyword=${inputValue}`)
      .then((res) => res.data.data);
  };

  const loadDomainOptions = (inputValue) => {
    var url = ``;
    var email;
    if (roleAccount === "super" || roleAccount === "admin") {
      email = isEmpty(selectedUser) ? "" : selectedUser.value;
    } else email = userEmail;

    if (isEmpty(selectedUser)) {
      url = `domain/search?page=1&page_size=10&keyword=${inputValue}`;
    } else
      url = `domain/search?email=${email}&page=1&page_size=10&keyword=${inputValue}`;
    return axios.get(url).then((res) => res.data.data.data);
  };

  useEffect(() => {
    if (roleAccount === "admin" || roleAccount === "super") {
      props.searchEmail(
        { page: 1, page_size: 20, keyword: "" },
        (code, res) => {
          if (code === 200) {
            setDefaultUserList(res);
          } else message.error("Error when fetching user list !");
        }
      );
    }
  }, []);

  useEffect(() => {
    var email;
    if (roleAccount === "super" || roleAccount === "admin") {
      email = isEmpty(selectedUser) ? "" : selectedUser.value;
    } else email = userEmail;
    props.getDomainList(
      {
        page: 1,
        page_size: 20,
        keyword: "",
        email: email,
      },
      (code, res) => {
        if (code === 200) {
          setDefaultDomainList(res.data);
        } else message.error("Error when fetching domain list !");
      }
    );
  }, [selectedUser]);

  //#endregion

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
    menu: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
  };

  return (
    <Auxiliary>
      <Row>
        {roleAccount === "admin" || roleAccount === "super" ? (
          <Col xxl={6} xl={5} lg={12} md={12} sm={12} xs={12}>
            <AsyncSelect
              className="gx-mb-4"
              placeholder="All user..."
              styles={colourStyles}
              isClearable={true}
              defaultOptions={defaultUserList}
              //value={selectedUser}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              loadOptions={loadUserOptions}
              onChange={handleChange}
              maxMenuHeight={175}
            />
          </Col>
        ) : null}
        <Col xxl={6} xl={5} lg={12} md={12} sm={12} xs={12}>
          <AsyncSelect
            placeholder="All domain..."
            className="gx-mb-4"
            styles={colourStyles}
            isClearable={true}
            defaultOptions={defaultDomainList}
            // value={selectedUser}
            getOptionLabel={(e) => e.domain}
            getOptionValue={(e) => e.id}
            loadOptions={loadDomainOptions}
            onChange={handleDomainChange}
            maxMenuHeight={175}
          />
        </Col>
        <Col xxl={12} xl={14} lg={12} md={12} sm={24} xs={24}>
          <span></span>
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {/* <CCU CCUData={CCUData} /> */}
          <Traffic
            selectedDomain={selectedDomain}
            selectedUser={selectedUser}
          />
        </Col>
      </Row>
    </Auxiliary>
  );
};

export default connect(mapStateToProps, {
  getDomainList,
  searchEmail,
})(Visualization);
