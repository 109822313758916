import { FETCH_ERROR, FETCH_START } from "../../constants/ActionTypes";
import qs from "qs";
import axios from "util/Api";

/**
 *  Hàm lay thông tin DataPack
 * @param {} id
 */
export const getUserDataPackDetail = (
  { id },
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .get(`userpacks/information?id=${id}`)
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(200, res.data.data);
          }
        } else callback(404);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

/**
 *  Hàm lay thông tin list Datapack
 * @param {} page
 * @param {} page_size
 * @param {*} callback
 */
export const getListUserDataPack = (
  { page, page_size, email },
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .get(`userpacks?email=${email}&page=${page}&page_size=${page_size}`)
      .then(({ data }) => {
        if (data.code === 200) {
          if (typeof callback === "function") {
            callback(data.code, data.data);
          }
        } else callback(data.code);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

/**
 *  Hàm them datapack
 * @param {} userDataPackInfo
 */
export const createUserDataPack = (
  userDataPackInfo,
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post(
        `userpacks`,
        qs.stringify({
          pack_name:
            userDataPackInfo[0] && userDataPackInfo[0].value
              ? userDataPackInfo[0].value
              : "",
          email:
            userDataPackInfo[1] && userDataPackInfo[1].value
              ? userDataPackInfo[1].value
              : "",
          active:
            userDataPackInfo[2] && userDataPackInfo[2].value
              ? userDataPackInfo[2].value
              : null,
        })
      )
      .then(({ data }) => {
        if (data.code === 200) {
          if (typeof callback === "function") {
            callback(data.code);
          }
        } else callback(data.code, null);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404, null);
        }
      });
  };
};

/**
 *  Hàm delete datapack
 * @param {} dataPackID
 */

export const deleteDatapack = (
  userDataPackID,
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .delete(`userpacks/${userDataPackID}`)
      .then(({ data }) => {
        if (data.code === 200) {
          if (typeof callback === "function") {
            callback(200, data.data);
          }
        } else callback(404);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

/**
 *  Hàm update thông tin data Pack (admin  mới có quyền)
 * @param {userDataPackInfo}
 */
export const updateDataPackRemain = (
  userDataPackInfo,
  userDataPackID,
  callback = (response) => {}
) => {
  return (dispatch) => {
    axios
      .put(
        "userpacks/remain",
        qs.stringify({
          id: userDataPackID,
          remain: userDataPackInfo[0].value,
        })
      )
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(res.data.code);
          }
        } else {
          callback(res.data.code);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        callback(404);
      });
  };
};

/**
 *  Hàm update thông tin data Pack (admin  mới có quyền)
 * @param {status}
 */
export const updateDataPackStatus = (
  { id, active },
  callback = (response) => {}
) => {
  return (dispatch) => {
    axios
      .put(
        "userpacks/status",
        qs.stringify({
          id: id,
          active: active,
        })
      )
      .then((res) => {
        if (res.data.code === 200) {
          if (typeof callback === "function") {
            callback(res.data.code);
          }
        } else {
          callback(res.data.code);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        callback(404);
      });
  };
};
