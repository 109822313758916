import React, { useState, useEffect } from "react";
import { Form, Select, Input, message, Row, Col, Button } from "antd";
import { createApp } from "../../../appRedux/actions/AppConfig";
import { connect } from "react-redux";
import {
  AppstoreAddOutlined,
  GlobalOutlined,
  LinkOutlined,
  MailOutlined,
} from "@ant-design/icons";
import Cookies from "universal-cookie";

const Option = Select.Option;
const cookies = new Cookies();
const roleAccount = cookies.get("role");

const mapStateToProps = () => ({});
const CreateApp = (props) => {
  const [appInfo, setAppInfo] = useState([
    {
      name: ["app_name"],
      value: null,
    },
    {
      name: ["domain_name"],
      value: null,
    },
    {
      name: ["play_url"],
      value: null,
    },
    {
      name: ["email"],
      value: null,
    },
    {
      name: ["platform"],
      value: null,
    },
  ]);

  const onChange = (newFields) => {
    setAppInfo(newFields);
  };

  const handleCreateApp = (res) => {
    if (res === 200) {
      message.success("Create App successfully !");
      props.onIsFetch();
      props.onClose();
    } else {
      //   props.onIsFetch(true);
      props.onClose();
      message.error("Error when creating app !");
    }
  };

  const onFinish = (values) => {
    props.createApp(values, (res) => handleCreateApp(res));
  };

  return (
    <Form
      name="basic"
      id="createApp"
      className="gx-signin-form gx-form-row0"
      fields={appInfo}
      onFieldsChange={(changedFields, allFields) => {
        onChange(allFields);
      }}
      onFinish={onFinish}
      style={{ marginLeft: -15, marginRight: -15 }}
    >
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <p>App Name</p>
          <Form.Item
            name="app_name"
            rules={[{ required: true, message: "Please input your app name!" }]}
          >
            <Input
              prefix={
                <AppstoreAddOutlined style={{ color: "rgba(0,0,0,.25)" }} />
              }
              placeholder="The display name of app"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <p>Domain Name</p>
          <Form.Item
            name="domain_name"
            rules={[
              { required: true, message: "Please input your domain name!" },
            ]}
          >
            <Input
              prefix={<GlobalOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="com.company.example"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <p>Introduction URL</p>
          <Form.Item
            name="play_url"
            rules={[
              { required: true, message: "Please input the url of your app!" },
            ]}
          >
            <Input
              prefix={<LinkOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="https://introduction_to_app/index.html"
            />
          </Form.Item>
        </Col>
        {roleAccount === "admin" || roleAccount === "super" ? (
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <p>Email</p>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
              ]}
            >
              <Input
                prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="example@gmail.com"
                type="email"
              />
            </Form.Item>
          </Col>
        ) : null}
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <p>Platform</p>
          <Form.Item
            name="platform"
            rules={[{ required: true, message: "Please choose the platform!" }]}
          >
            <Select className="gx-mr-3 gx-mb-3" placeholder="Select platform">
              <Option key="1" value="android">
                Android
              </Option>
              <Option key="2" value="ios">
                iOS
              </Option>
              <Option key="3" value="pc">
                PC (Electron)
              </Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {/* <Row justify="end">
        <Col>
          <Form.Item>
            <Button
              type="default"
              className="gx-mb-0"
              onClick={() => {
                props.onClose(false);
              }}
            >
              Cancle
            </Button>
            <Button
              type="primary"
              className="gx-mb-0"
              htmlType="submit"
              style={{ marginRight: 15 }}
            >
              OK
            </Button>
          </Form.Item>
        </Col>
      </Row> */}
    </Form>
  );
};

export default connect(mapStateToProps, { createApp })(CreateApp);
