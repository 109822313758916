import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, message, DatePicker } from "antd";
import {
  LockOutlined,
  MailOutlined,
  UserOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { getUserInfoByID } from "../../../appRedux/actions/User";
import { connect } from "react-redux";
import moment from "moment";

const mapStateToProps = () => ({});

const Detail = (props) => {
  const [userInfo, setUserInfo] = useState([]);

  useEffect(() => {
    props.getUserInfoByID(props.userID, (code, res) => handleResult(code, res));
    const handleResult = (code, res) => {
      if (code === 200) {
        setUserInfo([
          {
            name: ["username"],
            value: res.user_name,
          },
          {
            name: ["email"],
            value: res.email,
          },
          {
            name: ["firstname"],
            value: res.firstname,
          },
          {
            name: ["lastname"],
            value: res.lastname,
          },
          {
            name: ["phone"],
            value: res.phone,
          },
          {
            name: ["sex"],
            value: res.sex,
          },
          {
            name: ["dateofbirth"],
            value: res.dateofbirth
              ? moment(
                  moment(res.dateofbirth).format("MM-DD-YYYY"),
                  "MM/DD/YYYY"
                )
              : null,
          },
        ]);
      } else message.error("Error when getting user information!");
    };
  }, []);

  return (
    <Form
      name="basic"
      className="gx-signin-form gx-form-row0"
      fields={userInfo}
    >
      <Row gutter={[16, 10]}>
        <Col span={12}>
          <p>Username</p>
          <Form.Item name="username">
            <Input
              suffix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Username"
              type="text"
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <p>Email</p>
          <Form.Item name="email">
            <Input
              suffix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Email"
              disabled
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 10]}>
        <Col span={12}>
          <p>First Name</p>
          <Form.Item name="firstname">
            <Input
              suffix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="First Name"
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <p>Last Name</p>
          <Form.Item name="lastname">
            <Input
              suffix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Last Name"
              disabled
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 10]}>
        <Col span={12}>
          <p>Password</p>
          <Form.Item>
            <Input
              suffix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Password"
              value="********"
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <p>Phone</p>
          <Form.Item name="phone">
            <Input
              suffix={<PhoneOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Phone"
              type="text"
              disabled
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 10]}>
        <Col span={12}>
          <p>Sex</p>
          <Form.Item name="sex">
            <Input
              suffix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Sex"
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <p>Date of Birth</p>
          <Form.Item name="dateofbirth">
            <DatePicker
              //prefix={<CalendarOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Date of Birth"
              format="MM/DD/YYYY"
              className="gx-mb-3 gx-w-100"
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default connect(mapStateToProps, { getUserInfoByID })(Detail);
