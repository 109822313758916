import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Button,
  Typography,
  message,
  DatePicker,
  Modal,
  Select,
} from "antd";
import {
  LockOutlined,
  UserOutlined,
  EditOutlined,
  PhoneOutlined,
  MailOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import Contact from "../UserInfo/components/contact";
import Auxiliary from "../../util/Auxiliary";
import ProfileHeader from "../../components/profile/ProfileHeader/index";
import {
  getUserInfo,
  updateUserInfo,
  changePassword,
} from "../../appRedux/actions/Auth";
import { connect } from "react-redux";
import moment from "moment";

const Option = Select.Option;

const buttonStyle = {
  backgroundColor: "#274679",
  height: "37px",
  color: "white",
  border: "none",
  borderRadius: "8px",
  boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.09)",
};

const buttonStyle2 = {
  height: "37px",
  color: "#274679",
  border: "none",
  borderRadius: "8px",
  boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.06)",
};

const mapStateToProps = () => ({});

const UserInfo = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isChangePass, setIsChangePass] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [password, setPassword] = useState([
    {
      name: ["old-password"],
      value: null,
    },
    {
      name: ["new-password"],
      value: null,
    },
  ]);
  const [isFetch, setIsFetch] = useState(false); // Biến truyền sang component contact : Có fetch dữ liệu mới về ko

  const handleUpdate = () => {
    setIsEdit(!isEdit);
    props.updateUserInfo(userInfo, (res) => handleUpdateResult(res));
  };

  const handleUpdateResult = (res) => {
    if (res === 200) {
      setIsFetch(true);
      message.success("Update your account information Successfully !");
    } else if (res === 500) {
      message.error("Server busy ! Please try again !");
    } else message.error("Update information failed!");
  };

  const handleCancleUpdate = () => {
    setIsFetch(true);
    setIsEdit(!isEdit);
  };

  const onChange = (newFields) => {
    setUserInfo(newFields);
  };

  const onPasswordChange = (newFields) => {
    setPassword(newFields);
  };

  const handleChangePass = () => {
    props.changePassword(password, (res) => {
      if (res === 200) {
        message.success("Change password successfully !");
      } else if (res === 1003) {
        message.error("Your old password is not correct ! Please try again !");
      } else if (res === 1000) {
        message.error("Permission Denied !");
      } else if (res === 500) {
        message.error("Server busy ! Please try again !");
      } else message.error("Change password failed ! Please try again !");
      setIsChangePass(!isChangePass);
    });
  };

  useEffect(() => {
    if (isFetch === true) {
      props.getUserInfo((code, res) => handleResult(code, res));
    }

    const handleResult = (code, res) => {
      if (code === 200) {
        setUserInfo([
          {
            name: ["username"],
            value: res.user_name,
          },
          {
            name: ["email"],
            value: res.email,
          },
          {
            name: ["firstname"],
            value: res.firstname,
          },
          {
            name: ["lastname"],
            value: res.lastname,
          },
          {
            name: ["phone"],
            value: res.phone,
          },
          {
            name: ["sex"],
            value: res.sex,
          },
          {
            name: ["dateofbirth"],
            value: res.dateofbirth
              ? moment(
                  moment(res.dateofbirth).format("DD-MM-YYYY"),
                  "DD/MM/YYYY"
                )
              : null,
          },
        ]);
        setIsFetch(false);
      } else message.error("Error when getting user information!");
    };
  }, [isFetch]);

  useEffect(() => {
    props.getUserInfo((code, res) => handleResult(code, res));
    const handleResult = (code, res) => {
      if (code === 200) {
        setUserInfo([
          {
            name: ["username"],
            value: res.user_name,
          },
          {
            name: ["email"],
            value: res.email,
          },
          {
            name: ["firstname"],
            value: res.firstname,
          },
          {
            name: ["lastname"],
            value: res.lastname,
          },
          {
            name: ["phone"],
            value: res.phone,
          },
          {
            name: ["sex"],
            value: res.sex,
          },
          {
            name: ["dateofbirth"],
            value: res.dateofbirth
              ? moment(
                  moment(res.dateofbirth).format("DD-MM-YYYY"),
                  "DD/MM/YYYY"
                )
              : null,
          },
        ]);
      } else message.error("Error when getting user information!");
    };
  }, []);

  return (
    <Auxiliary>
      <ProfileHeader />
      <div className="gx-profile-content">
        <Row>
          <Col xxl={16} xl={15} lg={14} md={14} sm={24} xs={24}>
            <Card className="gx-card">
              <Row
                className="gx-signin-form gx-form-row0"
                justify="space-between"
                style={{ marginLeft: "5px", marginRight: "8px" }}
              >
                <Col>
                  <h4
                    className="gx-font-weight-semi-bold gx-mb-3"
                    style={{ color: "#274679" }}
                  >
                    USER PROFILE{" "}
                  </h4>
                </Col>
                <Col>
                  {isEdit ? (
                    <Button
                      style={buttonStyle}
                      type="default"
                      icon={<CheckCircleOutlined />}
                      onClick={() => handleUpdate()}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      style={buttonStyle}
                      type="default"
                      icon={<EditOutlined />}
                      onClick={() => setIsEdit(!isEdit)}
                    >
                      Edit
                    </Button>
                  )}
                  {isEdit ? (
                    <Button
                      style={buttonStyle2}
                      type="default"
                      icon={<CloseCircleOutlined />}
                      onClick={() => handleCancleUpdate()}
                    >
                      Cancle
                    </Button>
                  ) : (
                    <Button
                      style={buttonStyle2}
                      type="default"
                      icon={<LockOutlined />}
                      onClick={() => setIsChangePass(!isChangePass)}
                    >
                      Change Password
                    </Button>
                  )}
                  {/* <Button type={isEdit ? 'default' : 'primary'} icon={isEdit ? '' : <EditOutlined />} onClick={() => setIsEdit(!isEdit)}>{isEdit ? 'Update' : 'Edit'}</Button> */}
                </Col>
              </Row>

              <Form
                name="basic"
                className="gx-signin-form gx-form-row0"
                fields={userInfo}
                onFieldsChange={(changedFields, allFields) => {
                  onChange(allFields);
                }}
              >
                <Row gutter={[16, 10]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <p>Username</p>
                    <Form.Item name="username">
                      <Input
                        suffix={
                          <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        placeholder="Username"
                        type="text"
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <p>Email</p>
                    <Form.Item name="email">
                      <Input
                        suffix={
                          <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        placeholder="Email"
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 10]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <p>First Name</p>
                    <Form.Item name="firstname">
                      <Input
                        suffix={
                          <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        placeholder="First Name"
                        disabled={!isEdit}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <p>Last Name</p>
                    <Form.Item name="lastname">
                      <Input
                        suffix={
                          <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        placeholder="Last Name"
                        disabled={!isEdit}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 10]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <p>Password</p>
                    <Form.Item>
                      <Input
                        suffix={
                          <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        type="password"
                        placeholder="Password"
                        value="********"
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <p>Phone</p>
                    <Form.Item name="phone">
                      <Input
                        suffix={
                          <PhoneOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        placeholder="Phone"
                        type="text"
                        disabled={!isEdit}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 10]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <p>Sex</p>
                    <Form.Item name="sex">
                      <Select className="gx-mr-3 gx-mb-3" disabled={!isEdit}>
                        <Option value="male">Male</Option>
                        <Option value="female">Female</Option>
                        <Option value="other">Other</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <p>Date of Birth</p>
                    <Form.Item name="dateofbirth">
                      <DatePicker
                        //prefix={<CalendarOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                        placeholder="Date of Birth"
                        format="MM/DD/YYYY"
                        className="gx-mb-3 gx-w-100"
                        disabled={!isEdit}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>

          <Col xxl={8} xl={9} lg={10} md={10} sm={24} xs={24}>
            <Contact isFetch={isFetch} />
          </Col>
        </Row>
      </div>
      {isChangePass && (
        <Modal
          title="Change Password"
          width={480}
          wrapClassName="vertical-center-modal"
          visible={isChangePass}
          onOk={() => handleChangePass()}
          onCancel={() => setIsChangePass(false)}
        >
          <Form
            name="basic"
            className="gx-signin-form gx-form-row0"
            fields={password}
            onFieldsChange={(changedFields, allFields) => {
              onPasswordChange(allFields);
            }}
          >
            <p>Old Password</p>
            <Form.Item
              name="old-password"
              rules={[
                { required: true, message: "Please input your old password!" },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Enter your old password"
                type="password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <p>New Password</p>
            <Form.Item
              name="new-password"
              rules={[
                { required: true, message: "Please input your new password!" },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Enter your new password"
                type="password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <p>Confirm Password</p>
            <Form.Item
              name="confirm-new-password"
              dependencies={["new-password"]}
              hasFeedback
              rules={[
                { required: true, message: "Please confirm your Password!" },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("new-password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The two passwords that you entered do not match!"
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Confirm your new pasword"
                type="password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </Auxiliary>
  );
};

export default connect(mapStateToProps, {
  getUserInfo,
  updateUserInfo,
  changePassword,
})(UserInfo);
