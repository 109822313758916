import { FETCH_ERROR, FETCH_START } from "../../constants/ActionTypes";
import qs from "qs";
import axios from "util/Api";

/**
 *  Hàm lay thông tin list Domain
 * @param {} page
 * @param {} page_size
 * @param {*} callback
 */
export const getDomainList = (
  { page, page_size, keyword, email, type="" },
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .get(
        `domain/search?email=${email}&keyword=${keyword}&page=${page}&page_size=${page_size}&type=${type}`
      )
      .then(({ data }) => {
        if (data.code === 200) {
          if (typeof callback === "function") {
            callback(200, data.data);
          }
        } else callback(404);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

/**
 *  Hàm them domain
 * @param {} domainInfo
 */
export const createDomain = (roleAccount, domainInfo, callback = (code, response) => {}) => {
  console.log(domainInfo)
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    var payload = roleAccount == "user" ? {
      domain:
        domainInfo[0] != null && domainInfo != null
          ? domainInfo[0].value
          : null,
      play_url:
        domainInfo[1] != null && domainInfo != null
          ? domainInfo[1].value
          : null,
      type:
        domainInfo[2] != null && domainInfo != null
          ? domainInfo[2].value
          : "",
    } : {
      domain:
        domainInfo[0] != null && domainInfo != null
          ? domainInfo[0].value
          : null,
      play_url:
        domainInfo[1] != null && domainInfo != null
          ? domainInfo[1].value
          : null,
      email:
        domainInfo[2] != null && domainInfo != null
          ? domainInfo[2].value
          : "",
      type:
        domainInfo[3] != null && domainInfo != null
          ? domainInfo[3].value
          : "",
    };
    axios
      .post(
        `domain`,
        qs.stringify(payload)
      )
      .then(({ data }) => {
        if (data.code === 200) {
          if (typeof callback === "function") {
            callback(200, data);
          }
        } else callback(404);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

/**
 *  Hàm verify domain
 * @param {} domain_id
 */
export const verifyDomain = (domain_id, callback = (code, response) => {}) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post(`domain/verification/${domain_id}`)
      .then(({ data }) => {
        if (data.code === 200) {
          if (typeof callback === "function") {
            callback(200, data.data);
          }
        } else callback(404);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

/**
 *  Hàm delete domain
 * @param {} domain_id
 */

export const deleteDomain = (domain_id, callback = (code, response) => {}) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .delete(`domain/${domain_id}`)
      .then(({ data }) => {
        if (data.code === 200) {
          if (typeof callback === "function") {
            callback(200, data.data);
          }
        } else callback(404);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

/**
 *  Hàm set status domain
 * @param {} domain_id
 */
export const setStatusDomain = (
  { domain_id, disable },
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post(
        `domain/status`,
        qs.stringify({
          domain_id: domain_id,
          disable: disable,
        })
      )
      .then(({ data }) => {
        if (data.code === 200) {
          if (typeof callback === "function") {
            callback(200, data.data);
          }
        } else callback(404);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};

/**
 *  Hàm set p2p setting
 * @param {} domain_id
 */
export const setP2PConfig = (
  { domain_id, ratio, wifi_only },
  callback = (code, response) => {}
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post(
        `domain/setting/p2p`,
        qs.stringify({
          domain_id: domain_id,
          ratio: ratio,
          wifi_only: wifi_only,
        })
      )
      .then(({ data }) => {
        if (data.code === 200) {
          if (typeof callback === "function") {
            callback(200, data.data);
          }
        } else callback(404);
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
        if (typeof callback === "function") {
          callback(404);
        }
      });
  };
};
