import React from "react";
import { Route, Switch } from "react-router-dom";
import UserManager from "./UserManager";
import Dashboard from "./Dashboard";
import DomainConfig from "./DomainConfig";
import UserInfo from "./UserInfo";
import AppConfig from "./AppConfig";
import Report from "./Report";
import P2PSetting from "./P2PSetting";
import PacketManager from "./PackageManager";
import Pricing from "./Pricing";
import TransactionManager from "./TransactionManager";
import UserPackage from "./UserPackage";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const roleAccount = cookies.get("role");

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    {roleAccount === "admin" || roleAccount === "super" ? (
      <Switch>
        <Route path={`${match.url}user-manager`} component={UserManager} />
        {/* <Route path={`${match.url}app-config`} component={AppConfig} /> */}
        <Route path={`${match.url}package-manager`} component={PacketManager} />
        <Route
          path={`${match.url}transaction-manager`}
          component={TransactionManager}
        />
        <Route path={`${match.url}user-package`} component={UserPackage} />
      </Switch>
    ) : null}

    {roleAccount === "user" ? (
      <Switch>
        <Route path={`${match.url}pricing`} component={Pricing} />)
      </Switch>
    ) : null}
    <Switch>
      <Route path={`${match.url}dashboard`} component={Dashboard} />
      <Route path={`${match.url}domain-config`} component={DomainConfig} />
      <Route path={`${match.url}app-config`} component={AppConfig} />
      <Route path={`${match.url}user-profile`} component={UserInfo} />
      <Route path={`${match.url}report`} component={Report} />
      <Route path={`${match.url}p2p-setting`} component={P2PSetting} />
    </Switch>
  </div>
);

export default App;
