// const SITE_KEY = (process.env.NODE_ENV == 'production') ? process.env.REACT_APP_CATPCHA_SITE_KEY_PRODUCT : process.env.REACT_APP_CATPCHA_SITE_KEY_LOCAL;
// const SECRET_KEY = (process.env.NODE_ENV == 'production') ? process.env.REACT_APP_CATPCHA_SECRET_KEY_PRODUCT : process.env.REACT_APP_CATPCHA_SECRET_KEY_LOCAL;

const SITE_KEY = process.env.REACT_APP_CATPCHA_SITE_KEY;
const SECRET_KEY = process.env.REACT_APP_CATPCHA_SECRET_KEY;

export const reCaptcha = {
  SITE_KEY: SITE_KEY,
  SECRET_KEY: SECRET_KEY,
};
