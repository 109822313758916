import React, { useState, useEffect } from "react";
import { Form, Input, message, Col, Row, Select, DatePicker } from "antd";
import { acceptTransaction } from "../../../appRedux/actions/Transaction";
import { connect } from "react-redux";

const mapStateToProps = () => ({});

function EditTransaction(props) {
  const [transactionInfo, setTransactionInfo] = useState([
    { name: "paid", value: 0 },
    { name: "description", value: "" },
  ]);

  const onChange = (newFields) => {
    setTransactionInfo(newFields);
  };

  const handleUpdate = (res) => {
    if (res === 200) {
      message.success("Confirm transaction  successfully!");
      props.onIsFetch(true);
    } else {
      message.error("Error when confirm transaction !");
    }
    props.onClose(false);
  };

  useEffect(() => {
    if (props.submitUpdate === true) {
      props.acceptTransaction(
        props.selectedTrans.trans_id,
        transactionInfo != null && transactionInfo[0] != null
          ? transactionInfo[0].value
          : null,
        transactionInfo != null && transactionInfo[1] != null
          ? transactionInfo[1].value
          : null,
        (res) => handleUpdate(res)
      );
    }
  }, [props.submitUpdate]);

  return (
    <Form
      name="basic"
      className="gx-signin-form gx-form-row0"
      fields={transactionInfo}
      onFieldsChange={(changedFields, allFields) => {
        onChange(allFields);
      }}
    >
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <div className="gx-font-weight-semi-bold">Paid</div>
          <Form.Item
            name="paid"
            rules={[{ required: true, message: "Please input paid money!" }]}
          >
            <Input
              suffix={
                <strong className="gx-text-eco-2">
                  {props.currency ? props.currency : "USD"}
                </strong>
              }
              placeholder="Paid Amount"
              type="number"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <div className="gx-font-weight-semi-bold" style={{ marginBottom: 6 }}>
            Description
          </div>
          <Form.Item
            name="description"
            rules={[{ required: true, message: "Please input description !" }]}
          >
            <Input.TextArea placeholder="Description ..." />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default connect(mapStateToProps, { acceptTransaction })(EditTransaction);
