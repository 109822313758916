import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import { CheckCircleTwoTone, DollarCircleOutlined } from "@ant-design/icons";
import Payment from "./Payment";
import { connect } from "react-redux";
import { formatDataSize } from "../../../../util/formatData";

const mapStateToProps = () => ({});
const NewlineText = (props) => {
  const description = props.description;
  return description.split("\n").map((str) => <p>{str}</p>);
};
const PackageItem = (props) => {
  const [isBuy, setIsBuy] = useState(false);
  const handleClickBuy = () => {
    setIsBuy(true);
  };
  return (
    <div className="package-item">
      <div
        className="gx-product-item gx-product-vertical"
        style={{ height: 450 }}
      >
        <div className="gx-product-body">
          <div
            style={{
              margin: "auto",
              backgroundColor: "#f5efebd9",
              borderRadius: 12,
              height: 80,
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#ef5555ba", //#5ecb89
                height: 60,
                width: 60,
                marginLeft: 10,
                borderRadius: 12,
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <DollarCircleOutlined style={{ fontSize: 25, color: "white" }} />
            </div>
            <div className="gx-ml-4">
              <div
                className="gx-font-weight-bold gx-text-eco-1 gx-product-title"
                style={{ fontSize: 26 }}
              >
                {props.name}
              </div>
              <div className="ant-row-flex">
                <div
                  className="gx-font-weight-bold gx-product-title gx-mr-2"
                  style={{ fontSize: 23, color: "#F3565D" }}
                >
                  {props.real_price} {props.currency}
                </div>
                <del style={{ fontWeight: "bold", color: "gray" }}>
                  {props.origin_price} {props.currency}
                </del>
              </div>
            </div>
          </div>
          <div>
            <div className="ant-row-flex  ant-row-space-between gx-mt-4">
              <div className="gx-font-weight-semi-bold">
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  className="gx-mr-2"
                />
                Traffic
              </div>
              <div
                className="gx-font-weight-semi-bold gx-text-eco-3"
                style={{ fontSize: 16 }}
              >
                {formatDataSize(props.value)}
              </div>
            </div>
            <hr></hr>
            <div className="ant-row-flex  ant-row-space-between gx-mt-3">
              <div className="gx-font-weight-semi-bold">
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  className="gx-mr-2"
                />
                Expired
              </div>
              <div
                className="gx-font-weight-semi-bold gx-text-eco-3"
                style={{ fontSize: 16 }}
              >
                {props.expired} Days
              </div>
            </div>
            <hr></hr>
            <div className="gx-mt-3">
              <div className="gx-font-weight-semi-bold gx-mb-2">
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  className="gx-mr-2"
                />
                Description
              </div>
              <NewlineText description={props.description} />
            </div>
          </div>
        </div>

        <div
          className="gx-product-footer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props.default ? <Button
            style={{
              backgroundColor: "#f5487f", //#006eb8
              color: "white",
              borderRadius: 8,
              height: 50,
              width: "100%",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.06)",
              border: 0,
            }}
            disabled
          >
            <strong style={{ fontSize: 15 }}>Buy Now</strong>
            <i
              className="icon  i icon-arrow-right gx-ml-3"
              style={{ fontSize: 15, fontWeight: "bold" }}
            ></i>
          </Button> :
            <Button
              style={{
                backgroundColor: "#f5487f", //#006eb8
                color: "white",
                borderRadius: 8,
                height: 50,
                width: "100%",
                boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.06)",
                border: 0,
              }}
              onClick={() => handleClickBuy()}
            >
              <strong style={{ fontSize: 15 }}>Buy Now</strong>
              <i
                className="icon  i icon-arrow-right gx-ml-3"
                style={{ fontSize: 15, fontWeight: "bold" }}
              ></i>
            </Button>
          }
        </div>
      </div>
      {isBuy && (
        <Modal
          visible={isBuy}
          footer={false}
          onCancel={() => setIsBuy(false)}
          style={{ borderRadius: 20 }}
          centered
        >
          <Payment
            onClose
            key={props.id}
            name={props.name}
            value={props.value}
            origin_price={props.origin_price}
            real_price={props.real_price}
            description={props.description}
            currency={props.currency}
          />
        </Modal>
      )}
    </div>
  );
};

export default connect(mapStateToProps, {})(PackageItem);
