import React, { useState, useEffect } from "react";
import Widget from "components/Widget";
import Chart from "react-apexcharts";
import { Row, message, DatePicker, Button } from "antd";
import { formatDataSize } from "util/formatData";
import { connect } from "react-redux";
import { getReportData } from "../../../../appRedux/actions/Report";
import moment from "moment";
import { isEmpty } from "util/formatData";

const mapStateToProps = () => ({});
const Traffic = (props) => {
  const [time, setTime] = useState(moment().format("YYYY-MM-DD"));
  const [series, setSeries] = useState([
    {
      name: "Http Traffic",
      data: [],
    },
    {
      name: "P2P Traffic",
      data: [],
    },
  ]);

  const [categories, setCategories] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);

  const options = {
    chart: {
      id: "area-datetime",
      type: "area",
      //  height: 350,
      zoom: {
        autoScaleYaxis: true,
      },
      animations: {
        enabled: false,
      },
    },

    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return formatDataSize(value);
        },
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false,
      },
      // min: new Date("01 Mar 2012").getTime(),
      categories: categories,
      tickAmount: 6,
      tooltip: {
        enabled: false,
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yyyy HH:mm:ss",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
  };

  const handleRefresh = (value) => {
    setIsRefresh(!isRefresh);
  };

  const onSelectMonth = (month) => {
    if (!isEmpty(month)) {
      setTime(moment(month).format("YYYY-MM-DD"));
    } else {
      setTime("");
    }
  };

  useEffect(() => {
    props.getReportData(
      {
        domain: !isEmpty(props.selectedDomain)
          ? props.selectedDomain.domain
          : "",
        email: !isEmpty(props.selectedUser) ? props.selectedUser.value : "",
        selectedtime: time,
      },
      (code, res) => {
        if (code === 200) {
          if (res && res.length > 0) {
            const httpData = res.map((x) => x.httptraffic);
            const p2pData = res.map((x) => x.p2ptraffic);
            const categories = res.map((x) => x.date);
            setCategories(categories);
            setSeries([
              {
                name: "Http Traffic",
                data: httpData,
              },
              {
                name: "P2P Traffic",
                data: p2pData,
              },
            ]);
          } else {
            setCategories([]);
            setSeries([
              {
                name: "Http Traffic",
                data: [],
              },
              {
                name: "P2P Traffic",
                data: [],
              },
            ]);
          }
        } else message.error("Error when getting report traffic data !");
      }
    );
  }, [props.selectedUser, props.selectedDomain, time, isRefresh]);

  return (
    <Widget>
      <Row justify="space-between" style={{ marginLeft: 0, marginRight: 0 }}>
        <div className="h4 gx-mb-3 gx-font-weight-bold gx-text-eco-1">
          TRAFFIC REPORT
        </div>
        <div>
          <DatePicker
            style={{
              height: "38px",
              minWidth: 140,
            }}
            onChange={onSelectMonth}
            defaultValue={moment()}
            format="MM/YYYY"
            picker="month"
          />
          <Button
            style={{
              margin: "auto",
              border: "none",
              borderRadius: "8px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
              height: "38px",
            }}
            className="gx-ml-4"
            type="primary"
            onClick={handleRefresh}
          >
            Refresh
          </Button>
        </div>
      </Row>
      <hr></hr>
      <Chart options={options} series={series} type="area" height={400} />
    </Widget>
  );
};
export default connect(mapStateToProps, {
  getReportData,
})(Traffic);
