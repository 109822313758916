import React, { useState, useEffect } from "react";
import { Form, Input, message, Col, Row, Select } from "antd";
import { DollarCircleOutlined, GlobalOutlined } from "@ant-design/icons";
import { createDataPack } from "../../../appRedux/actions/DataPack";
import { connect } from "react-redux";

const mapStateToProps = () => ({});

function CreatePackage(props) {
  const Option = Select.Option;
  const [packageInfo, setPackageInfo] = useState([]);
  const [typeData, setTypeData] = useState("KB");

  const onChange = (newFields) => {
    setPackageInfo(newFields);
  };

  const handleSelectTypeData = (value) => {
    setTypeData(value);
  };

  const handleAdd = (res) => {
    if (res === 200) {
      message.success("Add domain information successfully!");
      props.onIsFetch(true);
    } else message.error("Error when adding domain information !");
    props.onClose();
  };

  const selectAfter = (
    <Select defaultValue={"KB"} onChange={handleSelectTypeData}>
      <Option value={"KB"}>
        <strong className="gx-text-eco-2">KB</strong>
      </Option>
      <Option value={"GB"}>
        <strong className="gx-text-eco-2">GB</strong>
      </Option>
      <Option value={"TB"}>
        <strong className="gx-text-eco-2">TB</strong>
      </Option>
    </Select>
  );

  useEffect(() => {
    if (props.submitAdd === true) {
      if (typeData == "GB") {
        packageInfo[1].value = packageInfo[1].value * 1024 * 1024;
      } else if (typeData == "TB") {
        packageInfo[1].value = packageInfo[1].value * 1024 * 1024 * 1024;
      }
      props.createDataPack(packageInfo, (res) => handleAdd(res));
    }
  }, [props.submitAdd]);

  return (
    <Form
      name="basic"
      className="gx-signin-form gx-form-row0"
      fields={packageInfo}
      initialValues={{
        currency: "USD",
        active: true,
        public: true,
        isdefault: false,
      }}
      onFieldsChange={(changedFields, allFields) => {
        onChange(allFields);
      }}
    >
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Package Name</div>
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please input  package name!" }]}
          >
            <Input placeholder="The display name of package" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Package Value</div>

          <Form.Item
            name="value"
            rules={[{ required: true, message: "Please input package value!" }]}
          >
            <Input
              type="number"
              placeholder="The value of package"
              addonAfter={selectAfter}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Real Price</div>
          <Form.Item
            name="real_price"
            rules={[{ required: true, message: "Please input real price!" }]}
          >
            <Input
              prefix={
                <DollarCircleOutlined style={{ color: "rgba(0,0,0,.25)" }} />
              }
              placeholder="Real Price"
              type="number"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Origin Price</div>
          <Form.Item
            name="origin_price"
            rules={[{ required: true, message: "Please input origin price!" }]}
          >
            <Input
              prefix={
                <DollarCircleOutlined style={{ color: "rgba(0,0,0,.25)" }} />
              }
              placeholder="Origin Price"
              type="number"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Currency</div>
          <Form.Item
            name="currency"
            rules={[{ required: true, message: "Please input your currency!" }]}
          >
            <Select defaultValue="USD">
              <Option value="USD">USD</Option>
              <Option value="VND">VND</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold" style={{ marginBottom: 6 }}>
            Expired
          </div>
          <Form.Item
            name="expired"
            rules={[{ required: true, message: "Please input expired date!" }]}
          >
            <Input
              suffix={<strong className="gx-text-eco-2">Days</strong>}
              placeholder="Expired (Days)"
              type="number"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Active</div>
          <Form.Item
            name="active"
            rules={[{ required: true, message: "Please choose active or not" }]}
          >
            <Select defaultValue={true}>
              <Option value={true}>Active</Option>
              <Option value={false}>InActive</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Public</div>
          <Form.Item
            name="public"
            rules={[
              { required: true, message: "Please choose public or not!" },
            ]}
          >
            <Select defaultValue={true}>
              <Option value={true}>Public</Option>
              <Option value={false}>Not Public</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold gx-mb-1">
            Default Package
          </div>
          <Form.Item
            name="isdefault"
            rules={[
              {
                required: true,
                message: "Please choose is default package or not!",
              },
            ]}
          >
            <Select defaultValue={false}>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Description</div>
          <Form.Item
            name="description"
            rules={[{ required: true, message: "Please input description !" }]}
          >
            <Input.TextArea
              prefix={<GlobalOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Description ..."
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default connect(mapStateToProps, { createDataPack })(CreatePackage);
