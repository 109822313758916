import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import {
  Table,
  Row,
  Col,
  Tag,
  Modal,
  message,
  Input,
  Select,
} from "antd";
import { FileSearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { searchEmail } from "../../../appRedux/actions/Dashboard";
import { getListTransaction } from "../../../appRedux/actions/Transaction";
import { Pagination } from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Detail from "./components/Detail";

const { Search } = Input;
const { Option, OptGroup } = Select;

const mapStateToProps = () => ({});
const TransactionManager = (props) => {
  const history = useHistory();
  //#region state
  const [keyWord, setKeyWord] = useState("");
  const [isFetch, setIsFetch] = useState(false);
  const [total, setTotal] = useState(50);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [listData, setListData] = useState([]);

  const [isDetail, setIsDetai] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [status, setStatus] = useState("pending");
  const cookies = new Cookies();
  const email = cookies.get("email");

  const columns = [
    {
      title: "ID",
      dataIndex: "trans_id",
      key: "trans_id",
    },
    {
      title: "Pack Name",
      dataIndex: "pack_name",
      key: "pack_name",
    },
    {
      title: "Order Code",
      dataIndex: "ordercode",
      key: "ordercode",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) =>
        status === "success" ? (
          <Tag
            color="#0388fc"
            style={{ width: 80, textAlign: "center", margin: "auto" }}
          >
            Success
          </Tag>
        ) : status === "pending" ? (
          <Tag
            color="#87d068"
            style={{ width: 80, textAlign: "center", margin: "auto" }}
          >
            Pending
          </Tag>
        ) : (
          <Tag
            color="#ff4d4f"
            style={{ width: 80, textAlign: "center", margin: "auto" }}
          >
            Cancle
          </Tag>
        ),
    },
    {
      title: "Real Price",
      dataIndex: "real_price",
      key: "real_price",
    },
    {
      title: "Origin Price",
      dataIndex: "origin_price",
      key: "origin_price",
    },
    {
      title: "Paid",
      dataIndex: "paid",
      key: "paid",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Pay date",
      dataIndex: "paydate",
      key: "paydate",
      render: (time) => moment(time).utc().format("DD-MM-YYYY HH:mm:ss"),
    },
    // {
    //   title: "Actions",
    //   key: "action",
    //   render: (text, record) => (
    //     <span key="btn-verify" className="gx-link">
    //       <Button
    //         style={{ width: "80px", height: "35px", margin: "auto" }}
    //         type="primary"
    //         onClick={() => onDetail(record)}
    //       >
    //         Detail
    //       </Button>
    //     </span>
    //   ),
    // },
  ];

  // const onDetail = (record) => {
  //   setIsDetai(true);
  //   setSelectedItem(record);
  // };
  const handleChangeStatus = (status) => {
    setStatus(status);
  };

  const onChange = (current, pageSize) => {
    setPage(current);
    setPageSize(pageSize);
  };

  useEffect(() => {
    props.getListTransaction(
      {
        keyword: keyWord,
        page: page,
        page_size: pageSize,
        email: email,
        trans_status: status,
      },
      (code, res) => handleResult(code, res)
    );
    const handleResult = (code, res) => {
      if (code === 200) {
        setListData(res.data);
        setTotal(res.total);
      } else {
        message.error("Error when getting transaction information!");
      }
    };
  }, [page, pageSize, keyWord, status]);

  return (
    <div>
      <Row
        justify="space-between"
        style={{ marginLeft: "-15px", marginRight: "-15px" }}
      >
        <Col
          style={{ marginRight: "0px" }}
          xs={24}
          sm={24}
          md={8}
          lg={6}
          xl={4}
          xxl={4}
        >
          <h4
            className="gx-font-weight-semi-bold "
            style={{ color: "#274679", marginLeft: 0 }}
          >
            TRANSACTIONS
          </h4>
        </Col>
        <Col
          xs={0}
          sm={0}
          md={0}
          lg={6}
          xl={8}
          xxl={8}
          //  className="gx-order-lg-2 gx-order-md-2"
        ></Col>
        <Col
          xs={12}
          sm={12}
          md={6}
          lg={4}
          xl={4}
          xxl={4}
          //  className="gx-order-lg-2 gx-order-md-2"
        >
          <Select
            defaultValue={status}
            bordered={false}
            style={{
              borderRadius: "8px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
              height: "37px",
              width: "100%",
              // minWidth: 140,
              backgroundColor: "white",
            }}
            onChange={(status) => handleChangeStatus(status)}
            className=" gx-mb-4"
          >
            <OptGroup label="Status">
              <Option value="success">Success</Option>
              <Option value="pending">Pending</Option>
              <Option value="cancel">Cancel</Option>
            </OptGroup>
          </Select>
        </Col>
        <Col xs={12} sm={12} md={10} lg={8} xl={8} xxl={8}>
          <Search
            placeholder="Filter by order code ..."
            onSearch={(value) => setKeyWord(value)}
            allowClear
            bordered={false}
            enterButton={<FileSearchOutlined/>}
            style={{
              height: "37px",
              backgroundColor: "white",
              borderRadius: "6px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
            }}
          />
        </Col>
      </Row>
      <div className="gx-card" key="card">
        <Table
          className="gx-table-responsive"
          key="table"
          columns={columns}
          dataSource={listData}
          pagination={false}
          rowKey="trans_id"
          style={{
            border: "none",
            borderRadius: "10px",
            minHeight: "360px",
          }}
        />
        <br />
        <Pagination
          showTotal={(total) => `Total ${total} items`}
          total={total}
          defaultCurrent={page}
          defaultPageSize={pageSize}
          onChange={onChange}
          showQuickJumper
          showSizeChanger
          style={{ marginLeft: "20px" }}
        />
        {isDetail && (
          <Modal
            maskClosable={false}
            title={
              <div className="gx-font-weight-semi-bold gx-text-eco-1">
                TRANSACTION DETAIL
              </div>
            }
            width={720}
            wrapClassName="vertical-center-modal"
            visible={isDetail}
            centered={true}
            onCancel={() => setIsDetai(false)}
            onOk={() => setIsDetai(false)}
          >
            <Detail selectedItem={selectedItem} />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, {
  getListTransaction,
  searchEmail,
})(TransactionManager);
