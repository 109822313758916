import React from "react";
import { Route, Switch } from "react-router-dom";
import Visualization from "./Visualization";
// import Statistic from "./Statistic";
import MonthReport from "./MonthReport";
import UserTraffic from "./UserTraffic";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const roleAccount = cookies.get("role");

const Report = ({ match }) => (
  <Switch>
    {/* <Route path={`${match.url}/statistic`} component={Statistic} /> */}
    <Route path={`${match.url}/visualization`} component={Visualization} />
    <Route path={`${match.url}/month`} component={MonthReport} />
    {roleAccount === "admin" || roleAccount === "super" ? (
      <Route path={`${match.url}/user-traffic`} component={UserTraffic} />
    ) : null}
  </Switch>
);

export default Report;
