import React, { useState, useEffect } from "react";
import { Form, Input, Col, Row, Select } from "antd";
import { DollarCircleOutlined, GlobalOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";

const mapStateToProps = () => ({});

function Detail(props) {
  const Option = Select.Option;

  const [transactionInfo, setTransactionInfo] = useState(null);

  useEffect(() => {
    if (props.selectedTrans) {
      setTransactionInfo([
        {
          name: "email",
          value: props.selectedTrans.email,
        },
        {
          name: "pack_name",
          value: props.selectedTrans.pack_name,
        },
        {
          name: "ordercode",
          value: props.selectedTrans.ordercode,
        },
        {
          name: "origin_price",
          value: props.selectedTrans.origin_price,
        },
        {
          name: "real_price",
          value: props.selectedTrans.real_price,
        },
        {
          name: "paid",
          value: props.selectedTrans.paid,
        },
        {
          name: "payment_id",
          value: props.selectedTrans.payment_id,
        },
        {
          name: "status",
          value: props.selectedTrans.status,
        },
        {
          name: "created",
          value: moment(props.selectedTrans.created)
            .utc()
            .format("DD-MM-YYYY HH:mm:ss"),
        },
        {
          name: "paydate",
          value: moment(props.selectedTrans.paydate)
            .utc()
            .format("DD-MM-YYYY HH:mm:ss"),
        },
        {
          name: "description",
          value: props.selectedTrans.description,
        },
      ]);
    }
  }, [props.selectedTrans]);

  return (
    <Form
      name="basic"
      className="gx-signin-form gx-form-row0"
      fields={transactionInfo}
    >
      <Row gutter={[16, 2]}>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold" style={{ marginBottom: 6 }}>
            Email
          </div>
          <Form.Item name="email" className="gx-mb-1">
            <Input placeholder="Email" type="email" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Package Name</div>
          <Form.Item name="pack_name" className="gx-mb-1">
            <Input placeholder="The display name of package" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold" style={{ marginBottom: 6 }}>
            Transaction Code
          </div>
          <Form.Item name="ordercode" className="gx-mb-1">
            <Input placeholder="Transaction Code" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Origin Price</div>
          <Form.Item name="origin_price" className="gx-mb-1">
            <Input
              prefix={
                <DollarCircleOutlined style={{ color: "rgba(0,0,0,.25)" }} />
              }
              suffix={
                <strong className="gx-text-eco-2">
                  {props.selectedTrans.currency}
                </strong>
              }
              placeholder="Origin Price"
              type="number"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Real Price</div>
          <Form.Item name="real_price" className="gx-mb-1">
            <Input
              prefix={
                <DollarCircleOutlined style={{ color: "rgba(0,0,0,.25)" }} />
              }
              suffix={
                <strong className="gx-text-eco-2">
                  {props.selectedTrans.currency}
                </strong>
              }
              placeholder="Real Price"
              type="number"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold" style={{ marginBottom: 6 }}>
            Paid
          </div>
          <Form.Item name="paid" className="gx-mb-1">
            <Input
              prefix={
                <DollarCircleOutlined style={{ color: "rgba(0,0,0,.25)" }} />
              }
              suffix={
                <strong className="gx-text-eco-2">
                  {props.selectedTrans.currency}
                </strong>
              }
              placeholder="Paid Amount"
              type="number"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Payment ID</div>
          <Form.Item name="payment_id" className="gx-mb-1">
            <Select>
              <Option value={1}>Direct Payment</Option>
              <Option value={2}>Paypal</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Status</div>
          <Form.Item initialValue="success" name="status" className="gx-mb-1">
            <Select>
              <Option value="success">Success</Option>
              <Option value="pending">Pending</Option>
              <Option value="cancle">Cancle</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold" style={{ marginBottom: 6 }}>
            Created Date
          </div>
          <Form.Item name="created">
            <Input placeholder="Created Date" type="text" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className="gx-font-weight-semi-bold" style={{ marginBottom: 6 }}>
            Pay Date
          </div>
          <Form.Item name="paydate">
            <Input placeholder="Pay Date" type="text" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <div className="gx-font-weight-semi-bold gx-mb-1">Description</div>
          <Form.Item name="description" className="gx-mb-1">
            <Input.TextArea
              prefix={<GlobalOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Description ..."
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default connect(mapStateToProps, {})(Detail);
