import React, { useState, useEffect } from "react";
import { Layout, Popover, Button, message } from "antd";
import { Link, useHistory } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import {
  switchLanguage,
  toggleCollapsedSideNav,
} from "../../appRedux/actions/Setting";
import SearchBox from "components/SearchBox";
import UserInfo from "components/UserInfo";
import AppNotification from "components/AppNotification";
import MailNotification from "components/MailNotification";
import Auxiliary from "util/Auxiliary";
import Cookies from "universal-cookie";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector, connect } from "react-redux";
import { getUserTrafficByEmail } from "../../appRedux/actions/UserTraffic";
import { WarningOutlined } from "@ant-design/icons";

const { Header } = Layout;
const mapStateToProps = () => ({});

const Topbar = (props) => {
  const { locale, navStyle } = useSelector(({ settings }) => settings);
  const { navCollapsed, width } = useSelector(({ common }) => common);
  const { searchText, setSearchText } = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const [isExpired, setIsExpired] = useState(false);
  const [isUsed, setIsUsed] = useState(false);

  const cookies = new Cookies();
  const roleAccount = cookies.get("role");
  const userEmail = cookies.get("email");

  // const languageMenu = () => (
  //   <CustomScrollbars className="gx-popover-lang-scroll">
  //     <ul className="gx-sub">
  //       {languageData.map((language) => (
  //         <li
  //           className="gx-media gx-pointer"
  //           key={JSON.stringify(language)}
  //           onClick={(e) => dispatch(switchLanguage(language))}
  //         >
  //           <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
  //           <span className="gx-language-text">{language.name}</span>
  //         </li>
  //       ))}
  //     </ul>
  //   </CustomScrollbars>
  // );

  const languageMenu = () => (
    <li className="gx-media gx-pointer" key="us">
      <i className="flag flag-24 gx-mr-2 flag-us" />
      <span className="gx-language-text">English</span>
    </li>
  );

  const updateSearchChatUser = (evt) => {
    setSearchText(evt.target.value);
  };

  useEffect(() => {
    if (userEmail) {
      props.getUserTrafficByEmail(props.userEmail, (code, res) => {
        if (code === 200) {
          if (res && res["usertraffic"]) {
            const userTraffic = res["usertraffic"];

            if (userTraffic["expired"]) {
              const diff = new Date(userTraffic["expired"]) - new Date();
              const diffDays = diff / (1000 * 3600 * 24);
              if (diffDays < 3) {
                setIsExpired(true);
              }
            }

            if (userTraffic["buyedtraffic"] === userTraffic["usedtraffic"]) {
              setIsUsed(true);
            }
          }
        }
      });
    }
  }, [userEmail]);

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}
      <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="" href="/" src={require("assets/images/w-logo.png")} />
      </Link>

      {/* <SearchBox styleName="gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg"
                 placeholder="Search in app..."
                 onChange={updateSearchChatUser}
                 value={searchText}/> */}
      <ul className="gx-header-notifications gx-ml-auto">
        {roleAccount === "user" ? (
          <li>
            {(isExpired || (isExpired && isUsed)) && (
              <div
                style={{
                  color: "red",
                  fontSize: 16,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <WarningOutlined />
                <div style={{ marginLeft: 10 }}>
                  <strong>Your packet is going to be expired!</strong>
                </div>
              </div>
            )}
            {isUsed && !isExpired && (
              <div
                style={{
                  color: "red",
                  fontSize: 16,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <WarningOutlined />
                <div style={{ marginLeft: 10 }}>
                  You have no traffic remain!
                </div>
              </div>
            )}
          </li>
        ) : null}

        {roleAccount === "user" ? (
          <li
            className="gx-language"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div>
              <Button
                style={{
                  margin: "auto",
                  backgroundColor: "#038fde", //#006eb8
                  color: "white",
                  borderRadius: 8,
                  width: "100%",
                  boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.06)",
                  border: 0,
                }}
                onClick={() => {
                  history.push("/pricing/resource");
                }}
              >
                <i className="icon icon-add gx-mr-2 gx-mb-0" />
                <strong>Buy Traffic</strong>
              </Button>
            </div>
          </li>
        ) : null}
        <li className="gx-language">
          <Popover
            overlayClassName="gx-popover-horizantal"
            placement="bottomRight"
            content={languageMenu()}
            trigger="click"
            disable
          >
            <span className="gx-pointer gx-flex-row gx-align-items-center">
              <i className={`flag flag-24 flag-us`} />
              <span className="gx-pl-2 gx-language-name">English</span>
              <i className="icon icon-chevron-down gx-pl-2" />
            </span>
          </Popover>
        </li>
        {width >= TAB_SIZE ? null : (
          <Auxiliary>
            <li className="gx-user-nav">
              <UserInfo />
            </li>
          </Auxiliary>
        )}
      </ul>
    </Header>
  );
};

export default connect(mapStateToProps, {
  getUserTrafficByEmail,
})(Topbar);
