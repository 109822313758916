import React, { useState, useEffect } from "react";
import { Col, message, Row, Input } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import PackageItem from "./components/PackageItem";

import { searchDataPack } from "../../../appRedux/actions/DataPack";
import Auxiliary from "../../../util/Auxiliary";

const mapStateToProps = () => ({});
const { Search } = Input;

const Resource = (props) => {
  const [keyWord, setKeyWord] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [listPackage, setListPackage] = useState([]);
  useEffect(() => {
    props.searchDataPack(
      { page: page, page_size: pageSize, keyword: keyWord },
      (code, res) => handleResult(code, res)
    );
    const handleResult = (code, res) => {
      if (code === 200) {
        setListPackage(res.data.filter((item) => item.public));
      } else {
        message.error("Error when getting package information!");
      }
    };
  }, [keyWord]);
  const content = listPackage.map((item) => (
    <Col key={item.id} xxl={6} xl={8} lg={12} md={12} sm={24} xs={24}>
      <PackageItem
        key={item.id}
        name={item.name}
        value={item.value}
        origin_price={item.origin_price}
        real_price={item.real_price}
        default={item.default}
        expired={item.expired}
        description={item.description}
        currency={item.currency}
      />
    </Col>
  ));

  return (
    <Auxiliary>
      <Row justify="space-between" style={{ marginLeft: 0, marginRight: 0 }}>
        <Col>
          <h4
            className="gx-font-weight-semi-bold gx-mb-3"
            style={{ color: "#274679", marginLeft: 0 }}
          >
            RESOURCE
          </h4>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} className="">
          <Search
            placeholder="Filter by pack name ..."
            onSearch={(value) => setKeyWord(value)}
            allowClear
            bordered={false}
            enterButton={<PlusCircleOutlined />}
            style={{
              height: "37px",
              backgroundColor: "white",
              borderRadius: "6px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
            }}
          />
        </Col>
      </Row>
      <div className="list-item ">
        <Row>{content}</Row>
      </div>
    </Auxiliary>
  );
};

export default connect(mapStateToProps, {
  searchDataPack,
})(Resource);
