import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Row,
  Col,
  Space,
  Tag,
  Modal,
  message,
  Input,
} from "antd";
import { MedicineBoxOutlined, FileSearchOutlined } from "@ant-design/icons";
import CreatePackage from "./components/CreatePackage";
import EditPackage from "./components/EditPackage";
import { connect } from "react-redux";
import { searchEmail } from "../../appRedux/actions/Dashboard";
import { formatDataSize } from "../../util/formatData";
import {
  verifyDomain,
  setStatusDomain,
} from "../../appRedux/actions/DomainConfig";
import {
  searchDataPack,
  deleteDatapack,
} from "../../appRedux/actions/DataPack";
import { Pagination } from "antd";

const { Search } = Input;

const mapStateToProps = () => ({});
const confirm = Modal.confirm;
const PackageManager = (props) => {
  //#region state
  const [isFetch, setIsFetch] = useState(false);
  const [keyWord, setKeyWord] = useState("");
  const [isAddPackage, setIsAddPackage] = useState(false);
  const [submitAdd, setSubmitAdd] = useState(false);
  const [submitUpdate, setSubmitUpdate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [packageInfo, setPackageInfo] = useState({});
  const [total, setTotal] = useState(50);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [listData, setListData] = useState([]);

  const columns = [
    {
      title: "ID",
      width: "5%",
      render: (text, record) => {
        return listData.indexOf(record) + 1;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Real Price",
      dataIndex: "real_price",
      key: "real_price",
    },
    {
      title: "Origin Price",
      dataIndex: "origin_price",
      key: "origin_price",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (value) => formatDataSize(value),
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Expired",
      dataIndex: "expired",
      key: "expired",
    },
    {
      title: "Public",
      dataIndex: "public",
      key: "public",
      render: (public_value) =>
        public_value === true ? (
          <Tag
            color="#0388fc"
            style={{ width: 60, textAlign: "center", margin: "auto" }}
          >
            Yes
          </Tag>
        ) : (
          <Tag
            color="#ff4d4f"
            style={{ width: 60, textAlign: "center", margin: "auto" }}
          >
            No
          </Tag>
        ),
    },
    {
      title: "Default Package",
      dataIndex: "default",
      key: "default",
      render: (isdefault) =>
        isdefault === true ? (
          <Tag
            color="#0388fc"
            style={{ width: 60, textAlign: "center", margin: "auto" }}
          >
            Yes
          </Tag>
        ) : (
          <Tag
            color="#ff4d4f"
            style={{ width: 60, textAlign: "center", margin: "auto" }}
          >
            No
          </Tag>
        ),
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (active) =>
        active === true ? (
          <Tag
            color="#0388fc"
            style={{ width: 80, textAlign: "center", margin: "auto" }}
          >
            Active
          </Tag>
        ) : (
          <Tag
            color="#ff4d4f"
            style={{ width: 80, textAlign: "center", margin: "auto" }}
          >
            InActive
          </Tag>
        ),
    },
    {
      title: "",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <Space onClick={() => handleActionClick(record)}>
          <span
            key="btn-verify"
            className="gx-link"
            onClick={() => setIsEdit(true)}
          >
            <Button
              style={{ width: 80, height: 35, margin: "auto" }}
              type="primary"
            >
              Edit
            </Button>
          </span>
          <span className="gx-link" onClick={() => confirmDelete(record)}>
          <Button
            style={{
              width: "80px",
              height: "35px",
              margin: "auto",
              color: "white",
              background: "#ff4d4f"
            }}
          >
            Delete
          </Button>
          </span>
        </Space>
      ),
    },
  ];

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
    menu: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
  };

  const onIsFetch = (isFetch) => {
    setIsFetch(isFetch);
  };

  const onClose = () => {
    setSubmitAdd(false);
    setSubmitUpdate(false);
    setIsAddPackage(false);
    setIsEdit(false);
    setIsFetch(false);
  };

  const onChange = (current, pageSize) => {
    setPage(current);
    setPageSize(pageSize);
  };

  const handleActionClick = (packageInfo) => {
    setPackageInfo(packageInfo);
  };

  const confirmDelete = (record) => {
    confirm({
      title: "Do you want to delete this pack ?",
      content: "If you agree, please click OK ",
      onOk() {
        props.deleteDatapack(record.id, (code) => {
          if (code === 200) {
            setIsFetch(true);
            onClose();
            message.success("Delete Pack Successfully !");
          } else message.error("Error when deleting pack !");
        });
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    var email = "";
    props.searchDataPack(
      { keyword: keyWord, page: page, page_size: pageSize },
      (code, res) => handleResult(code, res)
    );
    const handleResult = (code, res) => {
      if (code === 200) {
        setListData(res.data);

        setTotal(res.total);
      } else {
        message.error("Error when getting data pack information!");
      }
    };
  }, [page, pageSize, keyWord]);

  useEffect(() => {
    if (isFetch === true) {
      props.searchDataPack(
        { keyword: keyWord, page: page, page_size: pageSize },
        (code, res) => handleResult(code, res)
      );
      const handleResult = (code, res) => {
        if (code === 200) {
          setListData(res.data);
          setTotal(res.total);
        } else {
          message.error("Error when getting domain information!");
        }
      };
    }
  }, [page, pageSize, isFetch, keyWord]);

  return (
    <div>
      <Row
        justify="space-between"
        style={{ marginLeft: "-15px", marginRight: "-15px" }}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <h4
            className="gx-font-weight-semi-bold gx-mb-3"
            style={{ color: "#274679" }}
          >
            PACKAGE MANAGER
          </h4>
        </Col>
        <Col
          xs={11}
          sm={11}
          md={4}
          lg={4}
          xl={4}
          xxl={3}
          style={{ marginRight: "0px" }}
        >
          <Button
            type="default"
            style={{
              // backgroundColor: "#274679",
              fontWeight: "bold",
              height: "37px",
              color: "#274679",
              border: "none",
              borderRadius: "8px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
            }}
            icon={<MedicineBoxOutlined />}
            onClick={() => setIsAddPackage(true)}
          >
            Add Package
          </Button>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} className="">
          <Search
            placeholder="Filter by pack name ..."
            onSearch={(value) => setKeyWord(value)}
            allowClear
            bordered={false}
            enterButton={<FileSearchOutlined />}
            style={{
              height: "37px",
              backgroundColor: "white",
              borderRadius: "6px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
            }}
          />
        </Col>
      </Row>
      <div
        className="gx-card"
        key="card"
      >
        <Table
          className="gx-table-responsive"
          key="table"
          columns={columns}
          dataSource={listData}
          pagination={false}
          rowKey="id"
          style={{
            border: "none",
            borderRadius: "10px",
            minHeight: "360px",
          }}
        />
        <br />
        <Pagination
          showTotal={(total) => `Total ${total} items`}
          total={total}
          defaultCurrent={page}
          defaultPageSize={pageSize}
          onChange={onChange}
          showQuickJumper
          showSizeChanger
          style={{ marginLeft: "20px" }}
        />

        {isAddPackage && (
          <Modal
            maskClosable={false}
            title={
              <div className="gx-font-weight-semi-bold gx-text-eco-1">
                ADD NEW PACKAGE
              </div>
            }
            width={720}
            wrapClassName="vertical-center-modal"
            visible={isAddPackage}
            onOk={() => setSubmitAdd(true)}
            onCancel={() => setIsAddPackage(false)}
            centered={true}
          >
            <CreatePackage
              submitAdd={submitAdd}
              onClose={onClose}
              onIsFetch={onIsFetch}
            />
          </Modal>
        )}
        {isEdit && (
          <Modal
            maskClosable={false}
            title={
              <div className="gx-font-weight-semi-bold gx-text-eco-1">
                EDIT PACKAGE
              </div>
            }
            width={720}
            wrapClassName="vertical-center-modal"
            visible={isEdit}
            onOk={() => setSubmitUpdate(true)}
            onCancel={() => setIsEdit(false)}
            centered={true}
          >
            <EditPackage
              packageInfo={packageInfo}
              submitUpdate={submitUpdate}
              onClose={onClose}
              onIsFetch={onIsFetch}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, {
  searchDataPack,
  deleteDatapack,
  verifyDomain,
  setStatusDomain,
  searchEmail,
})(PackageManager);
