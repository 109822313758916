import React from "react";
import { formatDataSize, isEmpty } from "../../../../util/formatData";

import Widget from "components/Widget/index";
import { Card } from "antd";

const P2PTrafficMonth = (props) => {
  return (
    <Card
      style={{
        backgroundColor: "#20bf55",
        backgroundImage: `linear-gradient(315deg, #20bf55 0%,
        #01baef 99%)`,
        borderRadius: "10px",
        boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.05)",
      }}
    >
      <div className="gx-text-capitalize gx-mb-2 gx-text-white gx-font-weight-heavy">
        P2P TRAFFIC THIS MONTH
      </div>
      <div className="h1 gx-text-capitalize gx-mb-0 gx-text-white gx-font-weight-bold">
        {formatDataSize(props.p2pTrafficMonth)}
      </div>
      <hr />
    </Card>
  );
};

export default P2PTrafficMonth;
