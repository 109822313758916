import React, { useState, useEffect } from "react";
import FileSaver from "file-saver";
import Cookies from "universal-cookie";
import AsyncSelect from "react-select/async";
import axios from "util/Api";
import {
  Card,
  Table,
  Button,
  Row,
  Col,
  Space,
  Tag,
  Modal,
  message,
  Switch,
  Timeline,
  Input,
} from "antd";
import { GlobalOutlined, FileSearchOutlined } from "@ant-design/icons";
import CreateDomain from "./components/CreateDomain";
import { connect } from "react-redux";
import { searchEmail } from "../../appRedux/actions/Dashboard";
import {
  getDomainList,
  deleteDomain,
  verifyDomain,
  setStatusDomain,
} from "../../appRedux/actions/DomainConfig";
import { Pagination } from "antd";
import moment from "moment";

const { Search } = Input;

const mapStateToProps = () => ({});
const confirm = Modal.confirm;
const DomainConfig = (props) => {
  //#region state
  const [isFetch, setIsFetch] = useState(false);
  const [keyWord, setKeyWord] = useState("");
  const [isAddDomain, setIsAddDomain] = useState(false);
  const [submitAdd, setSubmitAdd] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [domainID, setDomainID] = useState(null);
  const [text, setText] = useState("");
  const [domain, setDomain] = useState(null);
  const [total, setTotal] = useState(50);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [listData, setListData] = useState([]);

  const [selectedUser, setSelectedUser] = useState(null);
  const [defaultUserList, setDefaultUserList] = useState([]);
  const cookies = new Cookies();
  const roleAccount = cookies.get("role");
  const columns = [
    {
      title: "ID",
      width: "10%",
      render: (text, record) => listData.indexOf(record) + 1,
    },
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
    },
    {
      title: "Time Created",
      dataIndex: "created_at",
      key: "created_at",
      width: "20%",
      render: (time) => moment(time).utc().format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: "Type",
      dataIndex: "isAdvanced",
      key: "isAdvanced",
      width: "10%",
      render: (isAdvanced) =>
      isAdvanced === 0 ? (
          <Tag style={{width: 80, textAlign: "center", margin: "auto"}} color="#87d068">
            Default
          </Tag>
        ) : (
          <Tag style={{width: 80, textAlign: "center", margin: "auto"}} color="#ff4d4f">
            Advanced
          </Tag>
        ),
    },
    {
      title: "State",
      dataIndex: "continue_switch",
      key: "continue_switch",
      width: "10%",
      render: (continue_switch) =>
      continue_switch !== 1 ? (
          <Tag style={{width: 80, textAlign: "center", margin: "auto"}} color="#87d068">
            Active
          </Tag>
        ) : (
          <Tag style={{width: 80, textAlign: "center", margin: "auto"}} color="#ff4d4f">
            InActive
          </Tag>
        ),
    },
    {
      title: "Setting",
      key: "setting",
      width: "10%",
      render: (text, record) => (
        <Space
          size="large"
          onClick={() =>
            handleActionClick(record.id, record.domain, record.text)
          }
        >
          <Switch
            checked={stateSwitch(record)}
            checkedChildren="enable"
            unCheckedChildren="disable"
            defaultChecked
            onChange={() => handleSwitch(record)}
          />
        </Space>
      ),
    }
  ];

  const adminColumns = [
    {
      title: "ID",
      width: "10%",
      render: (text, record) => listData.indexOf(record) + 1,
    },
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
    },
    {
      title: "Time Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (time) => moment(time).utc().format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: "Type",
      dataIndex: "isAdvanced",
      key: "isAdvanced",
      width: "10%",
      render: (isAdvanced) =>
      isAdvanced === 0 ? (
          <Tag style={{width: "80px", textAlign: "center", margin: "auto"}} color="#0388fc">
            Default
          </Tag>
        ) : (
          <Tag style={{width: "80px", textAlign: "center", margin: "auto"}} color="#87d068">
            Advanced
          </Tag>
        ),
    },
    {
      title: "State",
      dataIndex: "continue_switch",
      key: "continue_switch",
      width: "10%",
      render: (continue_switch) =>
      continue_switch !== 1 ? (
          <Tag style={{width: "80px", textAlign: "center", margin: "auto"}} color="#0388fc">
            Active
          </Tag>
        ) : (
          <Tag style={{width: "80px", textAlign: "center", margin: "auto"}} color="#ff4d4f">
            InActive
          </Tag>
        ),
    },
    {
      title: "Setting",
      key: "setting",
      render: (text, record) => (
        <Space
          size="large"
          onClick={() =>
            handleActionClick(record.id, record.domain, record.text)
          }
        >
          <Switch
            style={{ width: "80px" }}
            checked={stateSwitch(record)}
            checkedChildren="enable"
            unCheckedChildren="disable"
            defaultChecked
            onChange={() => handleSwitch(record)}
          />
        </Space>
      ),
    },
    {
      title: "",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <Space
          onClick={() =>
            handleActionClick(record.id, record.domain, record.text)
          }
        >
          {roleAccount !== "user" ? (
            <Space style={{ alignItems: "center" }}>
              <span className="gx-link" onClick={() => confirmDelete(record)}>
                <Button
                  style={{
                    width: "80px",
                    height: "35px",
                    margin: "auto",
                    color: "white",
                    background: "#ff4d4f"
                  }}
                >
                  Delete
                </Button>
              </span>
            </Space>
          ) : (
            ""
          )}
        </Space>
      ),
    },
  ];

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
    menu: (styles) => ({
      ...styles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
  };

  const stateSwitch = (record) => {
    if (roleAccount === "user") {
      if (!record.admin_disable_p2p) return !record.disable_p2p;
      return !record.admin_disable_p2p;
    } else {
      return !record.admin_disable_p2p;
    }
  };

  const onIsFetch = (isFetch) => {
    setIsFetch(isFetch);
  };

  const onClose = () => {
    setSubmitAdd(false);
    setIsAddDomain(false);
    setIsVerify(false);
    setIsFetch(false);
  };

  const onClickDownloadFile = (text) => {
    var blob = new Blob([text], { type: "text/plain;charset=utf-8" });
    FileSaver.saveAs(blob, "auth.txt");
  };
  const onChange = (current, pageSize) => {
    setPage(current);
    setPageSize(pageSize);
  };

  const handleSwitch = (record) => {
    //setTimeout(setIsLoading(true), 200);
    if (roleAccount === "user" && !record.admin_disable_p2p) {
      props.setStatusDomain(
        { domain_id: record.id, disable: !record.disable_p2p },
        (code, res) => {
          if (code === 200) {
            message.success("Set status domain successfully");
            setIsFetch(true);
            setIsFetch(false);
          } else {
            message.error("Error when verifing domain");
          }
        }
      );
    } else if (roleAccount === "admin" || roleAccount === "super") {
      props.setStatusDomain(
        { domain_id: record.id, disable: !record.admin_disable_p2p },
        (code, res) => {
          if (code === 200) {
            message.success("Set status domain successfully");
            setIsFetch(true);
            setIsFetch(false);
          } else {
            message.error("Error when verifing domain");
          }
        }
      );
    }
    // setIsLoading(false);
  };

  const handleActionClick = (id, domain, text) => {
    // e.preventDefault();
    setText(text);
    setDomainID(id);
    setDomain(domain);
  };

  const handleVerifyOkButton = (e) => {
    props.verifyDomain(domainID, (code, res) => {
      if (code === 200) {
        message.success("Verify domain successfully");
        setIsFetch(true);
        onClose();
      } else {
        message.error("Error when verifing domain");
      }
    });
  };

  const confirmDelete = (record) => {
    confirm({
      title: "Do you want to delete this domain ?",
      content: "If you agree, please click OK ",
      onOk() {
        props.deleteDomain(record.id, (code) => {
          if (code === 200) {
            setIsFetch(true);
            onClose();
            message.success("Delete App Successfully !");
          } else message.error("Error when deleting app !");
        });
      },
      onCancel() {
      },
    });
  };

  // Configure react-select
  const loadDefaultUserList = () => {
    if (roleAccount !== "user") {
      props.searchEmail(
        { page: 1, page_size: 20, keyword: "" },
        (code, res) => {
          if (code === 200) {
            setDefaultUserList(res);
          } else message.error("Error when fetching user list !");
        }
      );
    }
  };

  const loadUserOptions = (inputValue) => {
    if (roleAccount !== "user") {
      return axios
        .get(`admin/email/search?page=1&page_size=5&keyword=${inputValue}`)
        .then((res) => res.data.data);
    }
  };

  useEffect(() => {
    var email = "";
    if (selectedUser) email = selectedUser.value;
    props.getDomainList(
      { keyword: keyWord, page: page, page_size: pageSize, email: email },
      (code, res) => handleResult(code, res)
    );
    const handleResult = (code, res) => {
      if (code === 200) {
        setListData(res.data);

        if (!defaultUserList.length) loadDefaultUserList();
        setTotal(res.total);
      } else {
        message.error("Error when getting domain information!");
      }
    };
  }, [page, pageSize, keyWord, selectedUser]);

  useEffect(() => {
    if (isFetch === true) {
      var email = "";
      if (selectedUser) email = selectedUser.value;
      props.getDomainList(
        { keyword: keyWord, page: page, page_size: pageSize, email: email },
        (code, res) => handleResult(code, res)
      );
      const handleResult = (code, res) => {
        if (code === 200) {
          setListData(res.data);
          setTotal(res.total);
        } else {
          message.error("Error when getting domain information!");
        }
      };
    }
  }, [page, pageSize, isFetch, keyWord, selectedUser]);

  return (
    <div>
      <Row
        // justify="space-between"
        style={{ marginLeft: "-15px", marginRight: "-15px" }}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <h4
            className="gx-font-weight-semi-bold gx-mb-3"
            style={{ color: "#274679" }}
          >
            DOMAIN CONFIG
          </h4>
        </Col>
        <Col
          xs={11}
          sm={11}
          md={4}
          lg={4}
          xl={4}
          xxl={3}
          className="gx-order-lg-1 gx-order-md-1"
          style={{ marginRight: "0px" }}
        >
          <Button
            type="default"
            style={{
              // backgroundColor: "#274679",
              fontWeight: 600,
              height: "37px",
              color: "#274679",
              border: "none",
              borderRadius: "8px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
            }}
            icon={<GlobalOutlined />}
            onClick={() => setIsAddDomain(true)}
          >
            Add Domain
          </Button>
        </Col>
        <Col
          xs={13}
          sm={13}
          md={7}
          lg={7}
          xl={6}
          xxl={6}
          className="gx-order-lg-3  gx-order-md-3"
        >
          {roleAccount === "super" || roleAccount === "admin" ? (
            <AsyncSelect
              className="gx-mb-3"
              placeholder="All user..."
              isClearable={true}
              styles={colourStyles}
              defaultOptions={defaultUserList}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              loadOptions={loadUserOptions}
              onChange={(value) => setSelectedUser(value)}
              maxMenuHeight={175}
            />
          ) : null}
        </Col>
        <Col
          xs={0}
          sm={0}
          md={1}
          lg={4}
          xl={6}
          xxl={7}
          className="gx-order-lg-2 gx-order-md-2"
        ></Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={8}
          xl={8}
          xxl={8}
          className="gx-order-lg-4 gx-order-md-4"
        >
          <Search
            placeholder="Filter by domain ..."
            onSearch={(value) => setKeyWord(value)}
            allowClear
            bordered={false}
            enterButton={<FileSearchOutlined />}
            style={{
              height: "37px",
              backgroundColor: "white",
              borderRadius: "6px",
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
            }}
          />
        </Col>
      </Row>
      <div
        className="gx-card"
        key="card"
      >
        <Table
          className="gx-table-responsive"
          key="table"
          columns={roleAccount === "user" ? columns : adminColumns}
          dataSource={listData}
          pagination={false}
          rowKey="id"
          style={{
            border: "none",
            borderRadius: "10px",
            minHeight: "360px",
          }}
        />
        <br />
        <Pagination
          showTotal={(total) => `Total ${total} items`}
          total={total}
          defaultCurrent={page}
          defaultPageSize={pageSize}
          onChange={onChange}
          showQuickJumper
          showSizeChanger
          style={{ marginLeft: "20px" }}
        />

        {isAddDomain && (
          <Modal
            maskClosable={false}
            title="Add new domain"
            width={720}
            wrapClassName="vertical-center-modal"
            visible={isAddDomain}
            onOk={() => setSubmitAdd(true)}
            onCancel={() => setIsAddDomain(false)}
          >
            <CreateDomain
              submitAdd={submitAdd}
              onClose={onClose}
              onIsFetch={onIsFetch}
            />
          </Modal>
        )}
        {isVerify && (
          <Modal
            title="Verify Domain"
            width={720}
            wrapClassName="vertical-center-modal"
            visible={isVerify}
            onOk={() => handleVerifyOkButton()}
            onCancel={() => setIsVerify(false)}
          >
            <Card title="Verify your domain" className="gx-card">
              <Timeline pending="Verifing ...">
                <Timeline.Item key="0">
                  <span
                    className="gx-link"
                    type="primary"
                    onClick={() => onClickDownloadFile(text)}
                  >
                    Download auth.txt
                  </span>
                </Timeline.Item>
                <Timeline.Item>
                  Upload the file to http://{domain} or https://{domain}
                </Timeline.Item>
                <Timeline.Item key="1">
                  Confirm the file's name is auth.txt which should not be
                  modified
                </Timeline.Item>
                <Timeline.Item key="2">
                  Access http(s)://{domain}/auth.txt to confirm that the upload
                  was successful
                </Timeline.Item>
                <Timeline.Item key="3">
                  Click the OK button when you are done
                </Timeline.Item>
              </Timeline>
            </Card>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, {
  getDomainList,
  deleteDomain,
  verifyDomain,
  setStatusDomain,
  searchEmail,
})(DomainConfig);
