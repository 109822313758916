import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, message } from "antd";
import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import { createUser } from "../../../appRedux/actions/User";
import { connect } from "react-redux";

const mapStateToProps = () => ({});

function CreateUser(props) {
    const [userInfo, setUserInfo] = useState([]);
    const Option = Select.Option;

    const onChange = (newFields) => {
        setUserInfo(newFields);
      };

    const handleAdd = (res) => {
        if (res === 200) {
          message.success("Add user information successfully!");
          props.onIsFetch(true);
          props.onClose(false);
        } else message.error("Error when adding user information !");
    };

    useEffect(() => {
        if (props.submitAdd === true) {
          props.createUser(userInfo, (res) => handleAdd(res));
        }
    }, [props.submitAdd]);

    return (

        <Form
            name="basic"
            fields={userInfo}
            className="gx-signin-form gx-form-row0"
            onFieldsChange={(changedFields, allFields) => {
                onChange(allFields);
            }}
        >

            <Row gutter={[16, 10]}>
                <Col span={12}>
                    <p>Username</p>
                    <Form.Item
                        name="username"
                        rules={[
                            { required: true, message: "Please input your username!" },
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                            placeholder="Username"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <p>Email</p>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: "email",
                                message: "The input is not valid E-mail!",
                            },
                        ]}
                    >
                        <Input
                            prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                            placeholder="Email"
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[16, 10]}>
                <Col span={12}>
                    <p>Password</p>
                    <Form.Item
                        name="password"
                        rules={[
                            { required: true, message: "Please input your Password!" },
                        ]}
                    >
                        <Input
                            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>

                </Col>
                <Col span={12}>
                    <p>Confirm Password</p>
                    <Form.Item
                        name="confirm-password"
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                            { required: true, message: "Please confirm your Password!" },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue("password") === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        "The two passwords that you entered do not match!"
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input
                            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                            type="password"
                            placeholder="Confirm Password"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 10]}>
                <Col span={12}>
                    <p>Status</p>
                    <Form.Item
                        name="status"
                    >
                        <Select className="gx-mr-3 gx-mb-3">
                            <Option value="1">Active</Option>
                            <Option value="0">InActive</Option>
                        </Select>

                    </Form.Item>
                </Col>
                <Col span={12}>
                    <p>Type</p>
                    <Form.Item
                        name="type"
                    >
                        <Select className="gx-mr-3 gx-mb-3">
                            <Option value="0">User</Option>
                            <Option value="1">Admin</Option>
                            <Option value="2">Super</Option>
                        </Select>

                    </Form.Item>
                </Col>
            </Row>
        </Form >
    );
}

export default connect(mapStateToProps, { createUser })(CreateUser);
