import React, { useState, useRef } from "react";
import { Radio, Button, Modal } from "antd";
import { CheckCircleTwoTone, DollarCircleOutlined } from "@ant-design/icons";
import DirectPayment from "./DirectPayment";
import PayPal from "./PayPal";
import { connect } from "react-redux";
import Draggable from "react-draggable";

const mapStateToProps = () => ({});
const Payment = (props) => {
  const draggleRef = useRef();

  const [paymentMethod, setPaymentMethod] = useState(2); // 1 - paypal , 2 - chuyen khoan
  const [directDialog, setDirectDialog] = useState(false);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [disabled, setDisabled] = useState(true);

  const handleChangePaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleClick = () => {
    if (paymentMethod === 2) {
      setDirectDialog(true);
    } else {
      // do sth
    }
  };

  const handleCloseDirectDialog = (value) => {
    setDirectDialog(value);
  };

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };
  return (
    <div>
      <div className="price-info">
        <div className="h1 gx-text-eco-1 gx-font-weight-bold ">
          Order Detail
        </div>
        <div
          style={{
            paddingTop: 20,
            paddingLeft: 5,
            paddingRight: 5,
            paddingBottom: 5,
          }}
        >
          <div
            style={{
              margin: "auto",
              backgroundColor: "#f5efebd9",
              borderRadius: 12,
              height: 80,
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#ef5555ba", //#5ecb89
                height: 60,
                width: 60,
                marginLeft: 10,
                borderRadius: 12,
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <DollarCircleOutlined style={{ fontSize: 25, color: "white" }} />
            </div>
            <div className="gx-ml-4">
              <div
                className="gx-font-weight-bold gx-text-eco-1 gx-product-title"
                style={{ fontSize: 26 }}
              >
                {props.name}
              </div>
              <div className="ant-row-flex">
                <div
                  className="gx-font-weight-bold gx-product-title gx-mr-2"
                  style={{ fontSize: 25, color: "#F3565D" }}
                >
                  {props.real_price} {props.currency}
                </div>
                <del
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "gray",
                  }}
                >
                  {props.origin_price} {props.currency}
                </del>
              </div>
            </div>
          </div>
          <div>
            <div className="ant-row-flex  ant-row-space-between gx-mt-4">
              <div
                className="gx-font-weight-semi-bold"
                style={{ fontSize: 15 }}
              >
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  className="gx-mr-2"
                />
                Price
              </div>
              <div
                className="gx-font-weight-semi-bold gx-text-eco-3"
                style={{ fontSize: 16 }}
              >
                {props.real_price} {props.currency}
              </div>
            </div>
            <hr></hr>
            <div className="ant-row-flex  ant-row-space-between gx-mt-4">
              <div
                className="gx-font-weight-semi-bold"
                style={{ fontSize: 15 }}
              >
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  className="gx-mr-2"
                />
                VAT (0%)
              </div>
              <div
                className="gx-font-weight-semi-bold gx-text-eco-3"
                style={{ fontSize: 16 }}
              >
                0 {props.currency}
              </div>
            </div>
            <hr></hr>
            <div className="ant-row-flex  ant-row-space-between gx-mt-3">
              <div
                className="gx-font-weight-semi-bold"
                style={{ fontSize: 15 }}
              >
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  className="gx-mr-2"
                />
                Total Price
                <br />
                <div
                  className="h6 gx-font-weight-normal gx-ml-3 gx-mt-1"
                  style={{ opacity: 0.4 }}
                >
                  (Include VAT)
                </div>
              </div>
              <div
                className="gx-font-weight-semi-bold gx-text-eco-3"
                style={{ fontSize: 22 }}
              >
                {props.real_price} {props.currency}
              </div>
            </div>
            <hr></hr>
            <div className="gx-mt-3">
              <div
                className="gx-font-weight-semi-bold gx-mb-3"
                style={{ fontSize: 15 }}
              >
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  className="gx-mr-2"
                />
                Payment Method
              </div>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Radio.Group
                  defaultValue={2}
                  size="large"
                  onChange={handleChangePaymentMethod}
                >
                  <Radio value={1}>
                    <img
                      src={require("../../../../assets/images/paypal.png")}
                      style={{ width: 80, height: 20 }}
                    ></img>
                  </Radio>
                  <Radio value={2}>
                    <strong style={{ fontSize: 17 }}>Direct Payment</strong>
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          </div>
        </div>

        <div
          className="gx-product-footer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {paymentMethod === 1 ? (
            <PayPal
              expired={props.expired}
              key={props.id}
              name={props.name}
              value={props.value}
              real_price={props.real_price}
              description={props.description}
            />
          ) : (
            <Button
              style={{
                backgroundColor: "#f5487f", //#006eb8
                color: "white",
                borderRadius: 8,
                height: 50,
                width: "80%",
                boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.06)",
                border: 0,
              }}
              onClick={handleClick}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <strong style={{ fontSize: 16 }}>Pay Now</strong>
                <i
                  className="icon  i icon-modal gx-ml-3"
                  style={{
                    fontSize: 25,
                    fontWeight: "bold",
                    marginTop: 4,
                  }}
                ></i>
              </div>
            </Button>
          )}
        </div>
      </div>
      {directDialog && (
        <Modal
          visible={directDialog}
          title={
            <div
              style={{
                width: "100%",
                cursor: "move",
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
              // fix eslintjsx-a11y/mouse-events-have-key-events
              // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
              onFocus={() => {}}
              onBlur={() => {}}
            >
              <div className="h1 gx-text-eco-1 gx-font-weight-bold gx-ml-4">
                Direct Payment
              </div>
            </div>
          }
          footer={false}
          onCancel={() => setDirectDialog(false)}
          centered
          width={600}
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
        >
          <DirectPayment
            pack_name={props.name}
            real_price={props.real_price}
            origin_price={props.origin_price}
            currency={props.currency}
            currency={props.currency}
            description={props.description}
            onClose={handleCloseDirectDialog}
          />
        </Modal>
      )}
    </div>
  );
};

export default connect(mapStateToProps, {})(Payment);
