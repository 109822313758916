import React, { useState, useEffect } from "react";
import { Form, message, Col, Row, Select } from "antd";
import { createTransaction } from "../../../appRedux/actions/Transaction";
import {
  searchDataPack,
  getDataPackInfo,
} from "../../../appRedux/actions/DataPack";

import { searchEmail } from "../../../appRedux/actions/Dashboard";
import { formatDataSize } from "../../../util/formatData";
import { connect } from "react-redux";

import AsyncSelect from "react-select/async";
import axios from "util/Api";
import Cookies from "universal-cookie";

const mapStateToProps = () => ({});

function CreateOrder(props) {
  const [email, setEmail] = useState("");
  const [packItemDetail, setPackItemDetail] = useState(null);
  const [packDetail, setPackDetail] = useState(null);

  const [defaultUserList, setDefaultUserList] = useState([]);
  const [defaultPackList, setDefaultPackList] = useState([]);
  const cookies = new Cookies();
  const roleAccount = cookies.get("role");

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      //   border: "none",
      borderRadius: "6px",
      height: "37px",
      // boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
    menu: (styles) => ({
      ...styles,
      //border: "none",
      borderRadius: "8px",
      // boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
    }),
  };

  const loadDefaultUserList = () => {
    if (roleAccount !== "user") {
      props.searchEmail(
        { page: 1, page_size: 20, keyword: "" },
        (code, res) => {
          if (code === 200) {
            setDefaultUserList(res);
          } else message.error("Error when fetching user list !");
        }
      );
    }
  };

  const loadDefaultPackList = () => {
    if (roleAccount !== "user") {
      props.searchDataPack(
        { page: 1, page_size: 20, keyword: "" },
        (code, res) => {
          if (code === 200) {
            setDefaultPackList(res.data);
          } else message.error("Error when fetching package list !");
        }
      );
    }
  };

  const loadUserOptions = (inputValue) => {
    if (roleAccount !== "user") {
      return axios
        .get(`admin/email/search?page=1&page_size=5&keyword=${inputValue}`)
        .then((res) => res.data.data);
    }
  };

  const loadPackageOptions = (inputValue) => {
    if (roleAccount !== "user") {
      return axios
        .get(`pack/search?page=1&page_size=5&keyword=${inputValue}`)
        .then((res) => res.data.data.data);
    }
  };

  const handeCreateOrder = (res) => {
    if (res === 200) {
      message.success("Create Order successfully!");
      props.onIsFetch(true);
    } else message.error("Error when create new order !");
    props.onClose();
  };

  useEffect(() => {
    if (roleAccount === "admin" || roleAccount === "super") {
      loadDefaultUserList();
      loadDefaultPackList();
    }
  }, []);

  useEffect(() => {
    if (packItemDetail && packItemDetail.id) {
      props.getDataPackInfo({ id: packItemDetail.id }, (code, res) => {
        if (code === 200) {
          setPackDetail(res);
        } else message.error("Error when getting package detail info !");
      });
    }
  }, [packItemDetail]);

  useEffect(() => {
    if (props.submitAdd === true) {
      props.createTransaction(
        [
          {
            name: "email",
            value: email != null ? email.value : null,
          },
          {
            name: "pack_name",
            value: packDetail.name,
          },
          {
            name: "trans_id",
            value: "",
          },
          {
            name: "origin_price",
            value: packDetail.origin_price,
          },
          {
            name: "real_price",
            value: packDetail.real_price,
          },
          {
            name: "paid",
            value: 0,
          },
          {
            name: "currency",
            value: packDetail.currency,
          },
          {
            name: "pay_id",
            value: 1,
          },
          {
            name: "status",
            value: "pending",
          },
          {
            name: "description",
            value: "",
          },
        ],
        (res) => handeCreateOrder(res)
      );
    }
  }, [props.submitAdd]);

  return (
    <div>
      <Form
        name="basic"
        className="gx-signin-form gx-form-row0"
        // onFieldsChange={(changedFields, allFields) => {
        //   onChange(allFields);
        // }}
      >
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <div className="gx-font-weight-semi-bold gx-mb-1">Package Name</div>
            <AsyncSelect
              className="gx-mb-3"
              placeholder="Package Name"
              isClearable={true}
              styles={colourStyles}
              defaultOptions={defaultPackList}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.id}
              loadOptions={loadPackageOptions}
              onChange={(value) => setPackItemDetail(value)}
              maxMenuHeight={175}
            />
          </Col>
          <Col span={12}>
            <div className="gx-font-weight-semi-bold gx-mb-1">Email</div>
            <AsyncSelect
              className="gx-mb-3"
              placeholder="All user..."
              isClearable={true}
              styles={colourStyles}
              defaultOptions={defaultUserList}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              loadOptions={loadUserOptions}
              onChange={(value) => setEmail(value)}
              maxMenuHeight={175}
            />
          </Col>
        </Row>
      </Form>
      {packItemDetail && packItemDetail.id && (
        <div style={{ paddingLeft: 8, paddingTop: 12 }}>
          <div
            className="gx-text-eco-1 gx-font-weight-bold gx-mb-3"
            style={{ fontSize: 16 }}
          >
            Package Info :
          </div>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <div className="gx-font-weight-semi-bold gx-mb-1">
                Package Name: <a>{packDetail ? packDetail.name : ""}</a>
              </div>
            </Col>
            <Col span={12}>
              <div className="gx-font-weight-semi-bold gx-mb-3">
                Value:{" "}
                <a>{packDetail ? formatDataSize(packDetail.value) : 0}</a>
              </div>
            </Col>
            <Col span={12}>
              <div className="gx-font-weight-semi-bold gx-mb-3">
                Real Price: <a>{packDetail ? packDetail.real_price : 0}</a>
              </div>
            </Col>
            <Col span={12}>
              <div className="gx-font-weight-semi-bold gx-mb-3">
                Origin Price: <a>{packDetail ? packDetail.origin_price : 0}</a>
              </div>
            </Col>

            <Col span={12}>
              <div className="gx-font-weight-semi-bold gx-mb-3">
                Currency: <a>{packDetail ? packDetail.currency : ""}</a>
              </div>
            </Col>
            <Col span={12}>
              <div className="gx-font-weight-semi-bold gx-mb-3">
                Expired: <a>{packDetail ? packDetail.expired : ""}</a> Days
              </div>
            </Col>
            <Col span={24}>
              <div className="gx-font-weight-semi-bold gx-mb-3">
                Description: <a> {packDetail ? packDetail.description : 0}</a>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default connect(mapStateToProps, {
  createTransaction,
  searchEmail,
  searchDataPack,
  getDataPackInfo,
})(CreateOrder);
